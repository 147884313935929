import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormSection } from 'components/FormSection'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import { theme } from 'themes'
import { SafetyExpert } from 'services/model'
import FormFileDropzone from 'components/FormFileDropzone'
import { FileUploadType } from 'components/FileDropzone'
import { useMutation } from 'redux-query-react'
import { updateSafetyExpertImage } from 'services/queries-typed'
import { useSnackbar } from 'notistack'

interface Props {
  safetyExpert: SafetyExpert
}

export const SafetyExpertProfile: React.FC<Props> = ({ safetyExpert }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [image, setImage] = useState<string | null>(safetyExpert.image)
  const canEditSafetyExpert = useSelector((state) =>
    state.profile.permissions.includes('weissbier:update:safety_expert')
  )
  const [, uploadImage] = useMutation(updateSafetyExpertImage)

  useEffect(() => {
    if (safetyExpert) {
      setImage(safetyExpert.image)
    }
  }, [safetyExpert])

  const onEdit = () => {
    setEditMode(true)
  }

  const onCancelEdit = () => {
    setEditMode(false)
  }

  const onUpdateImage = (images: File[]) => {
    onCancelEdit()
    uploadImage(images[0], safetyExpert.id)
      ?.then((response) => {
        if (response.status < 400) {
          enqueueSnackbar(t('safetyExperts.profile.image_upload.success'), {
            variant: 'success',
          })
          setImage(response.body.data.image)
        } else {
          enqueueSnackbar(t('safetyExperts.profile.image_upload.error'), {
            variant: 'error',
          })
        }
      })
      .catch(() => {
        enqueueSnackbar(t('safetyExperts.profile.image_upload.error'), {
          variant: 'error',
        })
      })
  }

  return (
    <FormSection
      title={t('safetyExperts.profile.image')}
      name="safetyExpertProfileImage"
      buttonTitle={t('global.buttons.edit')}
      showDivider={false}
      editMode={editMode}
      canEdit={canEditSafetyExpert}
      onButtonClick={onEdit}
    >
      {editMode && (
        <Box sx={{ mb: 4 }}>
          <FormFileDropzone
            onSave={onUpdateImage}
            onCancel={onCancelEdit}
            acceptFormatOnly={FileUploadType.IMAGE}
            acceptMultiple={false}
          />
        </Box>
      )}
      {image ? (
        <Grid item sx={{ maxWidth: '200px' }} key={safetyExpert?.image}>
          <img src={image} alt={image} height={'150px'} width={'auto'} />
        </Grid>
      ) : (
        <Box
          sx={{ backgroundColor: theme.palette.background.info }}
          padding={4}
        >
          <Typography>{t('safetyExperts.profile.no_image')}</Typography>
        </Box>
      )}
    </FormSection>
  )
}
