import FormData from 'form-data'
import { Language } from 'redux/settings/types'

import config from './config'

export const options = {
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en',
    get Authorization() {
      const JWT = localStorage.getItem('access_token')
      return `Bearer ${JWT}`
    },
  },
}

export const formDataOptions = {
  headers: {
    'Accept-Language': 'en',
    get Authorization() {
      const JWT = localStorage.getItem('access_token')
      return `Bearer ${JWT}`
    },
  },
}

export const getBilingualOptions = (language: Language) => ({
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': language,
    get Authorization() {
      const JWT = localStorage.getItem('access_token')
      return `Bearer ${JWT}`
    },
  },
})

export const createFormData = (payload: File, name?: string) => {
  const data = new FormData()
  name ? data.append(name, payload) : data.append('file', payload)

  return data
}

export const checkEmailVerifiedWithToken = (token: string) => {
  const EMAIL_VERIFIED_CLAIM = `${config.CUSTOM_CLAIM_PREFIX}email_verified`
  const tokens = token.split('.')
  const claims = JSON.parse(atob(tokens[1]))

  return !!claims[EMAIL_VERIFIED_CLAIM]
}
