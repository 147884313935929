import { Box, LinearProgress } from '@mui/material'
import { ConfirmationModal } from 'components/ConfirmationModal'
import FormFileDropzone from 'components/FormFileDropzone'
import { FormSection } from 'components/FormSection'
import { MeasureFiles } from 'components/MeasureFiles'
import {
  MeasureInfoProps,
  MeasureInformationForm,
} from 'components/MeasureInfoForm'
import { MeasureResponsiblesTable } from 'components/MeasureResponsiblesTable'
import { Comments as MeasureComments } from 'components/MeasureComments'
import { PageHeader } from 'containers/page-headers'
import { format } from 'date-fns'
import routes, { MeasureRouteParams } from 'navigation/routes'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Entities, QueryConfig, updateEntities } from 'redux-query'
import { useMutation, useRequests } from 'redux-query-react'
import {
  Document,
  Measure,
  MeasureComment,
  MeasureImage,
  MeasureImageWithUrl,
} from 'services/model'
import {
  addMeasureDocument,
  createImage,
  createMeasure,
  editCustomerMeasure,
  getRiskGroups,
} from 'services/queries'
import {
  getMeasure,
  getFacilities,
  emailMeasure,
  updateMeasureResponsible,
  deleteMeasureResponsible,
  getS3PresignedUrl,
  deleteMeasureDocument,
  deleteMeasureImage,
  createMeasureComment,
  getMeasureComments,
} from 'services/queries-typed'

export const MeasureDetailsPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { customerId, measureId } = useParams<MeasureRouteParams>()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const measure = useSelector((state) => state.entities.measure)
  const measureComments = useSelector((state) => state.entities.measureComments)
  const language = useSelector((state) => state.settings.language)
  const canEditMeasure = useSelector((state) =>
    state.profile.permissions.includes('weissbier:update:measure')
  )
  const canEditFullMeasure = useSelector((state) =>
    state.profile.permissions.includes('weissbier:update:measure:extended')
  )
  const divisions = useSelector((state) => state.entities.divisions?.list)
  const hazardGroups = useSelector((state) => state.entities.riskGroups)

  const [statusButtonCopy, setStatusButtonCopy] = useState<string>('')
  const [editModeInfo, setEditModeInfo] = useState<boolean>(false)
  const [editModeResponsible, setEditModeResponsible] = useState<boolean>(false)
  const [editModeFiles, setEditModeFiles] = useState<boolean>(false)
  const [responsibleModalIsOpen, setResponsibleModalIsOpen] =
    useState<boolean>(false)
  const [deleteFileModalIsOpen, setDeleteFileModalIsOpen] =
    useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<
    MeasureImage | Document | null
  >(null)
  const [processedMeasureImages, setProcessedMeasureImages] = useState<
    MeasureImageWithUrl[]
  >([])
  const [updateRequests, setUpdateRequests] = useState<QueryConfig<Entities>[]>(
    []
  )
  const [fetchedMeasureId, setFetchedMeasureId] = useState<
    Measure['id'] | null
  >(null)

  const [, fetchMeasure] = useMutation(getMeasure)
  const [, updateMeasure] = useMutation(editCustomerMeasure)
  const [, createNewMeasure] = useMutation(createMeasure)
  const [, updateResponsible] = useMutation(updateMeasureResponsible)
  const [, deleteResponsible] = useMutation(deleteMeasureResponsible)
  const [, getCustomerFacilities] = useMutation(getFacilities)
  const [, getHazardGroups] = useMutation(getRiskGroups)
  const [, sendMeasure] = useMutation(emailMeasure)
  const [, getS3Url] = useMutation(getS3PresignedUrl)
  const [, deleteDocument] = useMutation(deleteMeasureDocument)
  const [, deleteImage] = useMutation(deleteMeasureImage)
  const [, fetchComments] = useMutation(getMeasureComments)
  const [, createComment] = useMutation(createMeasureComment)
  const [{ isPending, isFinished }, createFiles] = useRequests(updateRequests)

  const goToHome = useCallback(
    () => history.push(routes.dashboard(customerId)),
    [customerId, history]
  )

  const goToMeasureDetails = useCallback(
    (id: Measure['id']) =>
      history.replace(routes.measureDetails(customerId, id)),
    [customerId, history]
  )

  useEffect(() => {
    if (!measureId) {
      dispatch(
        updateEntities({
          measure: () => undefined,
        })
      )
    }
  }, [measureId])

  useEffect(() => {
    if (measure) {
      if (measure.done) {
        setStatusButtonCopy(t('measure.set_open'))
      } else {
        setStatusButtonCopy(t('measure.set_done'))
      }
    }
  }, [measure?.done, language])

  useEffect(() => {
    if (customerId) {
      getHazardGroups()
      getCustomerFacilities(customerId, 500, 0)
      if (measureId) {
        getMeasureDetails()
        getComments()
      }
    }
  }, [customerId, measureId])

  useEffect(() => {
    if (measure?.id === fetchedMeasureId) {
      processImageUrls()
      setFetchedMeasureId(null)
      setUpdateRequests([])
    }
  }, [fetchedMeasureId])

  useEffect(() => {
    if (isFinished && measureId) {
      getMeasureDetails()
    }
  }, [isFinished, measureId])

  async function fetchImageUrl(
    image: MeasureImage
  ): Promise<MeasureImageWithUrl | undefined> {
    const newImage = await getS3Url(image.s3_key)
      ?.then((response) => {
        if (response.status < 400) {
          return {
            id: image.id,
            s3_key: image.s3_key,
            url: response.body.data,
          }
        } else {
          throw new Error('image not found')
        }
      })
      .catch((error) => {
        enqueueSnackbar(t('measure.image_not_found'), {
          variant: 'error',
        })
        throw error
      })
    return newImage
  }

  // Improvement notes: If we ever encounter an increased amount of loading time due to excessive usage of
  // the upload image feature, we can think about building a custom component wrapper for s3Images that
  // handles fetching the url for each image component one by one.
  const processImageUrls = useCallback(() => {
    if (!measure) return
    if (measure.images.length === 0) {
      setProcessedMeasureImages([])
      return
    }
    const imageRequests = measure.images.map((image: MeasureImage) => {
      return fetchImageUrl(image)
    })
    Promise.allSettled(imageRequests).then((results) => {
      const trueResults = results
        .filter((result) => result.status === 'fulfilled')
        .map(
          (result) =>
            (result as PromiseFulfilledResult<MeasureImageWithUrl>).value
        )
      setProcessedMeasureImages(trueResults)
    })
  }, [measure])

  const getMeasureDetails = useCallback(() => {
    fetchMeasure(customerId, measureId)
      ?.then((response) => {
        if (response.status < 400) {
          setFetchedMeasureId(response.body.data.id)
        } else {
          goToHome()
          enqueueSnackbar(t('measure.not_found'), {
            variant: 'error',
          })
        }
      })
      .catch(() => {
        goToHome()
        enqueueSnackbar(t('measure.not_found'), {
          variant: 'error',
        })
      })
  }, [customerId, measureId, measure])

  const getComments = useCallback(() => {
    fetchComments(customerId, measureId)
  }, [customerId, measureId, measureComments])

  const updateMeasureAttributes = (attributes: Partial<Measure>) => {
    updateMeasure(customerId, measureId, attributes)
      ?.then((response) => {
        if (response.status < 400) {
          getMeasureDetails()
        } else {
          enqueueSnackbar(t('measure.update_error'), {
            variant: 'error',
          })
        }
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar(t('measure.update_error'), {
          variant: 'error',
        })
      })
  }

  const createMeasureWithAttributes = (attributes: Partial<Measure>) => {
    createNewMeasure(customerId, attributes)
      ?.then((response) => {
        if (response.status < 400) {
          goToMeasureDetails(response.body.data.id)
        } else {
          enqueueSnackbar(t('measure.update_error'), {
            variant: 'error',
          })
        }
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar(t('measure.update_error'), {
          variant: 'error',
        })
      })
  }

  const updateResponsibleInfo = ({ name, email }: FieldValues) => {
    updateResponsible(customerId, measureId, { name, email })
      ?.then((response) => {
        if (response.status === 204) {
          getMeasureDetails()
          enqueueSnackbar(t('measure.email_sent', { email }), {
            variant: 'success',
          })
        } else {
          enqueueSnackbar(t('measure.update_error'), {
            variant: 'error',
          })
        }
      })
      .catch(() => {
        enqueueSnackbar(t('measure.update_error'), {
          variant: 'error',
        })
      })
  }

  const removeMeasureResponsible = () => {
    deleteResponsible(customerId, measureId)
      ?.then((response) => {
        if (response.status === 200) {
          getMeasureDetails()
          enqueueSnackbar(t('measure.responsible_deleted'), {
            variant: 'success',
          })
        } else {
          enqueueSnackbar(t('measure.responsible_delete_error'), {
            variant: 'error',
          })
        }
      })
      .catch(() => {
        enqueueSnackbar(t('measure.responsible_delete_error'), {
          variant: 'error',
        })
      })
  }

  const sendMeasureToResponsible = (measure: Measure) => {
    sendMeasure(customerId, measureId, measure)
      ?.then((response) => {
        if (response.status === 201) {
          enqueueSnackbar(
            t('measure.email_sent', { email: measure.responsible_email }),
            {
              variant: 'success',
            }
          )
        } else {
          enqueueSnackbar(t('measure.email_sent_error'), {
            variant: 'error',
          })
        }
      })
      .catch(() => {
        enqueueSnackbar(t('measure.email_sent_error'), {
          variant: 'error',
        })
      })
  }

  const onMeasureStatusChange = () => {
    if (measure) {
      updateMeasureAttributes({ done: !measure.done })
    }
  }

  const onEditInfo = () => {
    setEditModeInfo(true)
  }

  const onEditResponsible = () => {
    setEditModeResponsible(true)
  }

  const onEditFiles = () => {
    setEditModeFiles(true)
  }

  const isDocument = (file: Document | MeasureImage) => {
    // @ts-expect-error: MeasureImage does not have name property
    // and this is how we distinguish between images or documents
    return !!file.name
  }

  const onFileDownload = useCallback(
    (file: any) => {
      // Refresh presignedUrl
      getS3Url(file.s3_key)
        ?.then((response) => {
          if (response.status === 200) {
            window.open(response.body.data, '_blank')
          } else {
            throw new Error('document or image not found')
          }
        })
        .catch(() => {
          enqueueSnackbar(t('measure.file_download_error'), {
            variant: 'error',
          })
        })
    },
    [customerId, measure]
  )

  const onInfoSubmit = (attributes: Partial<MeasureInfoProps>) => {
    onCancelEdit()
    if (measure) {
      const attributesEntriesArray: [string, string | number | Date | null][] =
        Object.entries(attributes)

      const updatedAttributes = attributesEntriesArray.filter(
        ([key, value]) => {
          if (key === 'division') {
            return value !== measure.facility.id
          } else if (key === 'hazard_group') {
            return value !== measure.risk_group.id
            //@ts-ignore
          } else if (measure[key] !== value) {
            return true
          }
        }
      )

      const requestAttributes: Record<string, any> =
        Object.fromEntries(updatedAttributes)

      if (requestAttributes['division']) {
        requestAttributes.facility_id = requestAttributes.division
        delete requestAttributes.division
      }
      if (requestAttributes['hazard_group']) {
        requestAttributes.risk_group_id = requestAttributes.hazard_group
        delete requestAttributes.hazard_group
      }
      if (requestAttributes['due_date']) {
        requestAttributes.due_date = format(
          requestAttributes['due_date'],
          'yyyy-MM-dd'
        )
      }
      updateMeasureAttributes(requestAttributes)
    }
  }

  const onMeasureCreate = (attributes: Partial<MeasureInfoProps>) => {
    onCancelEdit()
    const newMeasure: Record<string, any> = {
      done: false,
      hazard: attributes.hazard,
      hazard_description: attributes.hazard_description,
      name: attributes.name,
      pdf_sent: false,
      responsible_email: null,
      responsible_name: null,
      risk_level: attributes.risk_level,
      source: attributes.source,
    }

    if (attributes.division)
      newMeasure['facility'] = { id: attributes.division }
    if (attributes.hazard_group)
      newMeasure['risk_group'] = { id: attributes.hazard_group }
    if (attributes.due_date && typeof attributes.due_date !== 'string') {
      newMeasure['due_date'] = format(attributes.due_date, 'yyyy-MM-dd')
    } else {
      newMeasure['due_date'] = format(new Date(), 'yyyy-MM-dd')
    }
    createMeasureWithAttributes(newMeasure)
  }

  const onCancelEdit = () => {
    setEditModeInfo(false)
    setEditModeResponsible(false)
    setEditModeFiles(false)
  }

  const onSendMeasure = () => {
    onCancelEdit()
    if (measure) sendMeasureToResponsible(measure)
  }

  const onRemoveResponsible = () => {
    setResponsibleModalIsOpen(false)
    onCancelEdit()
    removeMeasureResponsible()
  }

  const onUpdateResponsible = (attributes: FieldValues) => {
    onCancelEdit()
    updateResponsibleInfo(attributes)
  }

  const onUpdateMeasureFiles = (files: File[]) => {
    onCancelEdit()
    let requests: QueryConfig<Entities>[] = []
    files.map((file) => {
      if (file.type.startsWith('image/')) {
        requests.push(createImage(file, customerId, measureId))
        setUpdateRequests([...updateRequests, ...requests])
      } else {
        requests.push(addMeasureDocument(file, customerId, measureId))
        setUpdateRequests([...updateRequests, ...requests])
      }
    })
    // TODO: Find a way for promise rejection handling
    createFiles()
  }

  const handleDeleteFile = (file: MeasureImage | Document) => {
    setDeleteFileModalIsOpen(true)
    setSelectedFile(file)
  }

  const onCloseDeleteFileModal = () => {
    setSelectedFile(null)
    setDeleteFileModalIsOpen(false)
  }

  const onDeleteFile = useCallback(() => {
    if (selectedFile) {
      if (isDocument(selectedFile)) {
        deleteDocument(customerId, measureId, selectedFile.id)
          ?.then((response) => {
            if (response.status < 400) {
              getMeasureDetails()
              enqueueSnackbar(t('measure.file_deleted'), {
                variant: 'success',
              })
            } else {
              throw new Error('document not deleted')
            }
          })
          .catch(() => {
            enqueueSnackbar(t('measure.file_delete_error'), {
              variant: 'error',
            })
          })
      } else {
        deleteImage(customerId, measureId, selectedFile.id)
          ?.then((response) => {
            if (response.status < 400) {
              getMeasureDetails()
              enqueueSnackbar(t('measure.file_deleted'), {
                variant: 'success',
              })
            } else {
              throw new Error('image not deleted')
            }
          })
          .catch(() => {
            enqueueSnackbar(t('measure.file_delete_error'), {
              variant: 'error',
            })
          })
      }
      setSelectedFile(null)
    }
    setDeleteFileModalIsOpen(false)
  }, [selectedFile])

  const onComment = (comment: Partial<MeasureComment>) => {
    createComment(customerId, measureId, comment)
      ?.then((response) => {
        if (response.status < 400) {
          getComments()
          enqueueSnackbar(t('measure.comment_created'), {
            variant: 'success',
          })
        } else {
          throw new Error('comment not created')
        }
      })
      .catch(() => {
        enqueueSnackbar(t('measure.comment_create_error'), {
          variant: 'error',
        })
      })
  }

  const newMeasureForm = (
    <>
      <PageHeader
        title={t('measure.addMeasure.title')}
        breadcrumbs={[
          {
            name: t('sidebar_link_dashboard'),
            path: routes.dashboard(customerId),
          },
        ]}
      />
      <Box sx={{ m: 4 }}>
        <FormSection
          title={t('measure.details.title')}
          name="measureInfo"
          buttonTitle={t('global.buttons.edit')}
          editMode={true}
          canEdit={canEditMeasure}
          onButtonClick={onEditInfo}
          showDivider={false}
        >
          <MeasureInformationForm
            editMode={true}
            fullEditRights={true}
            divisions={divisions || []}
            hazardGroups={hazardGroups || []}
            onSubmit={onMeasureCreate}
            onClose={onCancelEdit}
          />
        </FormSection>
      </Box>
    </>
  )

  const isNewMeasure = !measure && !measureId
  if (isNewMeasure) return newMeasureForm
  return (
    <>
      {measure && (
        <>
          <PageHeader
            title={measure?.name}
            buttonTitle={statusButtonCopy}
            onButtonClick={onMeasureStatusChange}
            displayButton={true}
            breadcrumbs={[
              {
                name: t('sidebar_link_dashboard'),
                path: routes.dashboard(customerId),
              },
            ]}
          />
          <Box sx={{ m: 4 }}>
            <FormSection
              title={t('measure.details.title')}
              name="measureInfo"
              buttonTitle={t('global.buttons.edit')}
              editMode={editModeInfo}
              canEdit={canEditMeasure}
              onButtonClick={onEditInfo}
            >
              <MeasureInformationForm
                measure={measure}
                editMode={editModeInfo}
                fullEditRights={canEditFullMeasure}
                divisions={divisions || []}
                hazardGroups={hazardGroups || []}
                onSubmit={onInfoSubmit}
                onClose={onCancelEdit}
              />
            </FormSection>
            <FormSection
              title={t('measure.responsibles.title')}
              name="measureResponsibles"
              buttonTitle={t('global.buttons.edit')}
              editMode={editModeResponsible}
              canEdit={canEditMeasure}
              onButtonClick={onEditResponsible}
            >
              <MeasureResponsiblesTable
                measure={measure}
                editMode={editModeResponsible}
                onClose={onCancelEdit}
                onResend={onSendMeasure}
                onRemove={() => setResponsibleModalIsOpen(true)}
                onSaveAndSend={onUpdateResponsible}
              />
            </FormSection>
            <FormSection
              title={t('measure.files.title')}
              name="measureFiles"
              showDivider={true}
              buttonTitle={t('global.buttons.edit')}
              editMode={editModeFiles}
              canEdit={canEditMeasure}
              onButtonClick={onEditFiles}
            >
              {editModeFiles && (
                <FormFileDropzone
                  onSave={onUpdateMeasureFiles}
                  onCancel={onCancelEdit}
                />
              )}
              {isPending && !isFinished ? (
                <LinearProgress />
              ) : (
                <MeasureFiles
                  documents={measure?.documents}
                  images={processedMeasureImages}
                  onFileDownload={onFileDownload}
                  editMode={editModeFiles}
                  onDeleteFile={handleDeleteFile}
                />
              )}
            </FormSection>
            <FormSection
              title={t('measure.comments.title')}
              name="measureComments"
              showDivider={false}
              editMode={true}
            >
              <MeasureComments
                comments={measureComments || []}
                canComment={canEditMeasure}
                measureId={measureId}
                customerId={customerId}
                onComment={onComment}
              />
            </FormSection>
          </Box>
          <ConfirmationModal
            bodyCopy={t(
              'confirmation_modal.confirm_delete_measure_responsible'
            )}
            confirmButtonCopy={t('global.buttons.remove')}
            open={responsibleModalIsOpen}
            onClose={() => setResponsibleModalIsOpen(false)}
            onConfirm={onRemoveResponsible}
          />
          <ConfirmationModal
            bodyCopy={t('confirmation_modal.confirm_delete_measure_file')}
            confirmButtonCopy={t('global.buttons.remove')}
            open={deleteFileModalIsOpen}
            onClose={onCloseDeleteFileModal}
            onConfirm={onDeleteFile}
          />
        </>
      )}
    </>
  )
}
