import { useEffect } from 'react'

import { Grid, Typography, Stack } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Column, Table } from 'components/Table'
import UnauthAppBar from 'components/UnauthAppBar'
import { ConsentModal } from 'containers/consent-modal'
import { PageHeader } from 'containers/page-headers'
import moment from 'moment'
import { EmployeeRouteParams } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMutation } from 'redux-query-react'
import { EmployeeInstructionSummary } from 'services/model'
import {
  getEmployeeProfile,
  getEmployeeInstructionsSummary,
} from 'services/queries-typed'
import { theme } from 'themes'
import Footer from 'components/footer'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Language } from 'redux/settings/types'
import { getLanguageCode } from 'lib/helpers'

const useStyles = makeStyles()(() => ({
  container: {
    backgroundColor: theme.palette.background.banner,
    borderRadius: theme.spacing(0.5),
  },
  content: {
    padding: theme.spacing(2),
  },
}))

interface BannerProps {
  count: number
}

const Banner: React.FC<BannerProps> = ({ count }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  return (
    <Grid
      container
      justifyContent="space-between"
      className={classes.container}
    >
      <Grid item className={classes.content}>
        <Typography variant="h4">
          {t('instructions.portal.info', { count: count })}
        </Typography>
      </Grid>
    </Grid>
  )
}

const handleGoToInstruction = (instruction: EmployeeInstructionSummary) => {
  window.open(instruction.employee_slides_url, '_blank')
}

export const EmployeeInstructionsDashboard: React.FC = () => {
  const { t } = useTranslation()
  const getLanguage = () =>
    getLanguageCode() === 'de' ? Language.DE : Language.EN
  const instructions = useSelector(
    (state) => state.entities.employeeDashboardInstructions
  )
  const profile = useSelector(
    (state) => state.entities.employeeDashboardProfile
  )

  const { customerId, employeeId } = useParams<EmployeeRouteParams>()
  const [, getProfile] = useMutation(getEmployeeProfile)
  const [{ isPending }, getInstructions] = useMutation(
    getEmployeeInstructionsSummary
  )

  useEffect(() => {
    getProfile(customerId, employeeId)
    getInstructions(customerId, employeeId, getLanguage())
  }, [getInstructions, getProfile, customerId, employeeId, getLanguage()])

  // Define a media query that matches small screen sizes
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const columns: Column[] = [
    {
      label: t('instructions.portal.name'),
      getter: (instruction: EmployeeInstructionSummary) => (
        <Typography variant={isSmallScreen ? 'button' : 'button'}>
          {t(instruction.instruction.name)}
        </Typography>
      ),
    },
    {
      label: t('instructions.portal.status'),
      getter: (instruction: EmployeeInstructionSummary) =>
        instruction.employee_instruction ? (
          <Typography variant="body1">
            {t('instructions.portal.passed')}
          </Typography>
        ) : (
          <Typography variant="body1">
            {t('instructions.portal.open')}
          </Typography>
        ),
    },
    {
      label: t('instructions.portal.assigned_on'),
      getter: (instruction: EmployeeInstructionSummary) => (
        <Typography variant="body1">
          {moment(instruction.facility_instruction.created_at).format(
            'YYYY-MM-DD'
          )}
        </Typography>
      ),
    },
    {
      label: t('instructions.portal.passed_on'),
      getter: (instruction: EmployeeInstructionSummary) =>
        instruction.employee_instruction ? (
          <Typography variant="body1">
            {moment(instruction.employee_instruction.passed_at).format(
              'YYYY-MM-DD'
            )}
          </Typography>
        ) : (
          <Typography variant="body1">
            {t('instructions.portal.open')}
          </Typography>
        ),
    },
  ]

  return (
    <Grid spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
      <Grid item xs={12}>
        <ConsentModal />
        <UnauthAppBar />
        <PageHeader
          title={t('instructions.portal.title', {
            name: profile ? profile.first_name : '',
          })}
          secondaryTitle={
            instructions ? (
              <Banner count={instructions.outstanding_count} />
            ) : (
              ''
            )
          }
        ></PageHeader>
      </Grid>
      <Grid item xs={12} padding={isSmallScreen ? '20px' : '48px'}>
        {instructions && (
          <Table
            key={customerId}
            columns={columns}
            list={instructions.list}
            loading={isPending}
            // @ts-expect-error not actually an error
            // need to figure out how to do dynamic typing with table
            onRowClick={handleGoToInstruction}
            tableLabel="employee-instruction-list"
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Footer
          style={{
            width: '100%',
            bottom: 0,
          }}
        />
      </Grid>
    </Grid>
  )
}

export default EmployeeInstructionsDashboard
