import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  Measure,
  Document,
  MeasureImage,
  MeasureImageWithUrl,
} from 'services/model'
import FileTile from './FileTile'

export interface Props {
  documents: Measure['documents']
  images: MeasureImageWithUrl[]
  onFileDownload: (file: Document | MeasureImage) => void
  onDeleteFile: (file: Document | MeasureImage) => void
  editMode: boolean
}

export const MeasureFiles: React.FC<Props> = ({
  documents,
  images,
  onFileDownload,
  onDeleteFile,
  editMode,
}) => {
  const { t } = useTranslation()
  const hasEditableImages = images.length > 0 && editMode
  const hasUneditableImages = images.length > 0 && !editMode
  const hasEditableDocuments = documents.length > 0 && editMode
  const hasUneditableDocuments = documents.length > 0 && !editMode

  const renderMeasureDocuments = () => {
    if (hasUneditableDocuments) {
      return <MeasureDocuments documents={documents} onClick={onFileDownload} />
    } else if (hasEditableDocuments) {
      return (
        <EditableMeasureFiles
          files={documents}
          onClick={onFileDownload}
          onDelete={onDeleteFile}
        />
      )
    } else {
      return (
        <Typography padding={1}>
          {t('measure.files.documents.empty')}
        </Typography>
      )
    }
  }

  const renderMeasureImages = () => {
    if (hasUneditableImages) {
      return <MeasureImages images={images} onClick={onFileDownload} />
    } else if (hasEditableImages) {
      return (
        <EditableMeasureFiles
          files={images}
          onClick={onFileDownload}
          onDelete={onDeleteFile}
        />
      )
    } else {
      return (
        <Typography padding={1}>{t('measure.files.images.empty')}</Typography>
      )
    }
  }

  return (
    <>
      <FileSectionLayout title={t('measure.files.documents.title')}>
        {renderMeasureDocuments()}
      </FileSectionLayout>
      <FileSectionLayout title={t('measure.files.images.title')}>
        {renderMeasureImages()}
      </FileSectionLayout>
    </>
  )
}

export interface SectionLayoutProps {
  title: string
  children?: React.ReactNode
}

export const FileSectionLayout: React.FC<SectionLayoutProps> = ({
  title,
  children,
}) => {
  return (
    <Box>
      <Typography variant="subtitle1">{title}:</Typography>
      {children}
    </Box>
  )
}

interface MeasureDocumentsProps {
  documents: Document[]
  onClick: (doc: Document) => void
}

export const MeasureDocuments = ({
  documents,
  onClick,
}: MeasureDocumentsProps) => (
  <Grid container>
    {documents.map((doc) => (
      <Grid key={doc.id} item sx={{ p: 1 }}>
        <FileTile file={doc} onClick={onClick} />
      </Grid>
    ))}
  </Grid>
)

interface MeasureImagesProps {
  images: MeasureImageWithUrl[]
  onClick: (img: MeasureImageWithUrl) => void
}

export const MeasureImages = ({ images, onClick }: MeasureImagesProps) => (
  <Grid container spacing={2} paddingTop={2} paddingBottom={2}>
    {images.map((img) => (
      <Grid item sx={{ maxWidth: '200px' }} key={img.url}>
        <img
          src={img.url}
          alt={img.url}
          height={'100px'}
          width={'auto'}
          style={{ cursor: 'pointer' }}
          onClick={() => onClick(img)}
        />
      </Grid>
    ))}
  </Grid>
)

interface MeasureFilesProps {
  files: MeasureImage[] | Document[]
  onClick: (file: MeasureImage | Document) => void
  onDelete: (file: MeasureImage | Document) => void
}

const EditableMeasureFiles = ({
  files,
  onClick,
  onDelete,
}: MeasureFilesProps) => {
  return (
    <Grid container>
      {files.map((file) => (
        <Grid key={file.id} item sx={{ p: 1 }}>
          <FileTile
            file={file}
            onClick={onClick}
            onDelete={onDelete}
            deletable
          />
        </Grid>
      ))}
    </Grid>
  )
}
