import { Typography } from '@mui/material'
import { Column } from 'components/Table'
import { List } from 'containers/list'
import routes from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SafetyExpert } from 'services/model'
import { getSafetyExperts } from 'services/queries-typed'

export const SafetyExpertList: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const profile = useSelector((state) => state.profile)
  const { permissions, userId } = profile

  const columns: Column[] = [
    {
      label: t('safetyExperts.safetyExpert'),
      getter: (safetyExpert: SafetyExpert) => (
        <>
          {safetyExpert.last_name && (
            <Typography variant="body1">{`${safetyExpert.last_name}, ${safetyExpert.first_name}`}</Typography>
          )}
          <Typography variant="body1" color="textSecondary">
            {safetyExpert.email}
          </Typography>
          {!safetyExpert.last_name && !safetyExpert.email && (
            <Typography variant="body1">{`${safetyExpert.id}`}</Typography>
          )}
        </>
      ),
    },
  ]

  const handleGoToSafetyExpertProfile = (safetyExpert: { id: string }) => {
    history.push(routes.safetyExpertProfile(safetyExpert.id))
  }

  return (
    <List
      key={userId}
      columns={columns}
      query={getSafetyExperts}
      record="safetyExperts"
      onRowClick={
        permissions.includes('weissbier:read:safety_expert')
          ? handleGoToSafetyExpertProfile
          : undefined
      }
    />
  )
}

export default SafetyExpertList
