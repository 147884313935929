import { Breadcrumbs, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { DangerButton } from 'components/DangerButton'
import { Link } from 'react-router-dom'

const useStyles = makeStyles()(() => ({
  root: {
    padding: '48px',
    marginBottom: '1rem',
    // TODO: add once centered
    // maxWidth: '1080px',
    // minWidth: '768px',
  },
  title: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  infoTitle: {
    marginTop: '2rem',
  },
  children: {
    paddingTop: '36px',
  },
}))

export interface Breadcrumb {
  path: string
  name: string
}

export interface Props {
  title: string | React.ReactNode
  breadcrumbs?: Breadcrumb[]
  secondaryTitle?: string | React.ReactNode
  infoTitle?: string | React.ReactNode
  children?: React.ReactNode
  buttonTitle?: string
  secondaryButtonTitle?: string
  onButtonClick?: () => void
  onSecondaryButtonClick?: () => void
  dangerButton?: boolean
  displayButton?: boolean
  disableButton?: boolean
  displaySecondaryButton?: boolean
  disableSecondaryButton?: boolean
  header?: React.ReactNode
}

export const PageHeader: React.FC<Props> = ({
  title,
  breadcrumbs = [],
  secondaryTitle,
  infoTitle,
  children,
  buttonTitle,
  secondaryButtonTitle,
  onButtonClick,
  onSecondaryButtonClick,
  dangerButton,
  displayButton,
  disableButton,
  displaySecondaryButton,
  disableSecondaryButton,
  header,
}) => {
  const { classes } = useStyles()
  const displayHeaderButton = buttonTitle && onButtonClick && displayButton

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>{header}</Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            {breadcrumbs.length > 0 && (
              <Breadcrumbs>
                {breadcrumbs.map((crumb, i) => (
                  <Link
                    key={i}
                    to={crumb.path}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Typography
                      variant="h6"
                      data-testid={`page-header-breadcrumb-${i}`}
                    >
                      {crumb.name}
                    </Typography>
                  </Link>
                ))}
                <Typography
                  variant="h4"
                  color="secondary"
                  data-testid="page-header-breadcrumb-location"
                >
                  {title}
                </Typography>
              </Breadcrumbs>
            )}
          </Grid>
          <Grid item />
        </Grid>
        <Grid container direction="column">
          <Grid item>
            <Typography
              variant="h4"
              color="textSecondary"
              className={classes.infoTitle}
            >
              {infoTitle}
            </Typography>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  variant="h1"
                  className={classes.title}
                  data-testid="page-header-title"
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                {displayHeaderButton && (
                  <ButtonDisplay
                    dangerButton={dangerButton}
                    buttonTitle={buttonTitle}
                    onButtonClick={onButtonClick}
                    disableButton={disableButton}
                    secondaryButtonTitle={secondaryButtonTitle}
                    onSecondaryButtonClick={onSecondaryButtonClick}
                    displaySecondaryButton={displaySecondaryButton}
                    disableSecondaryButton={disableSecondaryButton}
                  />
                )}
              </Grid>
            </Grid>
            <Typography mt={2} variant="h4" color="textSecondary">
              {secondaryTitle}
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.children}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}

interface ButtonProps {
  buttonTitle: string
  onButtonClick: () => void
  disableButton?: boolean
  secondaryButtonTitle?: string
  onSecondaryButtonClick?: () => void
  displaySecondaryButton?: boolean
  dangerButton?: boolean
  disableSecondaryButton?: boolean
}

const ButtonDisplay: React.FC<ButtonProps> = ({
  dangerButton,
  buttonTitle,
  onButtonClick,
  displaySecondaryButton,
  secondaryButtonTitle,
  onSecondaryButtonClick,
  disableButton,
  disableSecondaryButton,
}) => {
  const ButtonComponent = dangerButton ? DangerButton : Button
  const PrimaryButton = () => (
    <Grid item>
      <ButtonComponent
        variant="contained"
        color="secondary"
        onClick={onButtonClick}
        data-testid="page-header-button"
        disabled={disableButton}
      >
        {buttonTitle}
      </ButtonComponent>
    </Grid>
  )
  const SecondaryButton = () => (
    <Grid item>
      <Button
        variant="contained"
        color="secondary"
        onClick={onSecondaryButtonClick}
        data-testid="page-header-secondary-button"
        sx={{ ml: 2 }}
        disabled={disableSecondaryButton}
      >
        {secondaryButtonTitle}
      </Button>
    </Grid>
  )
  if (
    displaySecondaryButton &&
    secondaryButtonTitle &&
    onSecondaryButtonClick
  ) {
    return (
      <Grid item>
        <Grid container justifyContent="space-between">
          <PrimaryButton />
          <SecondaryButton />
        </Grid>
      </Grid>
    )
  }
  return <PrimaryButton />
}
