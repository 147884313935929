import { useEffect } from 'react'

import { styled } from '@mui/material/styles'

import {
  AppBar,
  Grid,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import LanguageSwitch from 'components/LanguageSwitch'
import LogoutButton from 'components/LogoutButton'
import OnboardingBanner from 'components/OnboardingBanner'
import OrgLogo from 'components/OrgLogo'
import { Select } from 'components/SharedComponents'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import routes from 'navigation/routes'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'redux-query-react'
import { ProfileState } from 'redux/profile/types'
import { Customer, SafetyExpert } from 'services/model'
import { getAllCustomers, getSelfCustomer } from 'services/queries-typed'
import { colors, fonts, theme } from 'themes'

const PREFIX = 'ArgumedAppBar'

const classes = {
  appBar: `${PREFIX}-appBar`,
  container: `${PREFIX}-container`,
  customerSelect: `${PREFIX}-customerSelect`,
  userName: `${PREFIX}-userName`,
  companyName: `${PREFIX}-companyName`,
}

const StyledAppBar = styled(AppBar)(() => ({
  [`&.${classes.appBar}`]: {
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 2rem',
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.customerSelect}`]: {
    width: '80%',
    fontSize: '1rem',
  },

  [`& .${classes.userName}`]: {
    fontSize: '1rem',
    color: colors.linen,
  },

  [`& .${classes.companyName}`]: {
    fontFamily: fonts.base,
    fontSize: '1.3rem',
    color: colors.linen,
    whiteSpace: 'nowrap',
  },
}))

interface Props {
  user: ProfileState
  safetyExpert?: SafetyExpert
  flags: Record<string, any>
}

export const ArgumedAppBar: React.FC<Props> = ({
  user,
  safetyExpert,
  flags,
}) => {
  const permissions = useSelector((state) => state.profile.permissions)
  const customer = useSelector((state) => state.entities.customer)
  const customers = useSelector((state) => state.entities.customers)

  const showOnboardingBanner =
    !flags?.enableLocationBasedAudit && customer?.status === 'created'

  const [{ isPending: isGettingCustomers }, getCustomers] =
    useMutation(getAllCustomers)
  const [, getCustomer] = useMutation(getSelfCustomer)
  const displayCustomer = user.emailVerified || !flags.emailVerification

  const history = useHistory()

  const routeMatch = useRouteMatch({
    path: '/:customerId/',
    strict: true,
  })

  const customerId =
    (routeMatch?.params as Record<string, string>)?.customerId || null

  useEffect(() => {
    if (displayCustomer) {
      if (permissions.includes('weissbier:list:customer')) {
        customerId ? getCustomers(customerId) : getCustomers()
      } else {
        getCustomer()
      }
    }
  }, [permissions, getCustomers, getCustomer, displayCustomer, customerId])

  const handleChangeCustomerSelected = (event: SelectChangeEvent<unknown>) => {
    if (typeof event.target.value === 'string' && !isGettingCustomers) {
      getCustomers(event.target.value as Customer['id'])
      // FIXME: improve by replacing customerId in place
      // All queries/components must depend on customerId from useParams
      history.push(routes.dashboard(event.target.value as Customer['id']))
    }
  }

  return (
    <StyledAppBar position="sticky" className={classes.appBar}>
      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.container}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs={1}>
                <OrgLogo color={colors.linen} />
              </Grid>
              <Grid item xs={5}>
                {displayCustomer &&
                  (customers && customers.length > 0 ? (
                    <Select
                      value={customer?.id}
                      onChange={handleChangeCustomerSelected}
                      data-testid="appbar-customer-select"
                      className={classes.customerSelect}
                    >
                      {customers.map((cstmr) => (
                        <MenuItem key={cstmr.id} value={cstmr.id}>
                          {cstmr.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography
                      align="left"
                      variant="h4"
                      data-testid="appbar-customer-name"
                      className={classes.companyName}
                    >
                      {customer?.name}
                    </Typography>
                  ))}
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={6}>
                    <Typography
                      align="right"
                      variant="h6"
                      data-testid="appbar-user-name"
                      className={classes.userName}
                    >
                      {user?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <LanguageSwitch data-testid="language-switch" />
                  </Grid>
                  <Grid item xs={2}>
                    <LogoutButton data-testid="logout-button" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {showOnboardingBanner && displayCustomer && (
        <OnboardingBanner safetyExpert={safetyExpert} />
      )}
    </StyledAppBar>
  )
}

export default withLDConsumer()(ArgumedAppBar)
