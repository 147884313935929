import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createLogger } from 'redux-logger'
import { queryMiddleware as createQueryMiddleware } from 'redux-query'
import superagentInterface from 'redux-query-interface-superagent'

import rootReducer from './root-reducer'

// the logger master switch
const USE_LOGGING = false

// create the logger
const logger = createLogger({
  predicate: (getState, { type }) => USE_LOGGING,
})

const queryMiddleware = createQueryMiddleware(
  superagentInterface,
  (state) => state.queries,
  (state) => state.entities
)

export { logger, queryMiddleware }

// a function which can create our store and auto-persist the data
const reduxStore = () => {
  // add our normal middleware to the list
  const middlewares = applyMiddleware(logger, queryMiddleware)

  // https://github.com/zalmoxisus/redux-devtools-extension
  const composeEnhancers = composeWithDevTools({})

  const store = createStore(rootReducer, composeEnhancers(middlewares))

  return store
}
export default reduxStore
