import { Button, Grid, Paper } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FileDropzone, { FileUploadType } from './FileDropzone'
import FileTile from './FileTile'

interface DropzoneProps {
  onSave: (files: File[]) => void
  onCancel: () => void
  acceptFormatOnly?: FileUploadType.IMAGE | FileUploadType.DOC
  acceptMultiple?: boolean
}

const FormFileDropzone = ({
  onSave,
  onCancel,
  acceptFormatOnly,
  acceptMultiple = true,
}: DropzoneProps) => {
  const { t } = useTranslation()
  const [files, setFiles] = useState<File[]>([])
  const hasNoSelectedFiles = files.length === 0
  const handleClose = () => {
    setFiles([])
    onCancel()
  }
  const handleUpload = () => {
    onSave(files)
    setFiles([])
    onCancel()
  }
  const handleDrop = (newFiles: File[]) => {
    if (acceptMultiple) {
      setFiles([...files, ...newFiles])
    } else {
      setFiles([...newFiles])
    }
  }
  const handleRemoveFile = (oldFile: File) => {
    const updatedFiles = files.filter((file) => file !== oldFile)
    setFiles(updatedFiles)
  }
  return (
    <Paper sx={{ p: 4 }} data-testid="upload-measure-files-form">
      <FileDropzone
        onFileDrop={handleDrop}
        acceptFormatOnly={acceptFormatOnly}
        acceptMultiple={acceptMultiple}
      />
      <Grid container>
        {files.map((file, i) => (
          <Grid item key={i} sx={{ p: 1 }}>
            <FileTile file={file} onDelete={handleRemoveFile} deletable />
          </Grid>
        ))}
      </Grid>
      <Grid container justifyContent="flex-end" spacing={2} sx={{ pt: 4 }}>
        <Grid item>
          <Button
            variant="outlined"
            onClick={handleClose}
            className="outlined-form-btn"
            data-testid="cancel-measure-files-edit-btn"
          >
            {t('global.buttons.cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleUpload}
            className="outlined-form-btn"
            disabled={hasNoSelectedFiles}
            data-testid="upload-measure-files-btn"
          >
            {t('global.buttons.upload')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FormFileDropzone
