import { Column } from 'components/Table'
import { List } from 'containers/list'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Customer, Division } from 'services/model'
import { getFacilities } from 'services/queries-typed'

interface Props {
  customer: Customer
}

export const DivisionsList: React.FC<Props> = ({ customer }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { url } = useRouteMatch()

  const columns: Column[] = [
    { label: t('id'), getter: (division: Division) => division.id },
    {
      label: t('location'),
      getter: (division: Division) => division.location,
      sortKey: 'location',
    },
    {
      label: t('operational_area'),
      getter: (division: Division) => division.operational_area,
      sortKey: 'operational_area',
    },
    {
      label: t('managers'),
      getter: (division: Division) => division.managers_count,
    },
  ]

  const handleGoToDivisionProfile = (division: { id: number }) => {
    history.push(`${url}/${division.id}`)
  }

  return (
    <List
      key={customer?.id}
      customer={customer}
      columns={columns}
      query={getFacilities}
      record="divisions"
      onRowClick={handleGoToDivisionProfile}
    />
  )
}

export default DivisionsList
