import { IconButton, Box, Paper, Grid, Button, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Document, FileType, MeasureImageWithUrl } from 'services/model'
import { DefaultDocIcon, PdfIcon, DocTypeIcon, XlsIcon } from 'themes/icons'
import { theme } from 'themes'
import { t } from 'i18next'
import { useCallback } from 'react'

const getFileTypeIcon = (type: FileType) => {
  switch (type) {
    case 'pdf':
      return PdfIcon
    case 'doc':
      return DocTypeIcon
    case 'xls':
      return XlsIcon
    default:
      return DefaultDocIcon
  }
}

interface Props {
  file: any
  deletable?: boolean
  onDelete?: (file: any) => void
  onClick?: (file: any) => void
}

export const FileTile = ({ file, deletable, onDelete, onClick }: Props) => {
  const getContentTile = () => {
    if (file.file_type) {
      // Weissbier document file
      return <DocumentTile file={file} />
    } else if (file.s3_key || file.type?.startsWith('image/')) {
      // Weissbier image file or uploaded image preview file without s3_key
      return <ImageTile file={file} />
    } else {
      // Uploaded document preview file
      return <DocumentTile file={file} />
    }
  }

  const handleDelete = useCallback(() => onDelete && onDelete(file), [file])

  const handleClick = useCallback(() => onClick && onClick(file), [file])

  const deleteButton = (
    <IconButton
      onClick={handleDelete}
      className="FileCardDeleteIcon"
      size="large"
      data-testid="delete-file-icon"
    >
      <DeleteIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
    </IconButton>
  )

  return (
    <Paper sx={{ width: '10rem', height: '8rem' }}>
      <Grid container justifyContent={'space-around'} height={'inherit'}>
        <Grid item xs={2}>
          {deletable && deleteButton}
        </Grid>
        <Grid item xs={8} height="inherit" textAlign={'center'}>
          <Button
            onClick={handleClick}
            sx={{ height: 'inherit', width: 'inherit' }}
            className="fileTileDownloadButton"
          >
            <Box height="inherit" sx={{ p: 2, textAlign: 'center' }}>
              {getContentTile()}
            </Box>
          </Button>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Paper>
  )
}

export default FileTile

interface DocumentTileProps {
  file: Document | File
}

export const DocumentTile = ({ file }: DocumentTileProps) => {
  // @ts-expect-error: File without file_type defaults to DefaultDocIcon
  const DocIcon = file.file_type ? getFileTypeIcon(file.file_type) : DefaultDocIcon
  return (
    <>
      <DocIcon />
      <Typography
        color="textSecondary"
        className="DocumentCardTitle"
        data-testid="document-tile-title"
        title={file.name}
      >
        {file.name}
      </Typography>
    </>
  )
}

interface ImageTileProps {
  file: MeasureImageWithUrl | any
}

const ImageTile = ({ file }: ImageTileProps) => (
  <img
    src={file.url}
    alt={t('fileDropzone.imageFile')}
    style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
    data-testid="image-tile"
  />
)
