import { Box, Link, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { useTranslation } from 'react-i18next'
import { CsvUploadDataType } from '.'
import config from 'services/config'

export interface Props {
  dataType: CsvUploadDataType
}

export const CsvFormatInfo: React.FC<Props> = ({ dataType }) => {
  const { t } = useTranslation()
  const headerValues = () => {
    switch (dataType) {
      case CsvUploadDataType.MANAGERS:
        return ['email', 'first_name', 'last_name', 'role']
      case CsvUploadDataType.EMPLOYEES:
        return [
          'email',
          'first_name',
          'last_name',
          'location',
          'operational_area',
        ]
      case CsvUploadDataType.DIVISIONS:
        return ['location', 'operational_area']
      default:
        return []
    }
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">
        <InfoIcon
          fontSize="small"
          sx={{ position: 'relative', top: 4, mr: 2 }}
        />
        {t('dropzone.csv_import.format_info')}
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        {t('dropzone.csv_import.header_requirements')}
      </Typography>
      {headerValues().map((header) => (
        <li key={header}>{t(`dropzone.csv_import.${header}`)}</li>
      ))}
      {dataType == CsvUploadDataType.MANAGERS && (
        <>
          <Typography sx={{ mt: 2, mb: 2 }}>
            {t('dropzone.csv_import.role_info')}
          </Typography>
          <li>{t('dropzone.csv_import.location_manager')}</li>
          <li>{t('dropzone.csv_import.facility_manager')}</li>
        </>
      )}
      {dataType == CsvUploadDataType.DIVISIONS && (
        <Typography sx={{ mt: 2, mb: 2 }}>
          {t('dropzone.csv_import.location_info')}
        </Typography>
      )}
      <Typography variant="h4" sx={{ mt: 4 }}>
        {t('dropzone.csv_import.download_template')}
        <Link
          href={`${config.ASSETS_URL}/csv-import/templates/add_example_${dataType}.csv`}
          download
        >
          {`add_example_${dataType}.csv`}
        </Link>
      </Typography>
    </Box>
  )
}

export default CsvFormatInfo
