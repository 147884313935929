import { useCallback, useEffect, useState } from 'react'

import { DocumentTable } from 'components/DocumentTable'
import { PageHeader } from 'containers/page-headers'
import routes, { DocumentRouteParams } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useMutation } from 'redux-query-react'
import { DocumentType, LibraryDocument, LibraryListItem } from 'services/model'
import {
  getS3Documents,
  getS3PresignedUrl,
  deleteS3Document,
} from 'services/queries-typed'

import { useBreadcrumbs, rootFolderNames, getTopLevelPrefix } from './utils'
import { ConfirmationModal } from 'components/ConfirmationModal'
import { useSnackbar } from 'notistack'

export const DocumentLibrary = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { customerId, folderPath } = useParams<DocumentRouteParams>()

  const [pageTitle, setPageTitle] = useState<string>()
  const breadcrumbs = useBreadcrumbs(customerId, folderPath)

  const [selectedDoc, setSelectedDoc] = useState<LibraryDocument | null>(null)

  const library = useSelector((state) => state.entities.library)
  const folderPermissions = useSelector(
    (state) => state.entities.library?.permissions
  )
  const canUpload = folderPermissions?.includes(
    'weissbier:create:library_document'
  )

  useEffect(() => {
    // clean folderPath from topLevelPrefixes & trailing slashes
    const folders = decodeURIComponent(folderPath)
      ?.replace(getTopLevelPrefix(customerId), '')
      .split('/')
      .filter(Boolean)
    const currentFolderName = folders && folders[folders.length - 1]

    setPageTitle(
      folderPath
        ? rootFolderNames.includes(currentFolderName)
          ? t(`documents.folders.${currentFolderName}`)
          : currentFolderName
        : t('documents.reference_library')
    )
  }, [customerId, folderPath, t])

  const [{ isPending: gettingDocuments }, getDocuments] =
    useMutation(getS3Documents)
  const [, getDocumentUrl] = useMutation(getS3PresignedUrl)
  const [{ isPending: deletingDocuments }, deleteDocument] =
    useMutation(deleteS3Document)
  useEffect(() => {
    if (folderPath) {
      getDocuments(decodeURIComponent(folderPath))
    } else {
      getDocuments(getTopLevelPrefix(customerId))
    }
  }, [folderPath, getDocuments, customerId])

  const handleClick = useCallback(
    (record: LibraryListItem) => {
      if (record.type === DocumentType.FOLDER) {
        history.push(
          routes.libraryFolder(customerId, encodeURIComponent(record.id))
        )
      } else {
        getDocumentUrl(record.id)?.then((response) =>
          window.open(response.body.data, '_blank')
        )
      }
    },
    [customerId, history, getDocumentUrl]
  )

  const goToUpload = useCallback(
    () => history.push(routes.libraryUpload(customerId, folderPath)),
    [customerId, history, folderPath]
  )

  const handleCancelDelete = () => {
    setSelectedDoc(null)
  }

  const handleDocumentDelete = useCallback(() => {
    if (selectedDoc) {
      deleteDocument(selectedDoc.id)
        ?.then((response) => {
          if (response.status === 204) {
            getDocuments(decodeURIComponent(folderPath))
            enqueueSnackbar(
              t('documents.delete.success', { fileName: selectedDoc.name }),
              { variant: 'success' }
            )
          } else {
            enqueueSnackbar(
              t('documents.delete.error', { fileName: selectedDoc.name }),
              { variant: 'error' }
            )
          }
        })
        .finally(() => setSelectedDoc(null))
    }
  }, [selectedDoc])

  const handleSelectDocument = (doc: LibraryDocument) => {
    setSelectedDoc(doc)
  }

  return (
    <PageHeader
      title={pageTitle}
      buttonTitle={t('documents.to_upload')}
      displayButton={canUpload}
      onButtonClick={goToUpload}
      breadcrumbs={breadcrumbs}
    >
      <ConfirmationModal
        open={Boolean(selectedDoc)}
        onConfirm={handleDocumentDelete}
        onClose={handleCancelDelete}
        disabled={gettingDocuments || deletingDocuments}
        confirmButtonCopy={t('confirmation_modal.delete')}
        bodyCopy={t('confirmation_modal.confirm_delete_document', {
          name: selectedDoc?.name,
        })}
      />
      <DocumentTable
        onDeleteDocument={handleSelectDocument}
        onRowClick={handleClick}
        list={library ? [...library.folders, ...library.documents] : []}
      />
    </PageHeader>
  )
}
