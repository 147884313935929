import { useEffect, useState } from 'react'

import { ConfirmationModal } from 'components/ConfirmationModal'
import ManagerProfile from 'containers/manager/profile'
import { PageHeader } from 'containers/page-headers'
import routes, { CustomerRouteParams, UserRouteParams } from 'navigation/routes'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from 'redux-query-react'
import { ActionPromiseValue, Entities } from 'redux-query'
import { Customer, CustomerUserRole, UserProps } from 'services/model'
import {
  createOhsAdmin,
  createOhsOfficer,
  createSafetyExpert,
  deleteLocationManager,
  deleteManager,
  deleteOhsOfficer,
  getCustomerUser,
} from 'services/queries-typed'
import { addUserlikeScript } from 'services/userlike'

import Import from './import'
import List from './list'
import UserForm from './form'

export const UserListPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { customerId } = useParams<CustomerRouteParams>()
  const customer = useSelector((state) => state.entities.customer)
  const permissions = useSelector((state) => state.profile.permissions)

  const handleGoToCSVUpload = () => {
    if (customer) history.push(routes.usersUpload(customerId))
  }
  const handleGoToAddUser = () => {
    if (customer) history.push(routes.addUser(customerId))
  }
  useEffect(() => {
    addUserlikeScript()
  }, [])

  return (
    <PageHeader
      title={t('users.title')}
      buttonTitle={t('users.add')}
      onButtonClick={handleGoToCSVUpload}
      displayButton={permissions.includes(
        'weissbier:create:managers_batch_update'
      )}
      secondaryButtonTitle={t('ohsOfficers.add_ohsOfficer.button')}
      onSecondaryButtonClick={handleGoToAddUser}
      displaySecondaryButton={permissions.includes('weissbier:create:user')}
    >
      {customer && <List customer={customer} />}
    </PageHeader>
  )
}

export const UserImportPage = () => {
  const { t } = useTranslation()
  const { customerId } = useParams<CustomerRouteParams>()

  useEffect(() => {
    addUserlikeScript()
  }, [])

  return (
    <PageHeader
      title={t('users.add')}
      breadcrumbs={[{ name: t('users.title'), path: routes.users(customerId) }]}
    >
      <Import />
    </PageHeader>
  )
}

export const UserProfilePage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { customerId, userId } = useParams<UserRouteParams>()
  const user = useSelector((state) => state.entities.customerUser)
  const canDeleteFacilityManager =
    useSelector((state) =>
      state.profile.permissions.includes('weissbier:delete:division_manager')
    ) && user?.role === CustomerUserRole.FACILITY_MANAGER
  const canDeleteLocationManager =
    useSelector((state) =>
      state.profile.permissions.includes('weissbier:delete:location_manager')
    ) && user?.role === CustomerUserRole.LOCATION_MANAGER
  const canDeleteOhsOfficer =
    useSelector((state) =>
      state.profile.permissions.includes('weissbier:delete:user')
    ) && user?.role === CustomerUserRole.OHS_OFFICER

  const [{ isPending }, fetchUser] = useMutation(getCustomerUser)
  const [{ isPending: isDeletingFacilityManager }, removeFacilityManager] =
    useMutation(deleteManager)
  const [{ isPending: isDeletingLocationManager }, removeLocationManager] =
    useMutation(deleteLocationManager)
  const [{ isPending: isDeletingOhsOfficer }, removeOhsOfficer] =
    useMutation(deleteOhsOfficer)
  const [userTitle, setUserTitle] = useState('')
  const [open, setOpen] = useState(false)

  const isManager =
    user &&
    [
      CustomerUserRole.FACILITY_MANAGER,
      CustomerUserRole.LOCATION_MANAGER,
    ].includes(user.role)

  const canDeleteUser =
    canDeleteFacilityManager || canDeleteLocationManager || canDeleteOhsOfficer
  const isProcessingQuery =
    isPending ||
    isDeletingFacilityManager ||
    isDeletingLocationManager ||
    isDeletingOhsOfficer

  const getRequestType = () => {
    switch (user?.role) {
      case CustomerUserRole.LOCATION_MANAGER:
        return removeLocationManager
      case CustomerUserRole.FACILITY_MANAGER:
        return removeFacilityManager
      case CustomerUserRole.OHS_OFFICER:
        return removeOhsOfficer
      default:
        return null
    }
  }

  useEffect(() => {
    if (customerId && userId) {
      fetchUser(customerId, userId)
    }
  }, [userId, customerId, fetchUser])

  useEffect(() => {
    addUserlikeScript()
  }, [])

  useEffect(() => {
    if (user) {
      if (user.first_name) {
        setUserTitle(`${user.first_name} ${user.last_name}`)
      } else if (user.email) {
        setUserTitle(user.email)
      } else {
        setUserTitle(user.id)
      }
    }
  }, [user])

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleOnDelete = () => {
    setOpen(false)
    const removeManagerRequest = getRequestType()
    if (removeManagerRequest) {
      removeManagerRequest(customerId, userId)
        ?.then((response) => {
          if (response.status === 204) {
            history.replace(routes.users(customerId))
          } else {
            enqueueSnackbar(t('users.delete_error'), { variant: 'error' })
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' })
        })
    } else {
      enqueueSnackbar(t('users.delete_error'), { variant: 'error' })
    }
  }

  return (
    <>
      {!isProcessingQuery && (
        <>
          <PageHeader
            title={userTitle}
            infoTitle={user && t(`users.user_roles.${user.role}`)}
            secondaryTitle={user?.email}
            breadcrumbs={[
              { name: t('users.title'), path: routes.users(customerId) },
            ]}
            buttonTitle={t('users.delete')}
            onButtonClick={handleOpenModal}
            dangerButton={true}
            displayButton={canDeleteUser}
          >
            <ConfirmationModal
              bodyCopy={t('users.delete_modal_body', { name: userTitle })}
              confirmButtonCopy={t('users.delete_confirm')}
              open={open}
              onClose={handleCloseModal}
              onConfirm={handleOnDelete}
            />
            {user && isManager && (
              <ManagerProfile
                customerId={customerId}
                managerId={userId}
                name={userTitle}
                role={user.role}
              />
            )}
          </PageHeader>
        </>
      )}
    </>
  )
}

enum NewUserType {
  admin = 'admin',
  safetyExpert = 'safetyExpert',
  ohsOfficer = 'ohsOfficer',
}

interface CreateUserProps {
  type: NewUserType
  customerId?: Customer['id']
}

export const CreateUserPage = ({ type, customerId }: CreateUserProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [, createAdmin] = useMutation(createOhsAdmin)
  const [, createOhsSafetyExpert] = useMutation(createSafetyExpert)
  const [, createOfficer] = useMutation(createOhsOfficer)

  const [route, setRoute] = useState<string>('/')

  useEffect(() => {
    addUserlikeScript()
  }, [])

  useEffect(() => {
    switch (type) {
      case NewUserType.admin:
        setRoute(routes.admins())
        break
      case NewUserType.safetyExpert:
        setRoute(routes.safetyExperts())
        break
      case NewUserType.ohsOfficer:
        if (customerId) {
          setRoute(routes.users(customerId))
        }
        break
      default:
        break
    }
  }, [type, customerId])

  const handleResponse = (response: ActionPromiseValue<Entities>) => {
    if (response.status < 400) {
      history.replace(route)
      enqueueSnackbar(t(`${type}s.add_${type}.success`), { variant: 'success' })
    } else {
      enqueueSnackbar(t(`${type}s.add_${type}.error`), { variant: 'error' })
    }
  }

  const onCreateUser = (attributes: Partial<UserProps>) => {
    console.log('New User: ', attributes)
    switch (type) {
      case NewUserType.admin:
        createAdmin(attributes)
          ?.then((response) => {
            handleResponse(response)
          })
          .catch((error) => {
            enqueueSnackbar(error, { variant: 'error' })
          })
        break
      case NewUserType.safetyExpert:
        createOhsSafetyExpert(attributes)
          ?.then((response) => {
            handleResponse(response)
          })
          .catch((error) => {
            enqueueSnackbar(error, { variant: 'error' })
          })
        break
      case NewUserType.ohsOfficer:
        if (customerId) {
          createOfficer(customerId, attributes)
            ?.then((response) => {
              handleResponse(response)
            })
            .catch((error) => {
              enqueueSnackbar(error, { variant: 'error' })
            })
        }
        break
      default:
        break
    }
  }

  return (
    <PageHeader
      title={t(`${type}s.add`)}
      breadcrumbs={[{ name: t(`${type}s.title`), path: route }]}
    >
      <UserForm onSubmit={onCreateUser} />
    </PageHeader>
  )
}
