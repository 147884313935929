import { useMemo } from 'react'

import { Grid } from '@mui/material'
import styled from 'styled-components'
import { colors, theme } from 'themes'

const HorizonatalArrow = styled(Grid)`
  position: relative;
  left: 0px;
  top: 100px;
  height: 50px;
`

const VerticalArrow = styled.svg`
  position: relative;
  left: 120px;
  top: 0px;
  height: 50px;
`

const Arrow = ({ direction }) => {
  const { points, DirectionalArrow, width, height } = useMemo(() => {
    switch (direction) {
      case 'up':
        return {
          points: '10,0 20,10 0,10',
          DirectionalArrow: VerticalArrow,
          width: '20',
          height: '10',
        }
      case 'left':
        return {
          points: '0,10 10,20 10,0',
          DirectionalArrow: HorizonatalArrow,
          width: '10',
          height: '20',
        }
      case 'right':
        return {
          points: '10,10 0,0 0,20',
          DirectionalArrow: HorizonatalArrow,
          width: '10',
          height: '20',
        }
      case 'down':
        return {
          points: '10,10 0,0 20,0',
          DirectionalArrow: VerticalArrow,
          width: '20',
          height: '10',
        }
      default:
        return {}
    }
  }, [direction])

  return DirectionalArrow ? (
    <DirectionalArrow item>
      <svg width={width} height={height}>
        <polygon
          points={points}
          strokeWidth="2"
          fill={theme.palette.warning.main}
        />
      </svg>
    </DirectionalArrow>
  ) : (
    <></>
  )
}

export default Arrow
