import { useEffect, useState } from 'react'

import { Button, LinearProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Dropzone from 'components/Dropzone'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { QueryConfigFactory, useMutation } from 'redux-query-react'
import { OHSEntitiesState } from 'redux/entities'
import { CSVMeta, Features } from 'services/model'

import SuccessMessage from './success-message'
import { importEmployees } from 'services/queries-typed'

export enum CsvUploadDataType {
  MANAGERS = 'managers',
  DIVISIONS = 'divisions',
  EMPLOYEES = 'employees',
}

interface Props {
  title: string
  dataType: CsvUploadDataType
  query: QueryConfigFactory<OHSEntitiesState, any[]>
  onError?: (customerId: string, error: any) => void
}

export const ImportScreen: React.FC<Props> = ({
  title,
  dataType,
  query,
  onError,
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [file, setFile] = useState<File | null>(null)
  const [showImportSuccess, setShowImportSuccess] = useState<boolean>(false)
  const [csvMeta, setCsvMeta] = useState<CSVMeta>({ added: 0 })
  const [sendEmails, setSendEmails] = useState<boolean | undefined>(undefined)

  const customer = useSelector((state) => state.entities.customer)

  const [{ isPending: isProcessing }, sendCSV] = useMutation(query)

  useEffect(() => {
    if (customer && query === importEmployees) {
      if (customer.features?.includes(Features.NO_EMAIL_INSTRUCTIONS)) {
        setSendEmails(false)
      } else {
        setSendEmails(true)
      }
    }
  }, [customer, query, setSendEmails])

  const action = (key: any) => (
    <Button
      onClick={() => {
        closeSnackbar(key)
      }}
    >
      <CloseIcon color="error" />
    </Button>
  )

  const handleUpload = () => {
    if (customer && file) {
      sendCSV(customer.id, file, sendEmails)
        ?.then((response) => {
          if ([201, 200].includes(response.status)) {
            setCsvMeta(response.body.meta)
            setShowImportSuccess(true)
          } else if (response.status === 400 && response.body.detail) {
            // Regex checks for "(someKey)=(someValue)", e.g. error message "ERROR: could not create unique index "importing_employee_pkey"\nDETAIL: Key (customer_id, facility_id, email)=(b5ad1f52-06a2-4f55-bee3-0ae15a77daff, 1, bobby@example) is duplicated.\n"
            const matchDuplicateKeyValue = (
              response.body.detail as string
            ).match(/(?<=\()[^\=]*(?=\))/gm)
            if (
              (response.body.detail.includes(
                'duplicate key value violates unique constraint'
              ) ||
                response.body.detail.includes(
                  'could not create unique index'
                )) &&
              matchDuplicateKeyValue
            ) {
              enqueueSnackbar(
                t('import.duplicate_error', {
                  key: matchDuplicateKeyValue[0],
                  value: matchDuplicateKeyValue[1],
                }),
                { variant: 'error', persist: true, action }
              )
            } else {
              enqueueSnackbar(response.body?.detail, {
                variant: 'error',
                persist: true,
                action,
              })
            }
          } else {
            enqueueSnackbar(t('import.error'), {
              variant: 'error',
              persist: true,
              action,
            })
          }
        })
        .catch((error) => {
          enqueueSnackbar(t('import.error'), {
            variant: 'error',
            persist: true,
            action,
          })
          if (onError) {
            onError(customer.id, error)
          }
        })
    }
  }
  const onUploadChange = (files: File[]) => {
    setFile(files[0])
  }

  const cancelUpload = () => {
    setFile(null)
  }

  return (
    <>
      {isProcessing && <LinearProgress />}
      {showImportSuccess && file && (
        <SuccessMessage csvMeta={csvMeta} file={file} title={title} />
      )}
      {!showImportSuccess && (
        <Dropzone
          uploadType="csv"
          filesLimit={1}
          handleUpload={handleUpload}
          onUploadChange={onUploadChange}
          cancelUpload={cancelUpload}
          files={file ? [file] : []}
          dataType={dataType}
        />
      )}
    </>
  )
}

export default ImportScreen
