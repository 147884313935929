import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

export const RoleGuard: React.FC<RouteProps> = ({ ...rest }) => {
  const hasAccess = useSelector((state) =>
    state.profile.permissions.includes('weissbier:read:measure')
  )
  const userExists = useSelector(
    (state) => state.queries['get-user']?.status !== 0
  )

  if (hasAccess && userExists) {
    return <Route {...rest} />
  } else if (!userExists) {
    return <Redirect to={{ pathname: '/user-not-found' }} />
  }
  return <Redirect to={{ pathname: '/post-signup' }} />
}
