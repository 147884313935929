import { useCallback, useEffect } from 'react'

import Callout from 'components/callout'

import { useAuth0, User } from '@auth0/auth0-react'
import { Button, Paper, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'redux-query-react'
import { requestVerificationEmail } from 'services/queries'
import { checkEmailVerifiedWithToken } from 'services/utils'
import { colors } from 'themes'

const useStyles = makeStyles()(() => ({
  root: {
    padding: '5rem',
    textAlign: 'center',
  },
  container: {
    margin: '0 auto',
    maxWidth: '50rem',
  },
  checkCircle: {
    position: 'relative',
    top: '0.4rem',
    color: colors.green,
  },
  marginBottom: {
    marginBottom: '2rem',
  },
}))

interface Props {
  flags: Record<string, any>
}

const EmailVerificationPage: React.FC<Props> = ({ flags }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const profile = useSelector((state) => state.profile)
  const { userId, email, emailVerified } = profile
  const [, requestEmail] = useMutation(requestVerificationEmail)

  const handleVerify = useCallback(() => {
    ;(async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          // @ts-ignore
          ignoreCache: window.Cypress ? false : true,
        })
        localStorage.setItem('access_token', accessToken)
        if (!checkEmailVerifiedWithToken(accessToken)) {
          enqueueSnackbar(t('email_verification.not_verified'), {
            variant: 'error',
          })
        }
      } catch (e: any) {
        console.error(e)
        if (e.error === 'login_required' || e.error === 'consent_required') {
          loginWithRedirect()
        }
      }
    })()
  }, [getAccessTokenSilently, loginWithRedirect, t, enqueueSnackbar])

  useEffect(() => {
    const url = window.location.hash

    if (url.includes('details=expired')) {
      enqueueSnackbar(t('email_verification.expired_error'), {
        variant: 'error',
      })
    } else if (url.includes('details=invalid')) {
      enqueueSnackbar(t('email_verification.invalid_error'), {
        variant: 'error',
      })
    }
  }, [t, enqueueSnackbar])

  useEffect(() => {
    if (emailVerified === true || !flags.emailVerification) {
      history.push('/')
    }
  }, [emailVerified, history, t, flags])

  const handleResend = () => {
    userId &&
      requestEmail(userId)
        ?.then((response) => {
          if (response.status === 204) {
            enqueueSnackbar(t('email_verification.resend_alert'), {
              variant: 'success',
            })
          } else {
            enqueueSnackbar(t('email_verification.send_email_error'), {
              variant: 'error',
            })
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: 'error',
          })
        })
  }

  return (
    <>
      <Callout primaryOnly />
      <Paper elevation={0} className={classes.root}>
        <Typography variant="h2">{t('email_verification.title')}</Typography>
        <Paper elevation={0} className={classes.container}>
          <VerificationActions
            email={email}
            onVerify={handleVerify}
            onResend={handleResend}
          />
        </Paper>
      </Paper>
    </>
  )
}

export default withLDConsumer()(EmailVerificationPage)

export interface ActionProps {
  email: User['email'] | null
  onVerify: () => void
  onResend: () => void
}

export const VerificationActions: React.FC<ActionProps> = ({
  email,
  onVerify,
  onResend,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography data-testid="email-verification-actions">
        {t('email_verification.message', { email })}
      </Typography>
      <Typography>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={onVerify}
        >
          {t('email_verification.verify')}
        </Button>
      </Typography>
      <Button onClick={onResend}>{t('email_verification.resend_email')}</Button>
    </>
  )
}
