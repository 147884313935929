import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import { Logo } from 'themes/images'

const useStyles = makeStyles()(() => ({
  logo: {
    width: '100%',
    height: '100%',
  },
}))

const OrgLogo = ({ color }) => {
  const { classes } = useStyles()
  return (
    <Typography align="center">
      <Logo
        data-testid="image"
        fill={color}
        className={classes.logo}
        alt="Argumed Logo"
      />
    </Typography>
  )
}

export default OrgLogo
