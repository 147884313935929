interface Config {
  OHS_BACKEND_URL: string
  AUTH0_OHS_API_AUDIENCE: string
  AUTH0_CLIENTID: string
  AUTH0_CONNECTION: string
  AUTH0_DOMAIN: string
  AUTH0_URL: string
  BOTFRONT_URL: string
  COVID_WEBCHAT_URL: string
  HORA_WEBCHAT_URL: string
  INSTRUCTION_WEBCHAT_URL: string
  ENABLED_LANGUAGES: string[]
  LD_CLIENT_SIDE_ID: string
  CUSTOM_CLAIM_PREFIX: 'https://claims.argumed.eu/'
  ASSETS_URL: string
}

const el = document.getElementById('app-config') as HTMLScriptElement
const configRaw = el.innerText
const configJson = atob(configRaw)
const config = JSON.parse(configJson) as Config

const COMMIT_HASH = process.env.REACT_APP_COMMIT_HASH

const ACTIVITY_TIMEOUT = 2 * 60 * 1000
const MAX_POLL_FREQUENCY = 2000
const MIN_POLL_FREQUENCY = 200
const POLL_FREQUENCY_EXP = 1.01
const MAX_POLL_ATTEMPTS = 5

const appConfig = {
  ...config,
  COMMIT_HASH,
  ACTIVITY_TIMEOUT,
  MAX_POLL_FREQUENCY,
  MIN_POLL_FREQUENCY,
  POLL_FREQUENCY_EXP,
  MAX_POLL_ATTEMPTS,
}
export default appConfig
