import { useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CloseIcon from '@mui/icons-material/Close'
import { Column } from 'components/Table'
import { CustomerElementsTable } from 'components/customer-elements-table'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Entities } from 'redux-query'
import { QueryConfigFactory, useMutation } from 'redux-query-react'
import { Customer, Features, Instruction, Manager, Order } from 'services/model'

const useStyles = makeStyles()((theme) => ({
  divider: {
    marginBottom: '2rem',
  },
  dialog: {
    margin: 0,
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogInfo: {
    paddingTop: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontWeight: 600,
  },
  dialogActionButton: {
    marginTop: 0,
    marginBottom: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}))

interface ElementModalProps {
  customerId: Customer['id']
  elementId: Manager['id'] | Instruction['id']
  name: string
  isOpen: boolean
  onClose: () => void
  elementType: 'divisions' | 'locations' | 'instructionDivisions'
  elementSelectorKey:
    | 'managerUnassignedLocations'
    | 'managerUnassignedDivisions'
    | 'instructionUnassignedDivisions'
  getElementQuery: QueryConfigFactory<
    Entities,
    [
      customerId: string,
      elementId: string,
      limit?: number,
      offset?: number,
      sortBy?: string,
      order?: Order
    ]
  >
  onSelectElementsQuery: QueryConfigFactory<
    Entities,
    [customerId: string, elementId: string, selectedRows: string[] | number[]]
  >
  columns: Column[]
}

export const AssignElementsModal: React.FC<ElementModalProps> = ({
  customerId,
  elementId,
  name,
  isOpen,
  onClose,
  elementType,
  elementSelectorKey,
  getElementQuery,
  onSelectElementsQuery,
  columns,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const customer = useSelector((state) => state.entities.customer)

  const [{ isPending }, assignElements] = useMutation(onSelectElementsQuery)

  const translationKey =
    elementType === 'instructionDivisions' &&
    customer?.features?.includes(Features.NO_EMAIL_INSTRUCTIONS)
      ? 'instructionDivisionsNoEmail'
      : elementType

  const handleCloseModal = () => {
    setSelectedRows([])
    onClose()
  }

  const resetModal = () => {
    handleCloseModal()
  }

  const handleAssign = () => {
    if (customer && selectedRows.length > 0) {
      assignElements(customerId, elementId, selectedRows)
        ?.then((response) => {
          if (response.status === 204) {
            enqueueSnackbar(
              t(`profile.add_${translationKey}.success`, {
                count: selectedRows.length,
              }),
              {
                variant: 'success',
              }
            )
          } else {
            enqueueSnackbar(t(`profile.add_${elementType}.error`), {
              variant: 'error',
            })
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: 'error',
          })
        })
        .finally(() => resetModal())
    }
  }

  const handleSelect = (rows: (number | string)[]) => {
    setSelectedRows(rows)
  }
  return (
    <>
      <Dialog
        onClose={handleCloseModal}
        open={isOpen}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle className={`${classes.dialog} ${classes.dialogTitle}`}>
          <Typography variant="h6">
            {t(`profile.assign_${elementType}_0`)}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseModal}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="subtitle2" className={classes.dialogInfo}>
            {t(`profile.assign_${translationKey}_info`, {
              count: selectedRows.length,
              context: `${selectedRows.length}`,
            })}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          {isPending ? (
            <CircularProgress />
          ) : (
            <>
              {customer && (
                <CustomerElementsTable
                  customerId={customerId}
                  elementId={elementId}
                  name={name}
                  columns={columns}
                  elementType={elementType}
                  elementSelectorKey={elementSelectorKey}
                  getElementQuery={getElementQuery}
                  onSelectElementsQuery={onSelectElementsQuery}
                  tableType="unassigned"
                  onSelect={handleSelect}
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.dialog}>
          <Button onClick={handleCloseModal} variant="text" color="secondary">
            {t('profile.cancel')}
          </Button>
          <Button
            data-testid={`assign-${elementType}-button`}
            disabled={selectedRows.length === 0}
            autoFocus
            onClick={handleAssign}
            variant="contained"
            color="secondary"
            className={classes.dialogActionButton}
          >
            {t(`profile.assign_${translationKey}`)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
