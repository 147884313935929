import { Box, Button, Grid } from '@mui/material'
import { isNonEmpty } from 'lib/helpers'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Customer, Measure, MeasureComment } from 'services/model'
import { theme } from 'themes'
import { FormTextfield } from './FormFields'

interface Props {
  onCreate: (text: Partial<MeasureComment>) => void
  measureId: Measure['id']
  customerId: Customer['id']
  canComment: boolean
}

export const CommentForm = ({
  onCreate,
  measureId,
  customerId,
  canComment,
}: Props) => {
  const { t } = useTranslation()

  const defaultValues = { text: '' }

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<Partial<MeasureComment>>({ defaultValues })

  useEffect(() => {
    reset(defaultValues)
  }, [])

  const handleClose = () => {
    reset(defaultValues)
  }

  const handleCreate = (text: Partial<MeasureComment>) => {
    onCreate(text)
    reset()
  }

  return (
    <Box
      component="form"
      pt={4}
      pb={4}
      onSubmit={handleSubmit(handleCreate)}
      noValidate
      data-testid="measure-comments-form"
    >
      <Grid
        container
        item
        xs={12}
        sx={{
          backgroundColor: theme.palette.background.paper,
          margin: '16px 0',
        }}
      >
        <FormTextfield
          name={'text'}
          control={control}
          editMode={canComment}
          placeholder={t('measure.comments.placeholder')}
          rules={{ validate: isNonEmpty(t('measure.comments.comment')) }}
          multiline={true}
          rows={3}
        />
      </Grid>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            variant="outlined"
            onClick={handleClose}
            className="outlined-form-btn"
            disabled={!isDirty}
            data-testid="cancel-measure-comments-create-btn"
          >
            {t('global.buttons.cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            key="preventDoubleSubmit"
            className="outlined-form-btn"
            disabled={!isDirty}
            data-testid="save-measure-comment-btn"
          >
            {t('global.buttons.save')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
