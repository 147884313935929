import moment from 'moment'
import { Entities } from 'redux-query'
import {
  EmployeeDashboardInstructions,
  EmployeeState,
  Library,
  OHSEntitiesState,
} from 'redux/entities'
import { Language } from 'redux/settings/types'
import { processFileStream } from 'lib'

import config from './config'
import {
  AssignedDivisionInstruction,
  AssignedDivisionInstructions,
  CollectionResponse,
  CollectionWithMetaResponse,
  Customer,
  CustomerDivisionInstruction,
  CustomerLocation,
  CustomerUser,
  CustomerUsers,
  Division,
  InstructionDivision,
  DivisionEmployee,
  DivisionEmployeeWithInstructions,
  DivisionOverview,
  Divisions,
  Document,
  DocumentType,
  EmployeeInstructionSummary,
  ExtendedCustomerUser,
  ExtendedS3Response,
  GetDivisionEmployeesInstructionsResponse,
  Instruction,
  Instructions,
  ItemResponse,
  LibraryDocument,
  LibraryFolder,
  Manager,
  ManagerLocation,
  ManagerLocations,
  Managers,
  Measure,
  MeasureComment,
  MeasureFilters,
  MeasureFiltersResponse,
  MeasureImage,
  OHSAdmin,
  OHSAdmins,
  Order,
  PaginatedCollectionResponse,
  Pagination,
  SafetyExpert,
  SafetyExperts,
  UserProps,
  Employee,
} from './model'
import {
  createFormData,
  formDataOptions,
  getBilingualOptions,
  options,
} from './utils'
import { ResponsibleProps } from 'components/MeasureResponsiblesTable'

export const importEmployees = (
  customerId: Customer['id'],
  csv: File,
  sendEmails: Boolean
) => {
  const formData = createFormData(csv, 'employees_csv')
  return {
    url: `${config.OHS_BACKEND_URL}/customers/${customerId}/employees_batch_updates?sending_emails=${sendEmails}`,
    queryKey: 'import-employees',
    options: {
      ...formDataOptions,
      method: 'POST',
    },
    body: formData,
    update: {},
  }
}

const transformDivisionInstructionsMap = (
  response: CollectionResponse<CustomerDivisionInstruction>
) => {
  const rawInstructions = response.data
  const divisionInstructionsMap: EmployeeState['divisionInstructionsMap'] = {}

  rawInstructions.forEach((instruction) => {
    divisionInstructionsMap[instruction.facility_id] = {
      ...divisionInstructionsMap[instruction.facility_id],
      [instruction.instruction_id]: true,
    }
  })

  return {
    divisionInstructionsMap,
  }
}

// TODO: Create a generalized transform function to handle all PaginatedCollectionResponse types

const transformDivisionsResponse = (
  response: PaginatedCollectionResponse<Division>
) => ({ divisions: { list: response.data, meta: response.meta } })

const transformManagerDivisionsResponse = (
  response: PaginatedCollectionResponse<Division>
) => ({ managerDivisions: { list: response.data, meta: response.meta } })

const transformManagerUnassignedDivisionsResponse = (
  response: PaginatedCollectionResponse<Division>
) => ({
  managerUnassignedDivisions: { list: response.data, meta: response.meta },
})

const transformManagersResponse = (
  response: PaginatedCollectionResponse<Manager>
) => ({ managers: { list: response.data, meta: response.meta } })

const transformInstructions = (
  response: PaginatedCollectionResponse<Instruction>
) => ({
  instructions: { list: response.data, meta: response.meta },
})

const transformDivisionOverviewList = (
  response: CollectionResponse<DivisionOverview>
) => {
  return {
    divisionOverviewList: response.data,
  }
}

const transformDivision = (response: ItemResponse<Division>) => {
  return {
    division: response.data,
  }
}

const transformCustomer = (data: ItemResponse<Customer>) => ({
  customer: data.data,
})

const transformCustomers =
  (id?: string) => (data: CollectionResponse<Customer>) => ({
    customers: data.data,
    customer: id
      ? data.data.find((cstmr) => cstmr.id === id) || data.data?.[0]
      : data.data?.[0],
  })

const transformCustomerUsersResponse = (
  response: PaginatedCollectionResponse<ExtendedCustomerUser>
) => ({ customerUsers: { list: response.data, meta: response.meta } })

const transformCustomerUserResponse = (
  response: ItemResponse<CustomerUser>
) => ({
  customerUser: response.data,
})

const transformManagerLocationsResponse = (
  response: PaginatedCollectionResponse<ManagerLocation>
) => ({ managerLocations: { list: response.data, meta: response.meta } })

const transformManagerUnassignedLocationsResponse = (
  response: PaginatedCollectionResponse<ManagerLocation>
) => ({
  managerUnassignedLocations: { list: response.data, meta: response.meta },
})

const transformUnauditedLocationsResponse = (
  response: CollectionResponse<CustomerLocation>
) => ({
  unauditedLocations: response.data,
})

const transformInstructionDivisionsResponse = (
  response: PaginatedCollectionResponse<InstructionDivision>
) => ({
  instructionDivisions: { list: response.data, meta: response.meta },
})

const transformInstructionUnassignedDivisionsResponse = (
  response: PaginatedCollectionResponse<Division>
) => ({
  instructionUnassignedDivisions: { list: response.data, meta: response.meta },
})

const transformInstruction = (response: ItemResponse<Instruction>) => {
  return {
    instruction: response.data,
  }
}

const transformMeasureComments = (
  response: CollectionResponse<MeasureComment>
) => {
  return {
    measureComments: response.data,
  }
}

const transformEmployeeInstructions = (
  response: CollectionWithMetaResponse<
    EmployeeInstructionSummary,
    { total_count: number; passed_count: number }
  >
): { employeeDashboardInstructions: EmployeeDashboardInstructions } => {
  // Necessary as the table needs one ID per row
  const list = response.data.map((instructionSummary) => ({
    ...instructionSummary,
    id: instructionSummary.instruction.id,
  }))

  return {
    employeeDashboardInstructions: {
      list,
      outstanding_count: response.meta.total_count - response.meta.passed_count,
    },
  }
}

const transformMeasure = (response: ItemResponse<Measure>) => {
  return {
    measure: response.data,
  }
}

const transformOhsAdminsResponse = (
  response: PaginatedCollectionResponse<OHSAdmin>
) => ({ admins: { list: response.data, meta: response.meta } })

const transformOhsAdminResponse = (response: ItemResponse<OHSAdmin>) => {
  return {
    admin: response.data,
  }
}

const transformSafetyExpertsResponse = (
  response: PaginatedCollectionResponse<SafetyExpert>
) => ({ safetyExperts: { list: response.data, meta: response.meta } })

const transformSafetyExpertResponse = (
  response: ItemResponse<SafetyExpert>
) => {
  return {
    safetyExpert: response.data,
  }
}

const transformActiveDivisionInstructionsResponse = (
  response: PaginatedCollectionResponse<AssignedDivisionInstruction>
) => ({
  activeDivisionInstructions: { list: response.data, meta: response.meta },
})

const transformExpiredDivisionInstructionsResponse = (
  response: PaginatedCollectionResponse<AssignedDivisionInstruction>
) => ({
  expiredDivisionInstructions: { list: response.data, meta: response.meta },
})

export const getMeasureFilters = (customerId: string) => {
  const url = `${config.OHS_BACKEND_URL}/customers/${customerId}/measure_filters`
  return {
    queryKey: 'get-measure-filters',
    url,
    force: true,
    options: {
      ...options,
      method: 'GET',
    },
    transform: (response: MeasureFiltersResponse) => ({
      measureFilters: response.data,
    }),
    update: {
      measureFilters: (
        _prevOptions: OHSEntitiesState['measureFilters'],
        currentOptions: MeasureFilters
      ) => currentOptions,
    },
  }
}

export const getDivisionsInstructions = (customerId: Customer['id']) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/facility_instructions`,
  queryKey: 'get-divisions-instructions',
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformDivisionInstructionsMap,
  update: {
    divisionInstructionsMap: (
      _prevData: EmployeeState['divisionInstructionsMap'],
      data: EmployeeState['divisionInstructionsMap']
    ) => data,
  },
})

export const getInstructions = (
  customerId: Customer['id'],
  limit: number,
  offset: number,
  sortBy: string,
  order: Order,
  language: Language
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/instructions`,
  queryKey: `get-instructions-${language}`,
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  options: {
    ...getBilingualOptions(language),
    method: 'GET',
  },
  force: true,
  transform: transformInstructions,
  update: {
    instructions: (_prevData: Instructions, data: Instructions) => data,
  },
})

export const getFacilityInstructionsOverview = (
  customerId: Customer['id']
) => ({
  queryKey: 'get-facility-instructions-overview',
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/facility_instructions/results`,
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformDivisionOverviewList,
  update: {
    divisionOverviewList: (
      _prevData: DivisionOverview[],
      data: DivisionOverview[]
    ) => data,
  },
})

export const getDivisionEmployeesInstructions = (
  customerId: Customer['id'],
  divisionId: Division['id'],
  limit = 100,
  offset = 0
) => ({
  queryKey: 'get-facility-employees-instructions',
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/facilities/${divisionId}/employees_instructions?limit=${limit}&offset=${offset}`,
  options: {
    ...options,
    method: 'GET',
  },
  transform: (response: GetDivisionEmployeesInstructionsResponse) => ({
    divisionEmployeesInstructions: { list: response.data, meta: response.meta },
  }),
  update: {
    divisionEmployeesInstructions: (
      _prevData:
        | { list: DivisionEmployeeWithInstructions[]; meta: Pagination }
        | undefined,
      data: { list: DivisionEmployeeWithInstructions[]; meta: Pagination }
    ) => data,
  },
})

export const getFacility = (
  customerId: Customer['id'],
  divisionId: Division['id']
) => ({
  queryKey: 'get-facility',
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/facilities/${divisionId}`,
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformDivision,
  update: {
    division: (_prevData: Division, data: Division) => data,
  },
})

export const importFacilities = (customerId: Customer['id'], csv: File) => {
  const formData = createFormData(csv, 'facilities_csv')
  return {
    url: `${config.OHS_BACKEND_URL}/customers/${customerId}/facilities_batch_updates`,
    queryKey: 'import-facilities',
    options: {
      ...formDataOptions,
      method: 'POST',
    },
    body: formData,
    update: {},
  }
}

export const importManagers = (customerId: Customer['id'], csv: File) => {
  const formData = createFormData(csv, 'managers_csv')
  return {
    url: `${config.OHS_BACKEND_URL}/customers/${customerId}/managers_batch_updates`,
    queryKey: 'import-managers',
    options: {
      ...formDataOptions,
      method: 'POST',
    },
    body: formData,
    update: {},
  }
}

export const getFacilities = (
  customerId: Customer['id'],
  limit?: number,
  offset?: number,
  sortBy?: string,
  order?: Order
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/facilities`,
  queryKey: 'get-facilities',
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformDivisionsResponse,
  update: {
    divisions: (_prevData: OHSEntitiesState['divisions'], data: Divisions) =>
      data,
  },
})

export const getManagers = (
  customerId: Customer['id'],
  limit?: number,
  offset?: number,
  sortBy?: string,
  order?: Order
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/division_managers`,
  queryKey: 'get-division-managers',
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformManagersResponse,
  update: {
    managers: (_prevData: OHSEntitiesState['managers'], data: Managers) => data,
  },
})

export const getManagerDivisions = (
  customerId: Customer['id'],
  managerId: Manager['id'],
  limit?: number,
  offset?: number,
  sortBy?: string,
  order?: Order
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/division_managers/${managerId}/divisions`,
  queryKey: 'get-division-manager-divisions',
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformManagerDivisionsResponse,
  update: {
    managerDivisions: (
      _prevData: OHSEntitiesState['divisions'],
      data: Divisions
    ) => data,
  },
})

export const getManagerUnassignedDivisions = (
  customerId: Customer['id'],
  managerId: Manager['id'],
  limit?: number,
  offset?: number,
  sortBy?: string,
  order?: Order
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/division_managers/${managerId}/unassigned_divisions`,
  queryKey: 'get-manager-unassigned-divisions',
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformManagerUnassignedDivisionsResponse,
  update: {
    managerUnassignedDivisions: (
      _prevData: OHSEntitiesState['managerUnassignedDivisions'],
      data: Divisions
    ) => data,
  },
})

export const assignManagerDivision = (
  customerId: Customer['id'],
  managerId: Manager['id'],
  divisionIds: Division['id'][]
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/division_managers/${managerId}/divisions`,
  queryKey: 'assign-manager-division',
  body: {
    data: divisionIds,
  },
  options: {
    ...options,
    method: 'POST',
  },
})

export const unassignManagerDivision = (
  customerId: Customer['id'],
  managerId: Manager['id'],
  divisionIds: Division['id'][]
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/division_managers/${managerId}/unassigned_divisions`,
  queryKey: 'unassign-manager-division',
  body: {
    data: divisionIds,
  },
  options: {
    ...options,
    method: 'POST',
  },
})

export const getSelfCustomer = () => ({
  url: `${config.OHS_BACKEND_URL}/customers/self`,
  queryKey: 'get-self-customer',
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformCustomer,
  update: {
    customer: (
      _prevData: OHSEntitiesState['customer'],
      data: Customer | undefined
    ) => data,
  },
})

export const getAllCustomers = (id?: string, force = true) => {
  const url = `${config.OHS_BACKEND_URL}/customers`
  return {
    queryKey: 'get-all-customers',
    url,
    options: {
      ...options,
      method: 'GET',
    },
    force,
    transform: transformCustomers(id),
    update: {
      customer: (
        _prevData: OHSEntitiesState['customer'],
        data: Customer | undefined
      ) => data,
      customers: (
        _prevData: OHSEntitiesState['customers'],
        data: Customer[] | undefined
      ) => data,
    },
  }
}

export const requestVerificationEmail = (id: string) => ({
  url: `${config.OHS_BACKEND_URL}/user_signups/${id}/email_verifications`,
  queryKey: 'request-verification-email',
  options: {
    ...options,
    method: 'POST',
  },
})

export const deleteManager = (
  customerId: Customer['id'],
  managerId: Manager['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/division_managers/${managerId}`,
  queryKey: 'delete-manager',
  options: {
    ...options,
    method: 'DELETE',
  },
})

export const deleteFacility = (
  customerId: Customer['id'],
  divisionId: Division['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/facilities/${divisionId}`,
  queryKey: 'delete-facility',
  options: {
    ...options,
    method: 'DELETE',
  },
})

export const getCustomerUsers = (
  customerId: Customer['id'],
  limit?: number,
  offset?: number,
  sortBy?: string,
  order?: Order
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/users`,
  queryKey: 'get-customer-users',
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformCustomerUsersResponse,
  update: {
    customerUsers: (
      _prevData: OHSEntitiesState['customerUsers'],
      data: CustomerUsers
    ) => data,
  },
})

export const getCustomerUser = (
  customerId: Customer['id'],
  userId: CustomerUser['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/users/${userId}`,
  queryKey: 'get-customer-user',
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformCustomerUserResponse,
  update: {
    customerUser: (
      _prevData: OHSEntitiesState['customerUser'],
      data: CustomerUser
    ) => data,
  },
})

export const getManagerLocations = (
  customerId: Customer['id'],
  managerId: Manager['id'],
  limit?: number,
  offset?: number,
  sortBy?: string,
  order?: Order
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/location_managers/${managerId}/locations`,
  queryKey: 'get-manager-locations',
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformManagerLocationsResponse,
  update: {
    managerLocations: (
      _prevData: OHSEntitiesState['managerLocations'],
      data: ManagerLocations
    ) => data,
  },
})

export const getManagerUnassignedLocations = (
  customerId: Customer['id'],
  managerId: Manager['id'],
  limit?: number,
  offset?: number,
  sortBy?: string,
  order?: Order
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/location_managers/${managerId}/unassigned_locations`,
  queryKey: 'get-manager-unassigned-locations',
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformManagerUnassignedLocationsResponse,
  update: {
    managerUnassignedLocations: (
      _prevData: OHSEntitiesState['managerUnassignedLocations'],
      data: ManagerLocations
    ) => data,
  },
})

export const assignManagerLocation = (
  customerId: Customer['id'],
  managerId: Manager['id'],
  locationIds: ManagerLocation['id'][]
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/location_managers/${managerId}/locations`,
  queryKey: 'assign-manager-location',
  body: {
    data: locationIds,
  },
  options: {
    ...options,
    method: 'POST',
  },
})

export const unassignManagerLocation = (
  customerId: Customer['id'],
  managerId: Manager['id'],
  locationIds: ManagerLocation['id'][]
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/location_managers/${managerId}/unassigned_locations`,
  queryKey: 'unassign-manager-location',
  body: {
    data: locationIds,
  },
  options: {
    ...options,
    method: 'POST',
  },
})

// FIXME: update to `deleteUser` once endpoint is available
export const deleteDivisionManager = (
  customerId: Customer['id'],
  userId: CustomerUser['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/division_managers/${userId}`,
  queryKey: 'delete-division-manager',
  options: {
    ...options,
    method: 'DELETE',
  },
})

// FIXME: update to `deleteUser` once endpoint is available
export const deleteLocationManager = (
  customerId: Customer['id'],
  userId: CustomerUser['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/location_managers/${userId}`,
  queryKey: 'delete-location-manager',
  options: {
    ...options,
    method: 'DELETE',
  },
})

export const getUnauditedLocations = (customerId: Customer['id']) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/unaudited_locations`,
  queryKey: 'get-unaudited-locations',
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformUnauditedLocationsResponse,
  update: {
    unauditedLocations: (
      _prevData: OHSEntitiesState['unauditedLocations'],
      data: CustomerLocation[]
    ) => data,
  },
})

export const deleteMeasureDocument = (
  customerId: Customer['id'],
  measureId: Measure['id'],
  documentId: Document['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures/${measureId}/documents/${documentId}`,
  queryKey: 'delete-measure-document',
  options: {
    ...options,
    method: 'DELETE',
  },
})

export const deleteMeasureImage = (
  customerId: Customer['id'],
  measureId: Measure['id'],
  imageId: MeasureImage['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures/${measureId}/images/${imageId}`,
  queryKey: 'delete-measure-image',
  options: {
    ...options,
    method: 'DELETE',
  },
})

export const getInstructionDivisions = (
  customerId: Customer['id'],
  instructionId: Instruction['id'],
  limit?: number,
  offset?: number,
  sortBy?: string,
  order?: Order
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/instructions/${instructionId}/divisions`,
  queryKey: 'get-instruction-divisions',
  options: {
    ...options,
    method: 'GET',
  },
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  transform: transformInstructionDivisionsResponse,
  update: {
    instructionDivisions: (
      _prevData: OHSEntitiesState['instructionDivisions'],
      data: OHSEntitiesState['instructionDivisions']
    ) => data,
  },
})

export const getInstructionUnassignedDivisions = (
  customerId: Customer['id'],
  instructionId: Instruction['id'],
  limit?: number,
  offset?: number,
  sortBy?: string,
  order?: Order
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/instructions/${instructionId}/unassigned_divisions`,
  queryKey: 'get-instruction-unassigned-divisions',
  options: {
    ...options,
    method: 'GET',
  },
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  transform: transformInstructionUnassignedDivisionsResponse,
  update: {
    instructionUnassignedDivisions: (
      _prevData: OHSEntitiesState['instructionUnassignedDivisions'],
      data: OHSEntitiesState['instructionUnassignedDivisions']
    ) => data,
  },
})

export const assignInstructionDivision = (
  customerId: Customer['id'],
  instructionId: Instruction['id'],
  divisionIds: Division['id'][]
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/instructions/${instructionId}/divisions`,
  queryKey: 'assign-instruction-division',
  body: {
    data: divisionIds,
  },
  options: {
    ...options,
    method: 'POST',
  },
})

export const unassignInstructionDivision = (
  customerId: Customer['id'],
  instructionId: Instruction['id'],
  divisionIds: Division['id'][]
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/instructions/${instructionId}/unassigned_divisions`,
  queryKey: 'unassign-instruction-division',
  body: {
    data: divisionIds,
  },
  options: {
    ...options,
    method: 'POST',
  },
})

export const getInstruction = (
  customerId: Customer['id'],
  instructionId: Instruction['id'],
  language: Language
) => ({
  queryKey: `get-instruction-${language}`,
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/instructions/${instructionId}`,
  options: {
    ...getBilingualOptions(language),
    method: 'GET',
  },
  transform: transformInstruction,
  update: {
    instruction: (_prevData: Instruction, data: Instruction) => data,
  },
})

export const getS3Documents = (prefix: string) => ({
  queryKey: 'get-s3-documents',
  url: `${config.OHS_BACKEND_URL}/s3/objects`,
  body: {
    Prefix: prefix,
  },
  options: {
    ...options,
    method: 'GET',
  },
  transform: (response: ExtendedS3Response) => {
    // Current folder
    const currentFolder = response.data.Prefix

    // Sub-folders in the current folder
    const folders: LibraryFolder[] = []

    const getItemName = (prefix: string) => {
      const elements = prefix.split('/').filter(Boolean)
      return elements[elements.length - 1]
    }

    if (response.data.CommonPrefixes) {
      response.data.CommonPrefixes.forEach(({ Prefix }: any) => {
        // Prefix example: "library/customers/c4057a25-6fe6-4082-ab83-71560dd50ab1/Appointments/"
        folders.push({
          id: Prefix,
          name: getItemName(Prefix),
          type: DocumentType.FOLDER,
        })
      })
    }

    // Documents in the current folder
    const documents: LibraryDocument[] = []

    if (response.data.Contents) {
      response.data.Contents.filter(
        (item) => item.Key !== currentFolder
      ).forEach((document) => {
        // Key example: "library/customers/c4057a25-6fe6-4082-ab83-71560dd50ab1/Appointments/my_file.png"
        documents.push({
          id: document.Key,
          name: getItemName(document.Key),
          lastModified: document.LastModified,
          size: document.Size,
          // TODO: replace with actual value from API:
          type: DocumentType.FILE,
        })
      })
    }

    return {
      library: {
        currentFolder,
        folders,
        documents,
        permissions: response.meta.permissions,
      },
    }
  },
  update: {
    library: (_prevData: Entities['library'], data: Library) => data,
  },
})

export const getS3PresignedUrl = (key: string) => ({
  queryKey: 'get-s3-presigned-url',
  url: `${config.OHS_BACKEND_URL}/s3/presigned_url`,
  options: {
    ...options,
    method: 'GET',
  },
  body: {
    Key: key,
  },
})

export const getS3PresignedPost = (key: string) => ({
  queryKey: 'get-s3-presigned-post',
  url: `${config.OHS_BACKEND_URL}/s3/presigned_post`,
  options: {
    ...options,
    method: 'GET',
  },
  body: {
    Key: key,
  },
})

export const uploadDocument = (
  document: File,
  url: string,
  fields: Record<string, string>
) => {
  const data = new FormData()

  Object.entries(fields).forEach((field) => data.append(field[0], field[1]))

  data.append('file', document)

  return {
    url: url,
    queryKey: `upload-S3-document-${document.name}`,
    options: {
      method: 'POST',
    },
    body: data,
    update: {},
  }
}

export const getEmployeeProfile = (
  customerId: Customer['id'],
  employeeId: string
) => ({
  queryKey: `get-employee-profile`,
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/employees/${employeeId}`,
  options: {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  },
  transform: (
    response: ItemResponse<DivisionEmployee>
  ): { employeeDashboardProfile: DivisionEmployee } => ({
    employeeDashboardProfile: response.data,
  }),
  update: {
    employeeDashboardProfile: (
      _prevData: EmployeeState['employeeDashboardProfile'],
      data: DivisionEmployee
    ) => data,
  },
})

export const getEmployeeInstructionsSummary = (
  customerId: Customer['id'],
  employeeId: string,
  language: Language
) => ({
  queryKey: `get-employee-instructions-summary`,
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/employees/${employeeId}/instructions_summary`,
  options: {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': language,
    },
    method: 'GET',
  },
  transform: transformEmployeeInstructions,
  update: {
    employeeDashboardInstructions: (
      _prevData: EmployeeState['employeeDashboardInstructions'],
      data: EmployeeDashboardInstructions
    ) => data,
  },
})

export const getEmployeeInstructionsResultsDetails = (
  customerId: Customer['id'],
  language: Language,
  filename: string
) => {
  return fetch(
    `${config.OHS_BACKEND_URL}/customers/${customerId}/instructions_results_details`,
    getBilingualOptions(language)
  ).then((response) => {
    processFileStream(response, filename)
  })
}

export const deleteS3Document = (key: string) => ({
  queryKey: 'delete-s3-document',
  url: `${config.OHS_BACKEND_URL}/s3/objects?Key=${encodeURIComponent(key)}`,
  options: {
    ...options,
    method: 'DELETE',
  },
})

export const getMeasure = (
  customerId: Customer['id'],
  measureId: Measure['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures/${measureId}`,
  queryKey: 'get-measure',
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformMeasure,
  update: {
    measure: (_prevData: OHSEntitiesState['measure'], data: Measure) => data,
  },
})

export const updateMeasureResponsible = (
  customerId: Customer['id'],
  measureId: Measure['id'],
  responsible: ResponsibleProps
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures/${measureId}/responsible`,
  queryKey: 'update-measure-responsible',
  options: {
    ...options,
    method: 'POST',
  },
  body: { data: responsible },
})

export const deleteMeasureResponsible = (
  customerId: Customer['id'],
  measureId: Measure['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures/${measureId}/responsible`,
  queryKey: 'delete-measure-responsible',
  options: {
    ...options,
    method: 'DELETE',
  },
})

export const emailMeasure = (
  customerId: Customer['id'],
  measureId: Measure['id'],
  measure: Measure
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures/${measureId}/email_measure`,
  queryKey: 'email-measure',
  options: {
    ...options,
    method: 'POST',
  },
  body: { data: measure },
})

export const exportMeasures = (
  customerId: Customer['id'],
  division_ids: []
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/exports`,
  queryKey: 'create_export',
  options: {
    ...options,
    method: 'POST',
  },
  body: { data: division_ids },
})

export const sendCustomerInstructionReminderEmails = (
  customerId: Customer['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/facility_instructions/reminders`,
  queryKey: 'send-customer-instruction-reminders',
  options: {
    ...options,
    method: 'POST',
  },
})
export const sendCustomerFacilityInstructionReminderEmails = (
  customerId: Customer['id'],
  faiclityId: Division['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/facilities/${faiclityId}/facility_instructions/reminders`,
  queryKey: 'send-customer-instruction-reminders',
  options: {
    ...options,
    method: 'POST',
  },
})
export const sendEmployeeInstructionReminderEmails = (
  customerId: Customer['id'],
  email: DivisionEmployee['email']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/employees/${email}/instructions_reminders`,
  queryKey: 'send-employee-instruction-reminders',
  options: {
    ...options,
    method: 'POST',
  },
})

export const getOhsAdmins = (
  limit?: number,
  offset?: number,
  sortBy?: string,
  order?: Order
) => ({
  url: `${config.OHS_BACKEND_URL}/admins`,
  queryKey: 'get-ohs-admins',
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformOhsAdminsResponse,
  update: {
    admins: (_prevData: OHSEntitiesState['admins'], data: OHSAdmins) => data,
  },
})

export const getOhsAdmin = (userId: OHSAdmin['id']) => ({
  url: `${config.OHS_BACKEND_URL}/admins/${userId}`,
  queryKey: 'get-ohs-admin',
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformOhsAdminResponse,
  update: {
    admin: (_prevData: OHSEntitiesState['admin'], data: OHSAdmin) => data,
  },
})

export const deleteOhsAdmin = (userId: OHSAdmin['id']) => ({
  url: `${config.OHS_BACKEND_URL}/admins/${userId}`,
  queryKey: 'delete-ohs-admin',
  options: {
    ...options,
    method: 'DELETE',
  },
})

export const createOhsAdmin = (admin: Partial<UserProps>) => ({
  url: `${config.OHS_BACKEND_URL}/admins`,
  queryKey: 'create-ohs-admin',
  options: {
    ...options,
    method: 'POST',
  },
  body: { data: admin },
})

export const getMeasureComments = (
  customerId: Customer['id'],
  measureId: Measure['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures/${measureId}/comments`,
  queryKey: 'get-measure-comments',
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformMeasureComments,
  update: {
    measureComments: (
      _prevData: OHSEntitiesState['measureComments'],
      data: MeasureComment[]
    ) => data,
  },
})

export const createMeasureComment = (
  customerId: Customer['id'],
  measureId: Measure['id'],
  comment: Partial<MeasureComment>
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures/${measureId}/comments`,
  queryKey: 'create-measure-comment',
  options: {
    ...options,
    method: 'POST',
  },
  body: { data: comment },
})

export const getSafetyExperts = (
  limit?: number,
  offset?: number,
  sortBy?: string,
  order?: Order
) => ({
  url: `${config.OHS_BACKEND_URL}/safety_experts`,
  queryKey: 'get-safety-experts',
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
  },
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformSafetyExpertsResponse,
  update: {
    safetyExperts: (
      _prevData: OHSEntitiesState['safetyExperts'],
      data: SafetyExperts
    ) => data,
  },
})

export const getSafetyExpert = (userId: SafetyExpert['id']) => ({
  url: `${config.OHS_BACKEND_URL}/safety_experts/${userId}`,
  queryKey: 'get-safety-expert',
  options: {
    ...options,
    method: 'GET',
  },
  transform: transformSafetyExpertResponse,
  update: {
    safetyExpert: (
      _prevData: OHSEntitiesState['safetyExpert'],
      data: SafetyExpert
    ) => data,
  },
})

export const deleteSafetyExpert = (userId: SafetyExpert['id']) => ({
  url: `${config.OHS_BACKEND_URL}/safety_experts/${userId}`,
  queryKey: 'delete-safety-expert',
  options: {
    ...options,
    method: 'DELETE',
  },
})

export const createSafetyExpert = (safetyExpert: Partial<UserProps>) => ({
  url: `${config.OHS_BACKEND_URL}/safety_experts`,
  queryKey: 'create-safety-expert',
  options: {
    ...options,
    method: 'POST',
  },
  body: { data: safetyExpert },
})

export const updateSafetyExpertImage = (
  image: File,
  userId: SafetyExpert['id']
) => {
  const formData = createFormData(image)
  return {
    url: `${config.OHS_BACKEND_URL}/safety_experts/${userId}/image`,
    queryKey: `create-safety-expert-image`,
    options: {
      ...formDataOptions,
      method: 'POST',
    },
    body: formData,
  }
}

export const createOhsOfficer = (
  customerId: Customer['id'],
  ohsOfficer: Partial<UserProps>
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/ohs_officers`,
  queryKey: 'create-ohs-officer',
  options: {
    ...options,
    method: 'POST',
  },
  body: { data: ohsOfficer },
})

export const deleteOhsOfficer = (
  customerId: Customer['id'],
  userId: CustomerUser['id']
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/ohs_officers/${userId}`,
  queryKey: 'delete-ohs-officer',
  options: {
    ...options,
    method: 'DELETE',
  },
})

export const getInstructionsEmployees = (
  customerId: Customer['id'],
  filename: string
) => {
  return fetch(
    `${config.OHS_BACKEND_URL}/customers/${customerId}/instructions_employees`,
    getBilingualOptions(Language.EN)
  ).then((response) => {
    processFileStream(response, filename)
  })
}

export const getActiveDivisionInstructions = (
  customerId: Customer['id'],
  divisionId: Division['id'],
  limit: number,
  offset: number,
  sortBy: string = 'expires_at',
  order: Order = Order.DESCENDING,
  language: Language
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/divisions/${divisionId}/instructions`,
  queryKey: `get-active-division-instructions-${language}`,
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
    active: true,
  },
  options: {
    ...getBilingualOptions(language),
    method: 'GET',
  },
  force: true,
  transform: transformActiveDivisionInstructionsResponse,
  update: {
    activeDivisionInstructions: (
      _prevData: OHSEntitiesState['activeDivisionInstructions'],
      data: AssignedDivisionInstructions
    ) => data,
  },
})

export const getExpiredDivisionInstructions = (
  customerId: Customer['id'],
  divisionId: Division['id'],
  limit: number,
  offset: number,
  sortBy: string = 'expires_at',
  order: Order = Order.DESCENDING,
  language: Language
) => ({
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/divisions/${divisionId}/instructions`,
  queryKey: `get-expired-division-instructions-${language}`,
  body: {
    limit,
    offset,
    sort_by: sortBy,
    order,
    active: false,
  },
  options: {
    ...getBilingualOptions(language),
    method: 'GET',
  },
  force: true,
  transform: transformExpiredDivisionInstructionsResponse,
  update: {
    expiredDivisionInstructions: (
      _prevData: OHSEntitiesState['expiredDivisionInstructions'],
      data: AssignedDivisionInstructions
    ) => data,
  },
})
