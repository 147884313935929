import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import 'moment/locale/de'
import config from 'services/config'

import de from './de'
import en from './en'

const whitelist = config.ENABLED_LANGUAGES

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
    },
    fallbackLng: 'de',
    initImmediate: false,
    nonExplicitWhitelist: true,
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
    },
    whitelist,
  })

export const changeToNextLanguage = (enabledLanguages = whitelist) => {
  const curLangIndex = enabledLanguages.indexOf(i18n.language)
  const nextLanguage = enabledLanguages[curLangIndex + 1] || enabledLanguages[0]
  i18n.changeLanguage(nextLanguage)
}

export default i18n
