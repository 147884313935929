import { useState } from 'react'

import { Button, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Column } from 'components/Table'
import { AssignElementsModal } from 'components/assign-elements-modal'
import { CustomerElementsTable } from 'components/customer-elements-table'
import { useTranslation } from 'react-i18next'
import {
  Customer,
  CustomerUserRole,
  Division,
  Manager,
  ManagerLocation,
} from 'services/model'
import {
  assignManagerDivision,
  assignManagerLocation,
  getManagerDivisions,
  getManagerLocations,
  getManagerUnassignedDivisions,
  getManagerUnassignedLocations,
  unassignManagerDivision,
  unassignManagerLocation,
} from 'services/queries-typed'

const useStyles = makeStyles()(() => ({
  divider: {
    marginBottom: '2rem',
  },
}))

export interface Props {
  customerId: Customer['id']
  managerId: Manager['id']
  name: string
  role: string
}

export const ManagerProfile: React.FC<Props> = ({
  customerId,
  managerId,
  name,
  role,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const isLocationManager = role === CustomerUserRole.LOCATION_MANAGER
  const elementType = isLocationManager ? 'locations' : 'divisions'

  const divisionColumns: Column[] = [
    { label: t('id'), getter: (division: Division) => division.id },
    {
      label: t('location'),
      getter: (division: Division) => division.location,
      sortKey: 'location',
    },
    {
      label: t('operational_area'),
      getter: (division: Division) => division.operational_area,
      sortKey: 'operational_area',
    },
  ]

  const locationColumns: Column[] = [
    {
      label: t('location'),
      getter: (location: ManagerLocation) => location.name,
      sortKey: 'location',
    },
    {
      label: t('total_facilities'),
      getter: (location: ManagerLocation) => location.meta.total_divisions,
      sortKey: 'total_facilities',
    },
  ]

  const handleModalClose = () => {
    setIsOpen(false)
  }
  const handleModalOpen = () => {
    setIsOpen(true)
  }

  return (
    <>
      <Divider className={classes.divider} />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h6">
                {isLocationManager
                  ? t('profile.assigned_locations')
                  : t('profile.assigned_divisions')}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                data-testid={`open-assign-${role}-modal-button`}
                onClick={handleModalOpen}
                variant="contained"
                color="secondary"
              >
                {isLocationManager ? t('locations.add') : t('facilities.add')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <CustomerElementsTable
            key={isOpen.toString()}
            customerId={customerId}
            elementId={managerId}
            name={name}
            columns={isLocationManager ? locationColumns : divisionColumns}
            elementType={elementType}
            elementSelectorKey={
              isLocationManager ? 'managerLocations' : 'managerDivisions'
            }
            // @ts-expect-error
            getElementQuery={
              isLocationManager ? getManagerLocations : getManagerDivisions
            }
            // @ts-expect-error
            onSelectElementsQuery={
              isLocationManager
                ? unassignManagerLocation
                : unassignManagerDivision
            }
            tableType="assigned"
          />
        </Grid>
      </Grid>
      <AssignElementsModal
        customerId={customerId}
        elementId={managerId}
        name={name}
        isOpen={isOpen}
        onClose={handleModalClose}
        elementType={isLocationManager ? 'locations' : 'divisions'}
        elementSelectorKey={
          isLocationManager
            ? 'managerUnassignedLocations'
            : 'managerUnassignedDivisions'
        }
        // @ts-expect-error
        getElementQuery={
          isLocationManager
            ? getManagerUnassignedLocations
            : getManagerUnassignedDivisions
        }
        // @ts-expect-error
        onSelectElementsQuery={
          isLocationManager ? assignManagerLocation : assignManagerDivision
        }
        columns={isLocationManager ? locationColumns : divisionColumns}
      />
    </>
  )
}

export default ManagerProfile
