import { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import {
  Checkbox,
  LinearProgress,
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Button,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Order, Division } from 'services/model'
import { useTranslation } from 'react-i18next'
import { sendCustomerFacilityInstructionReminderEmails } from 'services/queries-typed'
import { useMutation } from 'redux-query-react'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router'
import { CustomerRouteParams } from 'navigation/routes'
import { ConfirmationModal } from 'components/ConfirmationModal'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles()((theme) => ({
  tableCell: {
    // Set a minimum width for table cells
    // minWidth: 110, // This is just an example value; adjust as needed
    [theme.breakpoints.down('md')]: {
      fontSize: '14px', // Adjust font size as needed
      // If you want to have a different minimum width for xs screens
    },
  },

  tableContainer: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto', // Allows horizontal scrolling
    },
  },

  empty: {
    padding: '2rem',
    textAlign: 'center',
    color: theme.palette.text.disabled,
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing.length * 3,
  },
  clickable: {
    cursor: 'pointer',
  },
  table: {
    minWidth: 700,
    [theme.breakpoints.down('md')]: {
      minWidth: 550,
      tableLayout: 'auto',
      '& tr > td': {
        // padding: '1px', // Adjust as needed for your design
        // paddingLeft: '4px',
        whiteSpace: 'normal', // Allows text to wrap
        wordBreak: 'normal', // Breaks long words to prevent overflow
        maxWidth: '180px',
      },
      '& th': {
        padding: '14px 0px 14px 14px', // Adjust as needed for your design
      },
      // Styles for screens 'md' and up
    },

    // Add more breakpoints as needed
  },
}))

export interface Column {
  label: string
  // TODO: dynamic typing?
  getter: (obj: any) => any
  sortKey?: string
}

export interface Row {
  id: string | number
  [k: string]: any
}

export interface Props extends PaginatedProps, SelectableProps {
  list: Row[]
  columns: Column[]
  loading?: boolean
  onRowClick?: (record: Row) => void

  emptyCopy?: string
  tableLabel?: string
  showButton?: boolean
}

export interface PaginatedProps {
  count?: number
  initialRowsPerPage?: number
  rowsPerPageOptions?: number[]
  /**
   * Define `onPageChange` to add pagination
   */
  onPageChange?: (
    limit: number,
    offset: number,
    sortBy: string | undefined,
    order: Order | undefined
  ) => void
}

export interface SelectableProps {
  selectable?: boolean
  onSelect?: (selectedRowIds: (number | string)[]) => void
}

/**
 * Table that optionally handles pagination & selection
 * - to add pagination, implement the `PaginatedProps`
 * - to add selection, implement the `SelectableProps`
 */
export const Table: React.FC<Props> = ({
  list,
  columns,
  count = 0,
  onPageChange = null,
  onRowClick = null,
  loading = false,
  selectable = false,
  onSelect,
  rowsPerPageOptions = [25, 50, 100],
  emptyCopy,
  initialRowsPerPage = 25,
  showButton = false,
  tableLabel,
}) => {
  const { t } = useTranslation()
  const { customerId } = useParams<CustomerRouteParams>()
  const { classes } = useStyles()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)
  const [order, setOrder] = useState<Order | undefined>()
  const [sortBy, setSortBy] = useState<string | undefined>()

  const [pageSelected, setPageSelected] = useState(false)
  const [selectedRows, setSelectedRows] = useState<(number | string)[]>([])
  const [, sendReminders] = useMutation(
    sendCustomerFacilityInstructionReminderEmails
  )
  const { enqueueSnackbar } = useSnackbar()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [divisionId, setDivisionId] = useState(0)

  useEffect(() => {
    onPageChange && onPageChange(rowsPerPage, page * rowsPerPage, sortBy, order)
  }, [page, rowsPerPage, onPageChange, sortBy, order])

  const handleChangePage = useCallback(
    (
      _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      pageNumber: number
    ) => {
      setPage(pageNumber)
    },
    []
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    },
    []
  )

  const handleSort = useCallback(
    (sortKey?: string) => {
      if (sortKey) {
        return () => {
          order === Order.ASCENDING
            ? setOrder(Order.DESCENDING)
            : setOrder(Order.ASCENDING)
          setSortBy(sortKey)
          setPage(0)
        }
      }
      return () => null
    },
    [order]
  )

  const handleRowClick = useCallback(
    (record: any) => {
      return () => onRowClick && onRowClick(record)
    },
    [onRowClick]
  )

  useEffect(() => {
    if (list.length > 0) {
      const valuesPresent = list.map((element) =>
        selectedRows.includes(element.id)
      )
      if (valuesPresent.includes(false)) {
        setPageSelected(false)
      } else {
        setPageSelected(true)
      }
    } else {
      setPageSelected(false)
    }
  }, [list, selectedRows])

  const handleRowSelection = useCallback(
    (id: number | string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedRows([...selectedRows, id])
      } else {
        const updatedSelectedRows = selectedRows.filter((value) => value !== id)
        setSelectedRows(updatedSelectedRows)
      }
    },
    [selectedRows]
  )

  const handlePageSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const ids = list.map((record) => record.id)
      if (event.target.checked) {
        const onlyUnique = (
          value: string | number,
          index: number,
          self: (string | number)[]
        ) => self.indexOf(value) === index

        const updatedSelection = [...selectedRows, ...ids].filter(onlyUnique)
        setSelectedRows(updatedSelection)
      } else {
        setSelectedRows(selectedRows.filter((id) => !ids.includes(id)))
      }
    },
    [list, selectedRows]
  )

  const handleReminder = (event: any, id: number) => {
    event.stopPropagation()
    setDivisionId(id)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const theme = useTheme()

  // Define a media query that matches small screen sizes
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const onSendReminders = () => {
    setIsModalOpen(false)
    sendReminders(customerId, divisionId)
      ?.then((response) => {
        if (response.status < 400) {
          enqueueSnackbar(t('instructionScreen.reminder.successPerDivision'), {
            variant: 'success',
          })
        } else {
          enqueueSnackbar(t('instructionScreen.reminder.error'), {
            variant: 'error',
          })
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' })
      })
  }

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedRows)
    }
  }, [selectedRows, onSelect])

  if (
    emptyCopy &&
    ((onPageChange && count === 0) || (!onPageChange && list.length === 0))
  ) {
    return (
      <Paper elevation={1}>
        {loading && <LinearProgress />}
        <Typography
          variant={isSmallScreen ? 'body1' : 'h6'}
          className={classes.empty}
        >
          {emptyCopy}
        </Typography>
      </Paper>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer
          component={Paper}
          elevation={1}
          className={classes.tableContainer}
        >
          {loading && <LinearProgress />}
          <MUITable
            className={classes.table}
            data-testid={`table-${tableLabel}`}
            size="small"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              {onPageChange && (
                <TableRow>
                  <TablePagination
                    count={count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                  />
                </TableRow>
              )}
              <TableRow>
                {selectable && (
                  <TableCell padding="checkbox" component="th">
                    <Checkbox
                      data-testid={`table-select-all-checkbox-${tableLabel}`}
                      checked={pageSelected}
                      onChange={handlePageSelection}
                    />
                  </TableCell>
                )}
                {columns.map((column, i) => (
                  <TableCell key={i} align="left" className={classes.tableCell}>
                    <TableSortLabel
                      active={!!(column.sortKey && sortBy === column.sortKey)}
                      disabled={!column.sortKey}
                      direction={order}
                      onClick={handleSort(column.sortKey)}
                      data-testid={`paginated-table-sort-label-${column.sortKey}`}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {showButton && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((record: Record<string, any>) => (
                <TableRow
                  className={!!onRowClick ? classes.clickable : ''}
                  key={record.id}
                  data-testid={`paginated-table-row-record-${record.id}`}
                  hover={!!onRowClick}
                  selected={selectedRows.includes(record.id)}
                  onClick={handleRowClick(record)}
                >
                  {selectable && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.includes(record.id)}
                        onChange={handleRowSelection(record.id)}
                      />
                    </TableCell>
                  )}
                  {columns.map((column, i) => (
                    <TableCell
                      key={i}
                      align="left"
                      className={classes.tableCell}
                    >
                      {column.getter(record)}
                    </TableCell>
                  ))}
                  {showButton && record.employees_overdue > 0 && (
                    <TableCell align="center">
                      <Button
                        size="small"
                        onClick={(event) => handleReminder(event, record.id)}
                        variant="contained"
                        color="secondary"
                        data-testid="online-instructions-remind-overdue-button"
                        sx={{ margin: 1 / 50, padding: 1.2 }}
                      >
                        {t('instructionScreen.remind_overdue_employees')}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              <ConfirmationModal
                bodyCopy={t('instructionScreen.division_reminder_modal_body')}
                confirmButtonCopy={t('global.buttons.send')}
                open={isModalOpen}
                onClose={handleCloseModal}
                onConfirm={() => onSendReminders()}
              />
            </TableBody>
            {onPageChange && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </MUITable>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
