import { Button, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ComplianceHeader } from 'components/ComplianceHeader'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { colors } from 'themes'

const useStyles = makeStyles()(() => ({
  root: {
    marginBottom: '3rem',
  },
  auditTileRoot: {
    margin: '0 0 1rem 1rem',
    height: '12rem',
    minWidth: '17rem',
  },
  auditTile: {
    padding: '1rem',
  },
  auditButton: {
    color: colors.peach,
  },
  auditTileBody: {
    margin: '1rem 0',
  },
}))

export interface Props {
  onButtonClick: () => void
  flags?: Record<string, any>
}

const TileHeaders: React.FC<Props> = ({ onButtonClick, flags }) => {
  const { classes } = useStyles()
  const unauditedLocations = useSelector(
    (state) => state.entities.unauditedLocations
  )

  const showAuditTile =
    flags?.enableLocationBasedAudit &&
    unauditedLocations &&
    unauditedLocations.length > 0

  return (
    <Grid container justifyContent="space-between" className={classes.root}>
      <Grid item xs={showAuditTile ? 9 : 12}>
        <ComplianceHeader />
      </Grid>
      {showAuditTile && (
        <Grid item xs={3}>
          <InitialAuditTile onButtonClick={onButtonClick} />
        </Grid>
      )}
    </Grid>
  )
}
export default withLDConsumer()(TileHeaders)

export interface AuditTileProps {
  onButtonClick: () => void
}

export const InitialAuditTile: React.FC<AuditTileProps> = ({
  onButtonClick,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  return (
    <Paper className={classes.auditTileRoot} data-testid="initial-audit-tile">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className={classes.auditTile}
      >
        <Grid item>
          <Typography variant="h6">{t('initial_audit.title')}</Typography>
        </Grid>
        <Grid item className={classes.auditTileBody}>
          <Typography>{t('initial_audit.location_not_audited')}</Typography>
        </Grid>
        <Grid item>
          <Grid item container justifyContent="flex-end">
            <Button
              variant="text"
              onClick={onButtonClick}
              className={classes.auditButton}
              data-testid="perform-initial-audit-button"
            >
              {t('initial_audit.perform_audit')}
              <ChevronRightIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
