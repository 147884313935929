import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import theme from './theme'

export const Themed = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <StyledThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledThemeProvider>
    </StyledEngineProvider>
  )
}

export default Themed
