import CsvImport, { CsvUploadDataType } from 'containers/csv-import'
import { useTranslation } from 'react-i18next'
import { importFacilities } from 'services/queries-typed'

export const FacilitiesImportScreen: React.FC = () => {
  const { t } = useTranslation()
  return (
    <CsvImport
      title={t('facilities.title')}
      query={importFacilities}
      dataType={CsvUploadDataType.DIVISIONS}
    />
  )
}

export default FacilitiesImportScreen
