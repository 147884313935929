const styles = {
  header: {
    margin: '1rem auto 0',
  },
  message: {
    height: 'calc(100vh - 300px)',
  },
}

export default styles
