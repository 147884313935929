import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isEmail, isNonEmpty } from 'lib'
import { Measure } from 'services/model'
import theme from 'themes/theme'
import { FormTextfield } from './FormFields'
import { useEffect } from 'react'

export interface ResponsibleProps {
  name: Measure['responsible_name']
  email: Measure['responsible_email']
}

export interface Props {
  measure: Measure
  editMode: boolean
  onRemove: () => void
  onResend: () => void
  onClose: () => void
  onSaveAndSend: (attributes: FieldValues) => void
}

export const MeasureResponsiblesTable: React.FC<Props> = ({
  measure,
  editMode,
  onRemove,
  onResend,
  onClose,
  onSaveAndSend,
}) => {
  const { t } = useTranslation()
  const tf = (field: string) => t(`measure.responsibles.${field}`)
  const { responsible_name, responsible_email } = measure

  const defaultValues = {
    name: responsible_name || '',
    email: responsible_email || '',
  }

  useEffect(() => {
    reset(defaultValues)
  }, [measure])

  // FIXME: currently control requires generic FieldValues as Type. Find a way to type with ResponsibleProps
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<FieldValues>({ defaultValues })

  const handleClose = () => {
    reset(defaultValues)
    onClose()
  }

  const onFormSubmit = (attributes: FieldValues) => {
    onSaveAndSend(attributes)
    reset()
  }

  const validEmailRule = {
    validate: isEmail,
    required: t('global.isRequired', '', { label: tf('email') }) as string,
  }

  return (
    <Box>
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        noValidate
        key={measure.id}
        data-testid="measure-responsible-form"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="measure-responsibles-table">
            <TableHead sx={{ backgroundColor: theme.palette.background.info }}>
              <TableRow>
                {/* TODO: create new global class InfoLabel or new variant */}
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.text.secondary}
                  >
                    {tf('name')}
                  </Typography>
                </TableCell>
                <TableCell colSpan={3}>
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.text.secondary}
                  >
                    {tf('email')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={editMode ? { height: '100px' } : undefined}>
                <TableCell>
                  <FormTextfield
                    name={'name'}
                    control={control}
                    editMode={editMode}
                    placeholder={tf('name')}
                    rules={{ validate: isNonEmpty(tf('name')) }}
                  />
                </TableCell>
                <TableCell>
                  <FormTextfield
                    name={'email'}
                    control={control}
                    editMode={editMode}
                    placeholder={tf('email')}
                    rules={validEmailRule}
                  />
                </TableCell>
                <ResponsibleActionButtons
                  isEmpty={!responsible_email && !responsible_name}
                  editMode={editMode}
                  isDirty={isDirty}
                  onRemove={onRemove}
                  onResend={onResend}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </form>
      {editMode && (
        <Grid container item justifyContent="flex-end" sx={{ mt: 4, mb: 4 }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            className="outlined-form-btn"
            data-testid="cancel-measure-responsible-edit-btn"
          >
            {t('global.buttons.cancel')}
          </Button>
        </Grid>
      )}
    </Box>
  )
}

interface ButtonProps {
  isEmpty: boolean
  editMode: boolean
  isDirty: boolean
  onRemove: () => void
  onResend: () => void
}

const ResponsibleActionButtons = ({
  isEmpty,
  editMode,
  isDirty,
  onRemove,
  onResend,
}: ButtonProps) => {
  const { t } = useTranslation()
  const isActionableExistingResponsible = editMode && !isDirty && !isEmpty
  const isUnsavedResponsible = editMode && isDirty
  if (isActionableExistingResponsible) {
    return (
      <>
        <TableCell align="right">
          <Button
            variant="text"
            onClick={onRemove}
            data-testid="remove-measure-responsible-btn"
          >
            {t('global.buttons.remove')}
          </Button>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="text"
            onClick={onResend}
            data-testid="send-measure-responsible-btn"
          >
            {t('global.buttons.resend')}
          </Button>
        </TableCell>
      </>
    )
  } else if (isUnsavedResponsible) {
    return (
      <>
        <TableCell />
        <TableCell align="right">
          <Button
            variant="text"
            type="submit"
            key="preventDoubleSubmit"
            disabled={!isDirty}
            data-testid="save-and-send-measure-responsible-btn"
          >
            {t('global.buttons.send')}
          </Button>
        </TableCell>
      </>
    )
  } else {
    // No edit mode or empty input
    return <TableCell colSpan={2} />
  }
}
