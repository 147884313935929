import { DatePicker } from '@mui/x-date-pickers'
import {
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { parseISO } from 'date-fns'
import { isValidDate } from 'lib'
import { Control, Controller, RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import theme from 'themes/theme'
import { MeasureInfoProps } from './MeasureInfoForm'
import { ResponsibleProps } from './MeasureResponsiblesTable'
import { MeasureComment, UserProps } from 'services/model'

interface FormProps {
  name:
    | keyof MeasureInfoProps
    | keyof ResponsibleProps
    | keyof UserProps
    | keyof MeasureComment
  control: Control
  editMode: boolean
  label?: string
  rules?: RegisterOptions
}

interface FormTextFieldProps extends FormProps {
  multiline?: boolean
  placeholder?: string
  rows?: number
}

export const FormTextfield = ({
  control,
  editMode,
  name,
  label,
  rules,
  multiline = false,
  placeholder,
  rows = 1,
}: FormTextFieldProps) => {
  return (
    <>
      {label && (
        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
          {label}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          //@ts-ignore see: https://github.com/mui/material-ui/issues/15697
          <TextField
            variant={editMode ? 'outlined' : 'standard'}
            size="small"
            {...field}
            error={!!error}
            helperText={error?.message}
            fullWidth
            disabled={!editMode}
            multiline={multiline}
            rows={rows}
            InputProps={{ disableUnderline: true }}
            placeholder={editMode ? placeholder : undefined}
            FormHelperTextProps={{ className: 'InlineTableFormHelper' }}
          />
        )}
      />
    </>
  )
}

export interface SelectItemProps {
  key: string | number
  label: string
}

interface FormSelectFieldProps extends FormProps {
  items: SelectItemProps[]
}

export const FormSelectField = ({
  control,
  editMode,
  items,
  name,
  label,
  rules,
}: FormSelectFieldProps) => {
  return (
    <>
      <Typography variant="subtitle2" color={theme.palette.text.secondary}>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select
              id={name}
              {...field}
              size="small"
              fullWidth
              disabled={!editMode}
              variant={editMode ? 'outlined' : 'standard'}
              disableUnderline={true}
              error={!!error}
            >
              {items?.map((item) => {
                return (
                  <MenuItem key={item.key} value={item.key}>
                    {item.label}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText
              sx={{
                color: theme.palette.error.main,
                position: 'relative',
                left: '14px',
              }}
            >
              {error?.message}
            </FormHelperText>
          </>
        )}
      />
    </>
  )
}

export const FormDatePicker = ({
  control,
  editMode,
  name,
  label,
  rules,
}: FormProps) => {
  const { t } = useTranslation()

  const isDateRule: RegisterOptions = {
    validate: (val: Date | string) => {
      let date
      if (typeof val === 'string') {
        date = parseISO(val)
      } else {
        date = val
      }
      if (!isValidDate(date)) {
        return t('global.invalidDate') as string
      }
      return true
    },
  }

  return (
    <>
      <Typography variant="subtitle2" color={theme.palette.text.secondary}>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        rules={{ ...rules, ...isDateRule }}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            {...field}
            //@ts-expect-error
            allowSameDateSelection
            disabled={!editMode}
            inputFormat="yyyy-MM-dd"
            mask="____-__-__"
            openTo="year"
            views={['year', 'month', 'day']}
            renderInput={
              editMode
                ? (params: any) => (
                    <TextField
                      size="small"
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      fullWidth
                    />
                  )
                : (params: any) => (
                    //@ts-ignore see: https://github.com/mui/material-ui/issues/15697
                    <TextField
                      size="small"
                      variant="standard"
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      fullWidth
                      // Removes calendar icon
                      InputProps={{ disableUnderline: true }}
                    />
                  )
            }
          />
        )}
      />
    </>
  )
}
