import { Select as MaterialSelect } from '@mui/material'
import styled from 'styled-components'

export const Select = styled(MaterialSelect)`
  height: 3rem;
  border: solid 1px black;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.palette.background.paper};
`
