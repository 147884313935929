import { Theme, createTheme, adaptV4Theme } from '@mui/material/styles'
import colors from './colors'

import fonts from './fonts'
import { red } from '@mui/material/colors'

declare module '@mui/material/styles' {
  interface DefaultTheme extends Theme {}
}

// A custom theme for this app
const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: colors.charcoal,
        light: colors.linen,
      },
      secondary: {
        main: colors.charcoal,
      },
      error: {
        main: red.A400,
        light: 'rgb(255, 231, 236)',
        dark: 'rgb(102, 9, 27)',
      },
      warning: {
        main: colors.peach,
        dark: 'rgb(94, 60, 47)',
        light: 'rgb(253, 244, 241)',
      },
      background: {
        default: colors.mist,
        banner: colors.sky,
        paper: colors.white,
        danger: colors.cherry,
        info: colors.smoke,
      },
      text: {
        primary: colors.charcoal,
        secondary: colors.dimGray,
        contrast: colors.linen,
        unselected: colors.silver,
      },
      info: {
        main: 'rgb(33, 148, 243)',
        dark: 'rgb(13, 60, 97)',
        light: 'rgb(232, 244, 253)',
      },
      success: {
        main: colors.green,
        dark: 'rgb(14, 79, 60)',
        light: 'rgb(233, 249, 244)',
      },
    },
    // FIXME check if typography method is really needed
    //@ts-ignore
    typography(palette) {
      const fontFamily = fonts.emphasis
      const fontSize = 8
      const htmlFontSize = 16
      const fontWeightLight = 300
      const fontWeightRegular = 400
      const fontWeightMedium = 500
      const fontWeightSemiBold = 600
      const fontWeightBold = 700

      const coef = fontSize / 14
      const pxToRem = (size: number) => `${(size / htmlFontSize) * coef}rem`
      const buildVariant = (
        fontFamily: string,
        fontWeight: number,
        size: number,
        lineHeight: number
      ) => ({
        fontFamily,
        fontWeight,
        fontSize: pxToRem(size),
        lineHeight: pxToRem(lineHeight),
        textTransform: 'none',
        color: palette.text.primary,
        wordBreak: 'break-word',
      })

      const variants = {
        h1: buildVariant(fonts.regular, fontWeightMedium, 50, 59),
        h2: buildVariant(fonts.regular, fontWeightMedium, 40, 49),
        h3: buildVariant(fontFamily, fontWeightLight, 40, 49),
        h4: buildVariant(fontFamily, fontWeightLight, 30, 49),
        h5: buildVariant(fonts.regular, fontWeightMedium, 35, 49),
        h6: buildVariant(fonts.bold, fontWeightLight, 30, 49),
        body1: buildVariant(fontFamily, fontWeightRegular, 24, 28),
        body2: buildVariant(fonts.regular, fontWeightMedium, 24, 49),
        button: buildVariant(fonts.bold, fontWeightBold, 24, 28),
      }

      return {
        fontFamily,
        fontWeightLight,
        fontWeightRegular,
        fontWeightMedium,
        fontWeightSemiBold,
        fontWeightBold,
        ...variants,
      }
    },
    overrides: {
      MuiAlert: {
        standardError: {
          backgroundColor: 'rgb(255, 231, 236)',
          color: 'rgb(102, 9, 27)',
        },
        standardWarning: {
          backgroundColor: 'rgb(253, 244, 241)',
          color: 'rgb(94, 60, 47)',
        },
        standardSuccess: {
          backgroundColor: 'rgb(233, 249, 244)',
          color: 'rgb(14, 79, 60)',
        },
        icon: {
          color: 'inherit !important',
        },
      },
      MuiDialogActions: {
        root: {
          backgroundColor: colors.smoke,
        },
      },
      MuiButton: {
        root: {
          borderRadius: '1rem',
          '&.outlined-form-btn': {
            borderRadius: '0.2rem',
            textTransform: 'uppercase',
            letterSpacing: '1.5px',
            padding: '0.7rem',
          },
          //FIXME: Should become new outlined default styles
          '&.outlined-form-btn-round': {
            borderRadius: '1.5rem',
            textTransform: 'uppercase',
            letterSpacing: '1.5px',
            padding: '0.7rem 1rem',
          },
        },
        text: {
          borderRadius: '0.2rem',
        },
        textSecondary: {
          borderRadius: '0.2rem',
          textTransform: 'uppercase',
          letterSpacing: '1.5px',
          padding: '0.7rem',
        },
        containedSecondary: {
          borderRadius: '0.2rem',
          textTransform: 'uppercase',
          letterSpacing: '1.5px',
          padding: '0.7rem',
          margin: '2rem 0',
        },
      },
      MuiTableCell: {
        head: {
          fontWeight: 700,
        },
        root: {
          padding: 12,
        },
      },
      MuiTypography: {
        h1: {
          margin: '2rem 0',
        },
        h2: {
          marginBottom: '1rem',
        },
        body2: {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
        },
        caption: {
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '14px',
        },
        subtitle1: {
          margin: '1rem 0',
          '&.adminStyleGuide': {
            textTransform: 'uppercase',
            margin: 0,
            fontWeight: 700,
            fontSize: '12px',
            fontFamily: fonts.bold,
            lineHeight: '16px',
            letterSpacing: '0.5px',
          },
        },
        subtitle2: {
          color: colors.silver,
        },
        // FIXME check for real class name
        //@ts-ignore
        colorError: {
          paddingTop: '0.5rem',
          color: colors.cherry,
        },
        root: {
          '&.DocumentCardTitle': {
            fontSize: '0.7rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: 'inherit',
            height: '2rem',
            whiteSpace: 'normal',
          },
        },
      },
      MuiDropzoneArea: {
        textContainer: {
          margin: '0.5rem 0',
          padding: '0.5rem',
        },
        text: {
          marginTop: '2rem',
          marginBottom: '2rem',
        },
      },
      MuiInput: {
        input: {
          '&.Mui-disabled': {
            textFillColor: colors.charcoal,
          },
        },
      },
      MuiFormHelperText: {
        root: {
          '&.InlineTableFormHelper': {
            position: 'absolute',
            top: '100%',
          },
        },
      },
      MuiButtonBase: {
        root: {
          '&.FileCardDeleteIcon': {
            position: 'relative',
            top: '0rem',
            left: '0rem',
            zIndex: 1,
          },
          '&.fileTileDownloadButton:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  })
)

export default theme
