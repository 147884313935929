import { Box, Button, Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { theme } from 'themes'

const acceptedMimeTypes = [
  'text/csv',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/zip',
  'application/gzip',
  'image/jpeg',
  'image/png',
  'image/bmp',
]

const acceptedMimeTypesOnlyDocs = [
  'text/csv',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/zip',
  'application/gzip',
]

const acceptedMimeTypesOnlyImages = ['image/jpeg', 'image/png', 'image/bmp']

const acceptedDocFormats = [
  'csv',
  'pdf',
  'docx',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
  'zip',
]
const acceptedImageFormats = ['jpeg', 'jpg', 'png', 'bmp']

export enum FileUploadType {
  IMAGE = 'Image',
  DOC = 'Doc',
}

interface DropzoneProps {
  onFileDrop: (files: any[]) => void
  acceptFormatOnly?: FileUploadType.IMAGE | FileUploadType.DOC
  acceptMultiple?: boolean
}

const FileDropzone = ({
  onFileDrop,
  acceptFormatOnly,
  acceptMultiple = true,
}: DropzoneProps) => {
  const { t } = useTranslation()

  const getAcceptedMimeTypes = () => {
    switch (acceptFormatOnly) {
      case FileUploadType.IMAGE:
        return acceptedMimeTypesOnlyImages
      case FileUploadType.DOC:
        return acceptedMimeTypesOnlyDocs
      default:
        return acceptedMimeTypes
    }
  }

  const {
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isFileDialogActive,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      const droppedFiles = acceptedFiles.map((file) => {
        // process for image file preview
        if (file.type.startsWith('image/')) {
          return Object.assign(file, {
            url: URL.createObjectURL(file),
          })
        } else {
          return file
        }
      })
      onFileDrop(droppedFiles)
    },
    noClick: true,
    accept: getAcceptedMimeTypes(),
    multiple: acceptMultiple,
  })

  const acceptedFormats = acceptFormatOnly
    ? acceptFormatOnly === FileUploadType.IMAGE
      ? [acceptedImageFormats]
      : [acceptedDocFormats]
    : [acceptedImageFormats, acceptedDocFormats]

  return (
    <div
      {...getRootProps({
        style: { ...(isDragAccept ? { opacity: 0.7 } : {}) },
      })}
    >
      <input {...getInputProps()} />
      <Box
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ p: 4, border: `2px dashed ${theme.palette.warning.main}` }}
      >
        <Trans i18nKey="fileDropzone.dragAndDrop">
          <Typography variant="h5" sx={{ mb: 2 }}>
            Drag and drop
          </Typography>
          <Typography sx={{ m: 2 }}>or</Typography>
          <Button
            variant="outlined"
            onClick={isFileDialogActive ? undefined : open}
            sx={{ m: 2 }}
            className="outlined-form-btn-round"
            data-testid={`dropzone-choose-file-btn`}
          >
            choose file
          </Button>
        </Trans>
        <Typography>{t('fileDropzone.acceptedFormats')}</Typography>
        <Grid container justifyContent="center">
          {acceptedFormats.map((acceptedFormat, i) => (
            <Grid item textAlign="left" key={acceptedFormat[i]}>
              <ul>
                {acceptedFormat.map((format) => (
                  <li key={format}>{format}</li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  )
}

export default FileDropzone
