import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

interface Props extends RouteProps {
  flags: Record<string, any>
}

const VerifiedEmailGuard: React.FC<Props> = ({ flags, ...rest }) => {
  const isVerified = useSelector((state) => state.profile.emailVerified)
  const location = useLocation()

  return isVerified || !flags.emailVerification ? (
    <Route {...rest} />
  ) : (
    <Redirect
      to={{ pathname: '/email-verification', search: location.search }}
    />
  )
}

export default withLDConsumer()(VerifiedEmailGuard)
