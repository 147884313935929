import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import UnauthAppBar from 'components/UnauthAppBar'
import BotfrontChat from 'components/botfront-chat'
import Callout from 'components/callout'
import Styles from 'containers/audit-chat-screen/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import config from 'services/config'

import ChatbotBanner from './chatbot-banner'
import Footer from 'components/footer'

export enum AuditTypes {
  HORA = 'hora',
  COVID = 'covid',
  SBK = 'sbk',
}

interface Props {
  type: AuditTypes
  displayAppBar?: boolean
}

export const UnauthChatbot: React.FC<Props> = ({ type, displayAppBar }) => {
  const { t } = useTranslation()

  const [sessionKey, setSessionKey] = useState<number>(Date.now())
  const language = useSelector((state) => state.settings.language)

  useEffect(() => {
    // this clears the webchat from saved conversations, ensuring we don't show the initial audit
    // or another conversation that happened on this domain.
    localStorage.removeItem('chat_session')
    setSessionKey(Date.now())
    return () => localStorage.removeItem('chat_session')
  }, [language])

  const getBotfrontComponent = () => {
    switch (type) {
      case AuditTypes.COVID:
        return (
          <BotfrontChat
            key={sessionKey}
            intent="get_started"
            socketUrl={config.COVID_WEBCHAT_URL}
          />
        )
      case AuditTypes.HORA:
        return (
          <BotfrontChat
            key={sessionKey}
            intent="get_started"
            language={language}
            socketUrl={config.HORA_WEBCHAT_URL}
          />
        )
      default:
        return <BotfrontChat key={sessionKey} intent="start_special_form" />
    }
  }

  return (
    <>
      {displayAppBar && <UnauthAppBar />}
      <ChatbotBanner
        type={type}
        title={t(`${type}_chatbot_welcome`)}
        body={t(`${type}_chatbot_description`)}
      />
      <Grid container style={Styles.header}>
        <Grid item xs={2} />
        <Grid item xs={8} style={Styles.message}>
          {getBotfrontComponent()}
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <Callout primaryOnly />
      <Footer
        style={{
          marginTop: 'calc(10% + 60px)',
          width: '100%',
          position: 'fixed',
          bottom: 0,
        }}
      />
    </>
  )
}

export default UnauthChatbot
