import { Typography } from '@mui/material'
import { Column } from 'components/Table'
import { List } from 'containers/list'
import routes from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { OHSAdmin } from 'services/model'
import { getOhsAdmins } from 'services/queries-typed'

export const AdminList: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const profile = useSelector((state) => state.profile)
  const { permissions, userId } = profile

  const columns: Column[] = [
    {
      label: t('admins.admin'),
      getter: (admin: OHSAdmin) => (
        <>
          {admin.last_name && (
            <Typography variant="body1">{`${admin.last_name}, ${admin.first_name}`}</Typography>
          )}
          <Typography variant="body1" color="textSecondary">
            {admin.email}
          </Typography>
          {!admin.last_name && !admin.email && (
            <Typography variant="body1">{`${admin.id}`}</Typography>
          )}
        </>
      ),
    },
  ]

  const handleGoToAdminProfile = (admin: { id: string }) => {
    history.push(routes.adminProfile(admin.id))
  }

  return (
    <List
      key={userId}
      columns={columns}
      query={getOhsAdmins}
      record="admins"
      onRowClick={
        permissions.includes('weissbier:read:admin')
          ? handleGoToAdminProfile
          : undefined
      }
    />
  )
}

export default AdminList
