import { format } from 'date-fns'
import i18n from 'i18n'
import { t } from 'i18next'
import moment, { Moment } from 'moment'
import isEmailValidator from 'validator/lib/isEmail'

export const getLanguageCode = () => i18n.language.split('-')[0]

export function displayFormattedDate(date?: string | Moment, forInput = false) {
  if (forInput) return moment(date).format('YYYY-MM-DD')
  moment.locale(i18n.language)
  return moment(date).format('L')
}

export function displayFormattedDateTime(dateTime: string | number) {
  moment.locale(i18n.language)
  return moment(dateTime).format('LLLL')
}

export function formatMeasureCommentDateTime(datetime: Date) {
  moment.locale(i18n.language)
  return moment(datetime).format('L, h:mmA')
}

export const downloadFileFromText = (
  filename: string,
  text: string,
  contentType: string
) => {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    `data:${contentType},` + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export const processFileStream = async (response: Response, filename: string) => {
  const reader = response.body?.getReader();
  const stream = new ReadableStream({
    async start(controller) {
      while (true) {
        const { done, value } = await reader?.read() || {};
        if (done) break;
        controller.enqueue(value);
      }
      controller.close();
    }
  });

  const blob = await new Response(stream).blob();
  downloadFileFromBlob(filename + ' ' + moment().format('DD-MM-YYYY'), blob, response.headers.get('content-type') || '');
}

const downloadFileFromBlob = (filename: string, blob: Blob, contentType: string) => {
  const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }));
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

// Form validators

export const isNonEmpty = (label: string) => (val: string | Date | null) => {
  if (typeof val === 'string' && val.trimStart()) {
    return true
  }
  if (typeof val !== 'string' && val !== undefined && val !== null) {
    return true
  }
  return t('global.isRequired', '', { label }) as string
}

export const isValidDate = (date: Date) => {
  try {
    format(date, 'yyyy-MM-dd')
    return true
  } catch {
    return false
  }
}

export const isEmail = (val?: string) => {
  if (val && !isEmailValidator(val)) {
    return t('global.invalidEmail')
  } else {
    return true
  }
}

export enum ConsentAnswer {
  AGREE = 'agree',
  DISAGREE = 'disagree',
}

export const setTrackConsentGranted = () => {
  localStorage.setItem('tracking_consent', ConsentAnswer.AGREE)
  localStorage.setItem('tracking_consent_answer_date', new Date().toISOString())
  window.dataLayer.push({
    event: 'custom_click',
    category: 'Button',
    action: 'Click',
    label: 'Consent Accepted',
  })
}

export const setTrackConsentWithdrawn = () => {
  localStorage.setItem('tracking_consent', ConsentAnswer.DISAGREE)
  localStorage.setItem('tracking_consent_answer_date', new Date().toISOString())
  window.dataLayer.push({
    event: 'custom_click',
    category: 'Button',
    action: 'Click',
    label: 'Consent Deny',
  })
}
