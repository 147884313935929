import { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import { Select } from 'components/SharedComponents'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMutation } from 'redux-query-react'
import { getMeasureFilters } from 'services/queries-typed'
import styled from 'styled-components'
import { SelectChangeEvent } from '@mui/material'

// Styles

const StyledFormControl = styled(FormControl)`
  width: 100%;
  padding-right: 1rem;
`

const StyledInputLabel = styled(InputLabel)`
  padding-left: 0.5rem;
`

const StyledButton = styled(Button)`
  border-radius: 0.3rem;
  border-color: ${(props) => props.theme.palette.primary.main};
  padding: 1rem;
  width: 10rem;
`

interface Option {
  label: string
  value: string
}
export interface FilterProps {
  label: string
  options: Option[]
  selectedOptions: string[]
  onChange: (options: string[]) => void
}

export const Filter: React.FC<FilterProps> = ({
  label,
  options = [],
  selectedOptions = [],
  onChange,
}) => {
  const { t } = useTranslation()

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChange(event.target.value as string[])
  }

  return (
    <Grid item xs key={label}>
      <StyledFormControl>
        <StyledInputLabel shrink>{label}</StyledInputLabel>
        <Select
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<Input />}
          renderValue={() =>
            `${t('filter.selected')} (${selectedOptions.length})`
          }
          disableUnderline={true}
          displayEmpty
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox
                checked={selectedOptions.some(
                  (selectedOption) => option.value === selectedOption
                )}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </Grid>
  )
}

export interface MeasureFilterBarProps {
  onFilter: (params: FilterParams) => void
  customerId: string
}

type FilterOptions = Record<
  'locations' | 'operational_areas' | 'statuses',
  Option[]
>
export interface FilterBarProps {
  onFilter: (params: FilterParams) => void
  filterOptions: FilterOptions
}

export interface FilterParams {
  locations?: string[]
  operational_areas?: string[]
  statuses?: string[]
}

export const FilterBar: React.FC<FilterBarProps> = ({
  onFilter,
  filterOptions,
}) => {
  const { t } = useTranslation()

  const [selectedLocations, setLocations] = useState<string[]>([])
  const [selectedStatuses, setStatuses] = useState<string[]>([])
  const [selectedOperationalAreas, setOperationalAreas] = useState<string[]>([])

  useEffect(() => {
    setLocations([])
    setStatuses([])
    setOperationalAreas([])
  }, [filterOptions])

  const handleFilter = () => {
    const filters: FilterParams = {}

    if (selectedLocations.length) {
      filters.locations = selectedLocations
    }

    if (selectedStatuses.length) {
      filters.statuses = selectedStatuses
    }

    if (selectedOperationalAreas.length) {
      filters.operational_areas = selectedOperationalAreas
    }

    onFilter(filters)
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-end">
      <Filter
        label={t('filter.location')}
        options={filterOptions.locations}
        selectedOptions={selectedLocations}
        onChange={setLocations}
      />
      <Filter
        label={t('filter.operational_area')}
        options={filterOptions.operational_areas}
        selectedOptions={selectedOperationalAreas}
        onChange={setOperationalAreas}
      />
      <Filter
        label={t('filter.status.title')}
        options={filterOptions.statuses}
        selectedOptions={selectedStatuses}
        onChange={setStatuses}
      />
      <Grid item xs>
        <StyledButton
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleFilter}
        >
          {t('filter.title')}
        </StyledButton>
      </Grid>
    </Grid>
  )
}

export const MeasureFilterBar: React.FC<MeasureFilterBarProps> = ({
  onFilter,
  customerId,
}) => {
  const { t } = useTranslation()
  const measureFilters = useSelector((state) => state.entities.measureFilters)
  const [, getFilters] = useMutation(getMeasureFilters)

  useEffect(() => {
    getFilters(customerId)
  }, [customerId, getFilters])

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    locations: [],
    operational_areas: [],
    statuses: [],
  })

  useEffect(() => {
    if (measureFilters) {
      const locations = measureFilters.locations.map((location) => ({
        label: location,
        value: location,
      }))

      const operational_areas = measureFilters.operational_areas.map((op) => ({
        label: op,
        value: op,
      }))

      const statuses = measureFilters.statuses.map((status) => ({
        label: t(`filter.status.${status}`),
        value: status,
      }))
      setFilterOptions({ locations, operational_areas, statuses })
    }
  }, [measureFilters, t])

  return <FilterBar onFilter={onFilter} filterOptions={filterOptions} />
}

export default MeasureFilterBar
