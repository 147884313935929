import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { colors } from 'themes'

const styles = {
  icon: {
    paddingLeft: '0.5rem',
  },
  button: {
    fontSize: '1rem',
    padding: '1rem',
    whiteSpace: 'nowrap',
    fontWeight: '600',
    color: 'inherit',
  },
  container: {
    color: colors.silver,
  },
  onHover: {
    color: colors.linen,
  },
}

const LogoutButton = () => {
  const { logout } = useAuth0()
  const [hasHover, setHasHover] = useState(false)

  const handleLogout = () => {
    localStorage.clear()
    logout({ returnTo: window.location.origin })
  }

  return (
    <Typography
      align="right"
      style={hasHover ? styles.onHover : styles.container}
    >
      <Button
        style={styles.button}
        onMouseEnter={() => setHasHover(true)}
        onMouseLeave={() => setHasHover(false)}
        onClick={handleLogout}
      >
        Logout
        <ExitToAppIcon style={styles.icon} />
      </Button>
    </Typography>
  )
}

export default LogoutButton
