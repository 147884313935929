import { useCallback } from 'react'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { goForward } from 'redux/onboarding/actions'
import { close } from 'redux/onboarding/actions'
import { colors, fonts, theme } from 'themes'
import { onboardingModalBanner } from 'themes/images'

const useStyles = makeStyles()((theme) => ({
  root: {
    textAlign: 'center',
  },
  title: {
    fontFamily: fonts.base,
    margin: '1.5rem 0 1rem',
  },
  button: {
    backgroundColor: theme.palette.warning.main,
    borderRadius: '20px',
    padding: '0.5rem 2rem',
    border: 'none',
    margin: '2rem auto 1rem',
  },
  icon: {
    paddingLeft: '0.5rem',
  },
  content: {
    margin: '0 2rem',
  },
}))

export const OnboardingModal = ({ showTutorial }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const currentStep = useSelector((state) => state.onboarding?.currentStep)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(close())
  }

  const handleStartOnboarding = useCallback(
    () => dispatch(goForward()),
    [dispatch]
  )
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={currentStep === 'start' && showTutorial}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <Card
        elevation={0}
        className={classes.root}
        data-testid="onboarding-modal-content"
      >
        <CardMedia
          component="img"
          alt="Tutorial Icons"
          height="200"
          image={onboardingModalBanner}
        />
        <CardContent className={classes.content}>
          <Typography
            variant="h3"
            component="p"
            className={classes.title}
            align="left"
          >
            {t('onboarding_modal_welcome')}
          </Typography>
          <Typography variant="body1" component="p" align="left">
            {t('onboarding_modal_description')}
          </Typography>
          <Button
            className={classes.button}
            align="center"
            onClick={handleStartOnboarding}
          >
            {t('onboarding_modal_start')}
            <ArrowRightAltIcon className={classes.icon} />
          </Button>
        </CardContent>
      </Card>
    </Dialog>
  )
}

export default OnboardingModal
