import { Paper, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()(() => ({
  root: {
    padding: '5rem',
    textAlign: 'center',
  },
}))

export const UserNotFoundPage: React.FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <Paper
      elevation={0}
      className={classes.root}
      data-testid="user-not-found-page-content"
    >
      <Typography variant="h2">{t('user_not_found')}</Typography>
    </Paper>
  )
}
