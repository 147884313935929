import { useCallback, useState } from 'react'

import { Button } from '@mui/material'
import { Column, Table } from 'components/Table'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Entities } from 'redux-query'
import { QueryConfigFactory, useMutation } from 'redux-query-react'
import { Customer, Order } from 'services/model'

interface CustomerElementsTableProps {
  customerId: Customer['id']
  elementId: string
  name: string
  /**
   * `assigned`: list of assigned elements.
   *
   * `unassigned`: list of unassigned elements
   */
  tableType: 'assigned' | 'unassigned'
  onSelect?: (selectedRowIds: (number | string)[]) => void
  columns: Column[]
  elementType: 'divisions' | 'locations' | 'instructionDivisions'
  elementSelectorKey:
    | 'managerDivisions'
    | 'managerUnassignedDivisions'
    | 'managerLocations'
    | 'managerUnassignedLocations'
    | 'instructionDivisions'
    | 'instructionUnassignedDivisions'
  getElementQuery: QueryConfigFactory<
    Entities,
    [
      customerId: string,
      elementId: string,
      limit?: number,
      offset?: number,
      sortBy?: string,
      order?: Order
    ]
  >
  onSelectElementsQuery: QueryConfigFactory<
    Entities,
    [customerId: string, elementId: string, selectedRows: string[]]
  >
}

export const CustomerElementsTable: React.FC<CustomerElementsTableProps> = ({
  customerId,
  elementId,
  name,
  columns,
  getElementQuery,
  onSelectElementsQuery,
  elementType,
  elementSelectorKey,
  tableType,
  onSelect,
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [tableKey, setTableKey] = useState<string>(
    `${tableType}-${elementType}`
  )

  const elements = useSelector((state) => state.entities[elementSelectorKey])

  const [{ isPending }, fetchElements] = useMutation(getElementQuery)
  const [{ isPending: isUnassigning }, onSelectElements] = useMutation(
    onSelectElementsQuery
  )

  const handleGetElements = useCallback(
    (
      limit: number,
      offset: number,
      sortBy: string | undefined,
      order: Order | undefined
    ) => {
      if (customerId && elementId) {
        fetchElements(customerId, elementId, limit, offset, sortBy, order)
      }
    },
    [fetchElements, customerId, elementId]
  )

  const resetTable = () => {
    fetchElements(customerId, elementId)
    setTableKey(Math.random().toString(36))
  }

  const handleSelect = (selectedRowIds: (number | string)[]) => {
    if (onSelect) {
      onSelect(selectedRowIds)
    } else {
      setSelectedRows(selectedRowIds)
    }
  }

  const handleUnassign = () => {
    if (selectedRows.length > 0) {
      onSelectElements(customerId, elementId, selectedRows)
        ?.then((response) => {
          if (response.status === 204) {
            enqueueSnackbar(
              t(`profile.remove_${elementType}.success`, {
                count: selectedRows.length,
              }),
              {
                variant: 'success',
              }
            )
          } else {
            enqueueSnackbar(t(`profile.remove_${elementType}.error`), {
              variant: 'error',
            })
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: 'error',
          })
        })
        .finally(() => resetTable())
    }
  }

  return (
    <>
      <Table
        key={tableKey}
        onPageChange={handleGetElements}
        count={elements?.meta.total_items || 0}
        columns={columns}
        list={elements?.list || []}
        loading={isPending || isUnassigning}
        emptyCopy={t(`profile.empty_${tableType}_${elementType}`, { name })}
        selectable
        onSelect={handleSelect}
        rowsPerPageOptions={
          tableType === 'assigned' ? [5, 25, 50, 100] : [5, 25]
        }
        initialRowsPerPage={5}
        tableLabel={`${tableType}-${elementType}`}
      />
      {tableType === 'assigned' && (
        <Button
          data-testid={`unassign-${elementType}-button`}
          disabled={selectedRows.length === 0}
          onClick={handleUnassign}
          variant="contained"
          color="secondary"
        >
          {`${t(`profile.unassign_${elementType}`, {
            count: selectedRows.length,
            context: `${selectedRows.length}`,
          })}`}
        </Button>
      )}
    </>
  )
}
