import { Backdrop, CircularProgress } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

export interface Props {
  isLoading: boolean
}

const useStyles = makeStyles()(() => ({
  backdrop: {
    position: 'absolute',
    zIndex: 1,
  },
}))

export const LoadingBackdrop: React.FC<Props> = ({ isLoading }) => {
  const { classes } = useStyles()

  return (
    <Backdrop open={isLoading} className={classes.backdrop}>
      <CircularProgress />
    </Backdrop>
  )
}

export default LoadingBackdrop
