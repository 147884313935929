import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import BotfrontChat from 'components/botfront-chat'
import routes, { CustomerRouteParams } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { PageHeader } from './page-headers'

const useStyles = makeStyles()(() => ({
  chat: { height: 'calc(100vh - 330px)' },
}))

export const LocationAudit = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { customerId } = useParams<CustomerRouteParams>()
  const userId = useSelector((state) => state.profile.userId)
  const [sessionKey, setSessionKey] = useState<number>(Date.now())

  useEffect(() => {
    localStorage.removeItem('chat_session')
    setSessionKey(Date.now())
    return () => localStorage.removeItem('chat_session')
  }, [customerId])

  const initialPayloadParams = {
    user_id: userId,
    customer_id: customerId,
  }

  return (
    <PageHeader
      title={t('onboarding_menu_audit')}
      breadcrumbs={[
        {
          name: t('sidebar_link_dashboard'),
          path: routes.dashboard(customerId),
        },
      ]}
    >
      <Grid item className={classes.chat}>
        <BotfrontChat
          key={sessionKey}
          intent="location_initial_audit"
          payload={initialPayloadParams}
        />
      </Grid>
    </PageHeader>
  )
}
