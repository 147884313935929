import { useCallback, useState } from 'react'

import Dropzone from 'components/Dropzone'
import { PageHeader } from 'containers/page-headers'
import { DocumentRouteParams } from 'navigation/routes'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { useMutation } from 'redux-query-react'
import { getS3PresignedPost, uploadDocument } from 'services/queries-typed'

import { useBreadcrumbs } from './utils'

export const DocumentLibraryUpload: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { customerId, folderPath } = useParams<DocumentRouteParams>()
  const [files, setFiles] = useState<File[]>([])

  const [{ isPending }, getPostUrl] = useMutation(getS3PresignedPost)
  const [{ isPending: isUploading }, createFile] = useMutation(uploadDocument)

  const breadcrumbs = useBreadcrumbs(customerId, folderPath + 'upload/')

  const onUploadChange = useCallback(
    (files: File[]) => {
      setFiles(files)
    },
    [setFiles]
  )

  const cancelUpload = useCallback(() => {
    setFiles([])
  }, [setFiles])

  const handleUpload = useCallback(() => {
    const calls: any[] = []
    files.forEach((file) => {
      const key = decodeURIComponent(folderPath) + file.name
      const fileUpload = new Promise((resolve, reject) => {
        getPostUrl(key)?.then((response) => {
          if (response.status === 200) {
            const preSignedUrl = response.body.data.url
            const presignedFields = response.body.data.fields
            createFile(file, preSignedUrl, presignedFields)?.then(
              (response) => {
                if (response.status === 204) {
                  enqueueSnackbar(
                    t('documents.upload.success', { fileName: file.name }),
                    {
                      variant: 'success',
                    }
                  )
                } else {
                  enqueueSnackbar(
                    t('documents.upload.error', { fileName: file.name }),
                    {
                      variant: 'error',
                    }
                  )
                }
                resolve(key)
              }
            )
          } else {
            enqueueSnackbar(t('documents.upload.error'), {
              variant: 'error',
            })
          }
        })
      })
      calls.push(fileUpload)
    })
    Promise.all(calls).then(() =>
      history.push(breadcrumbs[breadcrumbs.length - 1].path)
    )
  }, [
    customerId,
    folderPath,
    files,
    breadcrumbs,
    createFile,
    enqueueSnackbar,
    getPostUrl,
    history,
    t,
  ])

  return (
    <PageHeader
      title={t('documents.upload_documents')}
      breadcrumbs={breadcrumbs}
    >
      <Dropzone
        uploadType="file"
        filesLimit={1}
        handleUpload={handleUpload}
        onUploadChange={onUploadChange}
        cancelUpload={cancelUpload}
        files={files}
        isLoading={isPending || isUploading}
      />
    </PageHeader>
  )
}
