import { Column } from 'components/Table'
import List from 'containers/list'
import { PageHeader } from 'containers/page-headers'
import routes, { CustomerRouteParams } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useParams } from 'react-router'
import { Instruction } from 'services/model'
import { getInstructions } from 'services/queries-typed'

export const ManageInstructionsScreen: React.FC = () => {
  const { t } = useTranslation()
  const customer = useSelector((state) => state.entities.customer)
  const permissions = useSelector((state) => state.profile.permissions)
  const history = useHistory()
  const { customerId } = useParams<CustomerRouteParams>()
  const columns: Column[] = [
    {
      label: t('instructions.title'),
      getter: (instruction: Instruction) => instruction.name,
    },
  ]

  const handleGoToInstruction = (instruction: Record<'id', any>) => {
    history.push(routes.instructionProfile(customerId, instruction.id))
  }

  return (
    <PageHeader
      title={t('instructions.title')}
      breadcrumbs={[
        {
          name: t('instructions.overview'),
          path: routes.instructions(customerId),
        },
      ]}
    >
      {customer && (
        <List
          customer={customer}
          columns={columns}
          query={getInstructions}
          record={'instructions'}
          onRowClick={
            permissions.includes('weissbier:read:facility_instruction')
              ? handleGoToInstruction
              : undefined
          }
        />
      )}
    </PageHeader>
  )
}
