import { useEffect, useState } from 'react'

import { Button, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import moment from 'moment'
import { Column } from 'components/Table'
import { AssignElementsModal } from 'components/assign-elements-modal'
import { CustomerElementsTable } from 'components/customer-elements-table'
import { PageHeader } from 'containers/page-headers'
import routes, { InstructionRouteParams } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useMutation } from 'redux-query-react'
import { Division, InstructionDivision } from 'services/model'
import {
  assignInstructionDivision,
  getInstruction,
  getInstructionDivisions,
  getInstructionUnassignedDivisions,
  unassignInstructionDivision,
} from 'services/queries-typed'

const useStyles = makeStyles()(() => ({
  divider: {
    marginBottom: '2rem',
  },
}))

export const InstructionProfile = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [assignModalIsOpen, setAssignModalIsOpen] = useState<boolean>(false)
  const { instructionId, customerId } = useParams<InstructionRouteParams>()

  const instruction = useSelector((state) => state.entities.instruction)
  const language = useSelector((state) => state.settings.language)
  const [{ isPending }, fetchInstruction] = useMutation(getInstruction)

  useEffect(() => {
    if (language && instructionId) {
      fetchInstruction(customerId, instructionId, language)
    }
  }, [language, instructionId, fetchInstruction, customerId])

  const handleModalOpen = () => {
    setAssignModalIsOpen(true)
  }

  const handleModalClose = () => {
    setAssignModalIsOpen(false)
  }

  const columns: Column[] = [
    { label: t('id'), getter: (facility: Division) => facility.id },
    {
      label: t('location'),
      getter: (facility: Division) => facility.location,
      sortKey: 'location',
    },
    {
      label: t('operational_area'),
      getter: (facility: Division) => facility.operational_area,
      sortKey: 'operational_area',
    },
  ]

  const assginedColumns: Column[] = [
    ...columns,
    {
      label: t('expires_at'),
      getter: (facility: InstructionDivision) =>
        moment(facility.instruction_expires_at).format('YYYY-MM-DD'),
      sortKey: 'instruction_expires_at',
    },
  ]

  return (
    <PageHeader
      title={instruction?.name}
      breadcrumbs={[
        {
          name: t('instructions.title'),
          path: routes.instructionsManage(customerId),
        },
      ]}
    >
      <Divider className={classes.divider} />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h6">
                {t('instructions.divisions')}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                data-testid={`open-assign-instruction-modal-button`}
                onClick={handleModalOpen}
                variant="contained"
                color="secondary"
              >
                {t('instructions.add_divisions')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          {instruction && !isPending && (
            <>
              <CustomerElementsTable
                key={assignModalIsOpen.toString()}
                customerId={customerId}
                elementId={instructionId}
                name={instruction.name}
                columns={assginedColumns}
                elementType="instructionDivisions"
                elementSelectorKey="instructionDivisions"
                getElementQuery={getInstructionDivisions}
                // @ts-expect-error
                onSelectElementsQuery={unassignInstructionDivision}
                tableType="assigned"
              />
              <AssignElementsModal
                customerId={customerId}
                elementId={instructionId}
                name={instruction.name}
                isOpen={assignModalIsOpen}
                onClose={handleModalClose}
                elementType="instructionDivisions"
                elementSelectorKey="instructionUnassignedDivisions"
                getElementQuery={getInstructionUnassignedDivisions}
                // @ts-expect-error
                onSelectElementsQuery={assignInstructionDivision}
                columns={columns}
              />
            </>
          )}
        </Grid>
      </Grid>
    </PageHeader>
  )
}
