const styles = {
  spinner: {
    margin: '10% auto',
  },
  logo: {
    width: '30%',
    margin: '2rem auto',
  },
}

export default styles
