const routes = {
  instructions: (customerId: string) => `/${customerId}/instructions`,
  instructionsEmployeeUpload: (customerId: string) =>
    `/${customerId}/instructions/upload`,
  instructionsManage: (customerId: string) =>
    `/${customerId}/instructions/manage`,
  instructionsDivisionOverview: (
    customerId: string,
    divisionId: number | string
  ) => `/${customerId}/instructions/divisions/${divisionId}/overview`,
  instructionProfile: (customerId: string, instructionId: string) =>
    `/${customerId}/instructions/manage/${instructionId}`,

  divisions: (customerId: string) => `/${customerId}/divisions`,
  divisionsUpload: (customerId: string) => `/${customerId}/divisions/upload`,
  divisionProfile: (customerId: string, divisionId: string) =>
    `/${customerId}/divisions/${divisionId}`,

  users: (customerId: string) => `/${customerId}/users`,
  usersUpload: (customerId: string) => `/${customerId}/users/upload`,
  userProfile: (customerId: string, userId: string) =>
    `/${customerId}/users/${userId}`,
  addUser: (customerId: string) => `/${customerId}/users/add-user`,

  audits: (customerId: string) => `/${customerId}/audits`,

  postSignup: () => '/post-signup',
  userNotFound: () => '/user-not-found',
  emailVerification: () => '/email-verification',

  chatbotSBK: () => '/argumed/erstaudit',
  chatbotCovid: () => '/argumed/covid19',
  chatbotHomeOffice: () => '/argumed/home-office-gbu',

  dashboard: (customerId: string) => `/${customerId}/dashboard`,
  library: (customerId: string) => `/${customerId}/library`,
  libraryFolder: (customerId: string, folderPath: string) =>
    `/${customerId}/library/${folderPath}`,
  libraryUpload: (customerId: string, folderPath: string) =>
    `/${customerId}/library/${folderPath}/upload`,

  employeeInstructions: (customerId: string, employeeId: string) =>
    `/${customerId}/employees/${employeeId}/instructions`,

  measureDetails: (customerId: string, measureId: string) =>
    `/${customerId}/measures/${measureId}`,

  addMeasure: (customerId: string) => `/${customerId}/measures/add-measure`,

  admins: () => '/admins',
  addAdmin: () => '/admins/add-admin',
  adminProfile: (userId: string) => `/admins/${userId}`,

  safetyExperts: () => '/safety-experts',
  addSafetyExpert: () => '/safety-experts/add-user',
  safetyExpertProfile: (userId: string) => `/safety-experts/${userId}`,
}

export interface CustomerRouteParams {
  customerId: string
}

export interface AdminRouteParams {
  userId: string
}

export interface DivisionRouteParams extends CustomerRouteParams {
  divisionId: string
}

export interface UserRouteParams extends CustomerRouteParams {
  userId: string
}

export interface InstructionRouteParams extends CustomerRouteParams {
  instructionId: string
}

export interface DocumentRouteParams extends CustomerRouteParams {
  folderPath: string
}

export interface EmployeeRouteParams extends CustomerRouteParams {
  employeeId: string
}

export interface MeasureRouteParams extends CustomerRouteParams {
  measureId: string
}

export default routes
