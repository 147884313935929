import { Box, Button, Divider, Grid, Typography } from '@mui/material'

export interface SectionProps {
  title: string
  name: string
  buttonTitle?: string
  editMode?: boolean
  canEdit?: boolean
  showDivider?: boolean
  onButtonClick?: () => void
  children?: React.ReactNode
}

export const FormSection: React.FC<SectionProps> = ({
  title,
  name,
  buttonTitle,
  editMode,
  canEdit,
  showDivider = true,
  children,
  onButtonClick,
}) => {
  const showEditButton = !editMode && canEdit
  return (
    <Box>
      <Grid container>
        <Grid item xs={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h4" color="textSecondary">
                {title}
              </Typography>
            </Grid>
            {showEditButton && (
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={onButtonClick}
                  className="outlined-form-btn"
                  data-testid={`edit-form-btn-${name}`}
                >
                  {buttonTitle}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={9}>
          {children}
        </Grid>
      </Grid>
      {showDivider && <Divider sx={{ mt: 4, mb: 4 }} />}
    </Box>
  )
}
