import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { ProfileState } from 'redux/profile/types'

import DashboardScreen from '../dashboard'
import OnboardingScreen from '../onboarding'

interface Props {
  user: ProfileState
  customerStatus: 'created' | 'initial_audit_completed' | 'onboarded'
  forceLoadingScreen: boolean
  flags: Record<string, any>
}

export const App: React.FC<Props> = ({
  user,
  customerStatus,
  forceLoadingScreen,
  flags,
}) => {
  const showTutorial = user?.status === 'created'

  if (forceLoadingScreen) return <OnboardingScreen user={user} auditCompleted />
  else if (customerStatus) {
    switch (customerStatus) {
      case 'onboarded':
        return <DashboardScreen showTutorial={showTutorial} />
      case 'initial_audit_completed':
        return <OnboardingScreen user={user} auditCompleted={true} />
      case 'created':
        if (flags.enableLocationBasedAudit) {
          return <DashboardScreen showTutorial={showTutorial} />
        } else {
          return <OnboardingScreen user={user} auditCompleted={false} />
        }
      default:
        return <DashboardScreen showTutorial={false} />
    }
  } else {
    return null
  }
}

export default withLDConsumer()(App)
