import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

import { DangerButton } from './DangerButton'

const useStyles = makeStyles()((theme) => ({
  dialogTitle: {
    padding: theme.spacing(4),
    paddingBottom: 0,
  },
  dialogContent: {
    padding: theme.spacing(4),
    paddingTop: 0,
  },
  dialogActions: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  cancelButton: {
    color: theme.palette.text.secondary,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}))

export interface Props {
  bodyCopy: string
  confirmButtonCopy: string
  open: boolean
  onClose: () => void
  onConfirm: () => void
  disabled?: boolean
  errorMessage?: string
}

export const ConfirmationModal: React.FC<Props> = ({
  bodyCopy,
  confirmButtonCopy,
  open,
  onClose,
  onConfirm,
  disabled,
  errorMessage,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h2">{t('confirmation_modal.title')}</Typography>
        <IconButton
          aria-label="close"
          data-testid="close-modal-button"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText data-testid="confirmation-modal-content-text">
          {bodyCopy}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item>
            <Button
              onClick={onClose}
              variant="text"
              color="secondary"
              className={classes.cancelButton}
            >
              {t('confirmation_modal.cancel')}
            </Button>
            <DangerButton
              onClick={onConfirm}
              actionButton={true}
              variant="contained"
              color="secondary"
              disabled={disabled ?? false}
              data-testid="confirmation-modal-confirm-button"
            >
              {confirmButtonCopy}
            </DangerButton>
          </Grid>
          {disabled && errorMessage && (
            <Grid item>
              <Typography color="error" data-testid="confirmation-modal-error">
                {errorMessage}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
