import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import CsvImport, { CsvUploadDataType } from 'containers/csv-import'
import { useTranslation } from 'react-i18next'
import { getInstructionsEmployees, importEmployees } from 'services/queries'
import routes, { CustomerRouteParams } from 'navigation/routes'
import { PageHeader } from 'containers/page-headers'

export const EmployeeImportScreen = () => {
  const { t } = useTranslation()

  const { customerId } = useParams<CustomerRouteParams>()

  const [exportRunning, setExportRunning] = useState(false)

  const permissions = useSelector((state) => state.profile.permissions)
  const canExportEmployees = permissions.includes('weissbier:list:employee')

  const handleExport = useCallback(() => {
    setExportRunning(true)
    getInstructionsEmployees(
      customerId,
      t('employee_import.export_filename')
    ).then(() => setExportRunning(false))
  }, [customerId, getInstructionsEmployees])

  return (
    <PageHeader
      title={t('employee_import.title')}
      buttonTitle={
        exportRunning
          ? t('dropzone.csv_import.export_employees_btn_exporting')
          : t('dropzone.csv_import.export_employees_btn')
      }
      onButtonClick={handleExport}
      displayButton={canExportEmployees}
      disableButton={exportRunning}
      breadcrumbs={[
        {
          name: t('instructions.title'),
          path: routes.instructions(customerId),
        },
      ]}
    >
      <CsvImport
        title={t('employeeInstructionList_employeeHeader')}
        query={importEmployees}
        dataType={CsvUploadDataType.EMPLOYEES}
      />
    </PageHeader>
  )
}

export default EmployeeImportScreen
