import { Paper, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()(() => ({
  root: {
    padding: '5rem',
    textAlign: 'center',
  },
}))

export const PostSignupPage: React.FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <Paper
      elevation={0}
      className={classes.root}
      data-testid="post-signup-page-content"
    >
      <Typography variant="h2">{t('users.post_signup_message')}</Typography>
      <Typography>{t('users.post_signup_message_contact')}</Typography>
    </Paper>
  )
}
