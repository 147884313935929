import { useState, useEffect } from 'react'

import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'
import { colors } from 'themes'

export const RiskLevelChip = ({ measure, isModalView }) => {
  const { t } = useTranslation()
  const [color, setColor] = useState(colors.charcoal)
  const [bgColor, setBgColor] = useState(colors.mist)
  const { risk_level } = measure

  useEffect(() => {
    if (isModalView) {
      setBgColor(colors.white)
      switch (risk_level) {
        case 1:
          return
        case 2:
          return
        case 3:
          return setColor(colors.red)
        default:
          break
      }
    } else {
      switch (risk_level) {
        case 1:
          return setBgColor(colors.mist)
        case 2:
          return setBgColor(colors.linen)
        case 3:
          return setBgColor(colors.peach)
        default:
          return setBgColor(colors.mist)
      }
    }
  }, [risk_level, isModalView])

  return (
    <Chip
      label={t(`measuresScreen_risk_${risk_level}`)}
      style={{
        backgroundColor: bgColor,
        color: color,
        borderRadius: '5px',
      }}
    />
  )
}
export default RiskLevelChip
