import { Redirect, Route, RouteProps } from 'react-router-dom'

interface Props {
  hasAccess: boolean
}

export const ProtectedRoute: React.FC<Props & RouteProps> = ({
  hasAccess,
  ...rest
}) =>
  hasAccess ? <Route {...rest} /> : <Redirect to={{ pathname: '/not-found' }} />
