import { createReducer } from '@reduxjs/toolkit'
import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import { setPermissions, setProfile, setUserStatus } from './actions'
import { ProfileState } from './types'

const INITIAL_STATE: ProfileState = {
  userId: null,
  name: null,
  firstName: null,
  lastName: null,
  email: null,
  emailVerified: false,
  status: null,
  permissions: [],
}

export type ProfileActions = ActionType<typeof actions>

const profileReducer = createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(setProfile, (state, action) => {
    const user = action.payload

    state.userId = user.sub || null
    state.name = user.name || null
    state.firstName = user.given_name || null
    state.lastName = user.family_name || null
    state.email = user.email || null
    state.emailVerified = user.email_verified || false
  })
  builder.addCase(setUserStatus, (state, action) => {
    state.status = action.payload
  })
  builder.addCase(setPermissions, (state, action) => {
    state.permissions = action.payload
  })
})

export default profileReducer
