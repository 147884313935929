export const alphaSort = (a: string, b: string) => (a < b ? -1 : a > b ? 1 : 0)
export const alphaSortCaseInsensitive = (
  a: string | null,
  b: string | null
) => {
  if (a === b) return 0
  if (!a) return 1
  if (!b) return -1
  return alphaSort(a.toUpperCase(), b.toUpperCase())
}
