import { Button, ButtonProps } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  danger: {
    backgroundColor: theme.palette.background.danger,
    '&:hover': {
      backgroundColor: theme.palette.background.danger,
    },
  },
  // FIXME: Remove when globalizing modal styles:
  actionButton: {
    marginTop: 0,
    marginBottom: 0,
  },
}))

export interface Props extends ButtonProps {
  actionButton?: boolean
}

export const DangerButton: React.FC<Props> = ({ actionButton, ...rest }) => {
  const { classes } = useStyles()
  return (
    <Button
      {...rest}
      className={
        actionButton
          ? `${classes.danger} ${classes.actionButton}`
          : classes.danger
      }
    />
  )
}
