import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {

    const fragment = window.location.hash;

    const parts = fragment.split('/');

    let customerId = null;
    let employeeId = null;
    let pageName = null;

    if (parts.length > 4 && parts[2] === 'employees') {
      customerId = parts[1];   // "CUSTOMER_ID"
      employeeId = parts[3];   // "EMPLOYEE_ID"
      pageName = parts[4];     // "dashboard"
    }

    else if (parts.length === 3) {
      customerId = parts[1];
      pageName = parts[2];
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.href,
        path: window.location.pathname,
        title: document.title
      },
      customer_id: customerId,
      employee_id: employeeId,
      page_name: pageName
    });

  }, [location]);
};

export default usePageViewTracker;
