import { useEffect, useState } from 'react'

import { ConfirmationModal } from 'components/ConfirmationModal'
import { PageHeader } from 'containers/page-headers'
import routes, { AdminRouteParams } from 'navigation/routes'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from 'redux-query-react'
import { deleteSafetyExpert, getSafetyExpert } from 'services/queries-typed'
import { addUserlikeScript } from 'services/userlike'

import List from './list'
import { SafetyExpertProfile } from './profile'

export const SafetyExpertListPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const permissions = useSelector((state) => state.profile.permissions)
  const isAdmin = permissions.includes('weissbier:create:safety_expert')

  const handleGoToCreateSafetyExpert = () => {
    history.push(routes.addSafetyExpert())
  }
  useEffect(() => {
    addUserlikeScript()
  }, [])

  return (
    <PageHeader
      title={t('safetyExperts.title')}
      buttonTitle={t('safetyExperts.add')}
      onButtonClick={handleGoToCreateSafetyExpert}
      displayButton={isAdmin}
    >
      {isAdmin && <List />}
    </PageHeader>
  )
}

export const SafetyExpertProfilePage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { userId } = useParams<AdminRouteParams>()
  const safetyExpert = useSelector((state) => state.entities.safetyExpert)
  const canDeleteSafetyExpert = useSelector((state) =>
    state.profile.permissions.includes('weissbier:delete:safety_expert')
  )

  const [{ isPending }, fetchSafetyExpert] = useMutation(getSafetyExpert)
  const [{ isPending: isDeletingSafetyExpert }, removeSafetyExpert] =
    useMutation(deleteSafetyExpert)
  const [userTitle, setUserTitle] = useState('')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (userId) {
      fetchSafetyExpert(userId)
    }
  }, [userId, fetchSafetyExpert])

  useEffect(() => {
    addUserlikeScript()
  }, [])

  useEffect(() => {
    if (safetyExpert) {
      if (safetyExpert.first_name) {
        setUserTitle(`${safetyExpert.first_name} ${safetyExpert.last_name}`)
      } else if (safetyExpert.email) {
        setUserTitle(safetyExpert.email)
      } else {
        setUserTitle(safetyExpert.id)
      }
    }
  }, [safetyExpert])

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleOnDelete = () => {
    setOpen(false)

    removeSafetyExpert(userId)
      ?.then((response) => {
        if (response.status === 204) {
          history.replace(routes.safetyExperts())
          enqueueSnackbar(t('safetyExperts.delete_safetyExpert.success'), {
            variant: 'success',
          })
        } else {
          enqueueSnackbar(t('safetyExperts.delete_safetyExpert.error'), {
            variant: 'error',
          })
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' })
      })
  }

  return (
    <>
      {!isPending && !isDeletingSafetyExpert && (
        <>
          <PageHeader
            title={userTitle}
            infoTitle={t(`safetyExperts.safetyExpert`)}
            secondaryTitle={safetyExpert?.email}
            breadcrumbs={[
              { name: t('safetyExperts.title'), path: routes.safetyExperts() },
            ]}
            buttonTitle={t('safetyExperts.delete')}
            onButtonClick={handleOpenModal}
            dangerButton={true}
            displayButton={canDeleteSafetyExpert}
          >
            <ConfirmationModal
              bodyCopy={t('safetyExperts.delete_modal_body', {
                name: userTitle,
              })}
              confirmButtonCopy={t('safetyExperts.delete_confirm')}
              open={open}
              onClose={handleCloseModal}
              onConfirm={handleOnDelete}
            />
            {safetyExpert && (
              <SafetyExpertProfile safetyExpert={safetyExpert} />
            )}
          </PageHeader>
        </>
      )}
    </>
  )
}
