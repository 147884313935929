import { useEffect, useState } from 'react'

import Brightness1Icon from '@mui/icons-material/Brightness1'
import { colors } from 'themes'
export interface Props {
  overdue: boolean
  done: boolean
}

export const StatusLightIcon: React.FC<Props> = ({ overdue, done }) => {
  const [color, setColor] = useState(colors.yellow)

  useEffect(() => {
    if (overdue) {
      setColor(colors.red)
    } else if (done) {
      setColor(colors.green)
    } else {
      setColor(colors.yellow)
    }
  }, [overdue, done])

  return (
    <Brightness1Icon
      style={{
        color,
        top: '0.2rem',
        position: 'relative',
        height: '1rem',
      }}
    />
  )
}
export default StatusLightIcon
