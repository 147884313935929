const userlikeScript = document.createElement('script')
userlikeScript.id = 'userlike'
userlikeScript.src =
  'https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/51064e475b11426691e416608131f578f364663a253f4de58660864bcfacd1a9.js'
userlikeScript.async = true

const hasUserlikeScript = () => {
  return document.getElementById('userlike')
}

export const addUserlikeScript = () => {
  if (!hasUserlikeScript()) {
    document.body.appendChild(userlikeScript)
  }
}
