import { useEffect } from 'react'

import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { addUserlikeScript } from 'services/userlike'

import Styles from './styles.js'

export const ErrorPage = (props) => {
  const { t } = useTranslation()
  useEffect(() => {
    addUserlikeScript()
  }, [])
  return (
    <Typography
      align="center"
      variant="h5"
      style={Styles.message}
      data-testid="error-page"
    >
      {t('errorScreen_title')}
    </Typography>
  )
}

export default ErrorPage
