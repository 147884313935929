import { useEffect, useState } from 'react'

import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { changeToNextLanguage } from 'i18n'
import { getLanguageCode } from 'lib/helpers'
import { useDispatch } from 'react-redux'
import { setLanguage } from 'redux/settings/actions'
import { Language } from 'redux/settings/types'
import { fonts } from 'themes'

const useStyles = makeStyles()((theme) => ({
  container: {
    fontFamily: fonts.bold,
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.primary.light,
    whiteSpace: 'nowrap',
  },
  button: {
    fontSize: '1rem',
    minWidth: '2rem',
    color: theme.palette.primary.light,
  },
  unselected: {
    // FIXME: update theme to use MUI colors
    color: theme.palette.text.unselected,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
}))

export const LanguageSwitch: React.FC = () => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const getLanguage = () =>
    getLanguageCode() === 'de' ? Language.DE : Language.EN

  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    getLanguage()
  )
  const handleSwitch = () => {
    changeToNextLanguage()
    setSelectedLanguage(getLanguage())
  }

  useEffect(() => {
    if (selectedLanguage) {
      dispatch(setLanguage({ language: selectedLanguage }))
    } else {
      setSelectedLanguage(getLanguage())
    }
  }, [selectedLanguage, dispatch])

  return (
    <Typography align="right" className={classes.container}>
      <Button
        onClick={selectedLanguage === Language.EN ? handleSwitch : undefined}
        data-testid="language-switch-de"
        className={
          selectedLanguage === Language.EN
            ? `${classes.button} ${classes.unselected}`
            : classes.button
        }
      >
        DE
      </Button>
      |
      <Button
        onClick={selectedLanguage === Language.DE ? handleSwitch : undefined}
        data-testid="language-switch-en"
        className={
          selectedLanguage === Language.DE
            ? `${classes.button} ${classes.unselected}`
            : classes.button
        }
      >
        EN
      </Button>
    </Typography>
  )
}

export default LanguageSwitch
