import { useState, useEffect, useRef } from 'react'

import AppBar from 'components/AppBar'
import App from 'containers/app'
import AuthLock from 'containers/auth-lock'
import { ConsentModal } from 'containers/consent-modal'
import { DocumentLibrary } from 'containers/document-library'
import { DocumentLibraryUpload } from 'containers/document-library/upload'
import EmailVerificationPage from 'containers/email-verification-page'
import EmployeeImportScreen from 'containers/employee-import'
import { EmployeeInstructionsDashboard } from 'containers/employee-instructions-dashboard'
import ErrorPage from 'containers/error-page'
import { FacilityListPage, FacilityImportPage } from 'containers/facilities'
import { FacilityProfilePage } from 'containers/facilities/profile'
import { ManageInstructionsScreen } from 'containers/instructions'
import InstructionsOverviewScreen from 'containers/instructions-overview'
import { InstructionProfile } from 'containers/instructions/profile'
import { LocationAudit } from 'containers/location-audit'
import OnlineInstructionsScreen from 'containers/online-instructions'
import InstructionsFacilityEmployees from 'containers/online-instructions-facility-employees'
import { PostSignupPage } from 'containers/post-signup-page'
import Sidebar from 'containers/sidebar'
import UnauthChatbot from 'containers/unauthenticated-chatbot'
import { UserNotFoundPage } from 'containers/user-not-found-page'
import {
  UserListPage,
  UserImportPage,
  UserProfilePage,
  CreateUserPage,
} from 'containers/users'
import { MeasureDetailsPage } from 'containers/measure-details'
import { AdminListPage, AdminProfilePage } from 'containers/admins'
import {
  SafetyExpertListPage,
  SafetyExpertProfilePage,
} from 'containers/safety-experts'
import { withLDConsumer, withLDProvider } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import config from 'services/config'

import { RoleGuard, ProtectedRoute, VerifiedEmailGuard } from './RouteGuards'
import { history } from './history'
import routes from './routes'
import usePageViewTracker from 'lib/gtm-tracker'

function RouterWithRoutes({ flags }) {
  usePageViewTracker();
  const user = useSelector((state) => state.profile)
  const customer = useSelector((state) => state.entities.customer)
  const permissions = useSelector((state) => state.profile.permissions)

  const customerStatus = customer?.status
  const safetyExpert = useSelector(
    (state) => state.entities.customer?.safety_expert
  )

  const [forceLoading, setForceLoading] = useState(false)

  const previousCustomer = useRef(null)
  const loadingTimeout = useRef(null)

  useEffect(() => {
    return () => clearTimeout(loadingTimeout.current)
  }, [])

  useEffect(() => {
    if (
      previousCustomer.current?.status === 'created' &&
      customerStatus === 'onboarded' &&
      previousCustomer.current?.id === customer?.id
    ) {
      setForceLoading(true)
      loadingTimeout.current = setTimeout(() => setForceLoading(false), 5000)
    }
    previousCustomer.current = { id: customer?.id, status: customerStatus }
  }, [customer, customerStatus])

  const showMenu = customer?.status === 'onboarded' && !forceLoading

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/argumed/erstaudit">
          <UnauthChatbot type="sbk" />
        </Route>
        <Route exact path="/argumed/covid19">
          <UnauthChatbot type="covid" />
        </Route>
        <Route exact path="/argumed/home-office-gbu">
          <UnauthChatbot type="hora" displayAppBar={true} />
        </Route>
        <Route exact path="/:customerId/employees/:employeeId/dashboard">
          <EmployeeInstructionsDashboard />
        </Route>
        <AuthLock>
          <ConsentModal />
          <AppBar user={user} safetyExpert={safetyExpert} />
          <Route exact path="/email-verification">
            <EmailVerificationPage />
          </Route>
          <VerifiedEmailGuard>
            <Route exact path="/post-signup">
              <PostSignupPage />
            </Route>
            <Route exact path="/user-not-found">
              <UserNotFoundPage />
            </Route>
            <RoleGuard>
              <Sidebar show={showMenu}>
                <Switch>
                  <Route exact path="/">
                    {customer && (
                      <Redirect to={routes.dashboard(customer.id)} />
                    )}
                  </Route>
                  <Route exact path="/:customerId/dashboard">
                    <App
                      user={user}
                      customerStatus={customerStatus}
                      forceLoadingScreen={forceLoading}
                    />
                  </Route>
                  <Route exact path="/:customerId/measures/add-measure">
                    <MeasureDetailsPage />
                  </Route>
                  <Route exact path="/:customerId/measures/:measureId">
                    <MeasureDetailsPage />
                  </Route>
                  <Route exact path="/:customerId/library">
                    <DocumentLibrary />
                  </Route>
                  <ProtectedRoute
                    hasAccess={permissions.includes(
                      'weissbier:create:library_document'
                    )}
                    exact
                    path="/:customerId/library/:folderPath/upload"
                  >
                    <DocumentLibraryUpload />
                  </ProtectedRoute>
                  <Route exact path="/:customerId/library/:folderPath">
                    <DocumentLibrary />
                  </Route>
                  <ProtectedRoute
                    hasAccess={permissions.includes(
                      'weissbier:create:employees_batch_update'
                    )}
                    exact
                    path="/:customerId/instructions/upload"
                  >
                    <EmployeeImportScreen />
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes(
                      'weissbier:list:instruction'
                    )}
                    exact
                    path="/:customerId/instructions"
                  >
                    <OnlineInstructionsScreen>
                      <InstructionsOverviewScreen />
                    </OnlineInstructionsScreen>
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes(
                      'weissbier:update:facility_instruction'
                    )}
                    exact
                    path="/:customerId/instructions/manage"
                  >
                    <ManageInstructionsScreen />
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes(
                      'weissbier:update:facility_instruction'
                    )}
                    exact
                    path="/:customerId/instructions/manage/:instructionId"
                  >
                    <InstructionProfile />
                  </ProtectedRoute>
                  <Route
                    exact
                    path="/:customerId/instructions/divisions/:divisionId/overview"
                  >
                    <OnlineInstructionsScreen>
                      <InstructionsFacilityEmployees />
                    </OnlineInstructionsScreen>
                  </Route>
                  <Route exact path="/:customerId/divisions">
                    <FacilityListPage />
                  </Route>
                  <ProtectedRoute
                    hasAccess={permissions.includes(
                      'weissbier:create:facilities_batch_update'
                    )}
                    exact
                    path="/:customerId/divisions/upload"
                  >
                    <FacilityImportPage />
                  </ProtectedRoute>
                  <Route exact path="/:customerId/divisions/:divisionId">
                    <FacilityProfilePage />
                  </Route>
                  <ProtectedRoute
                    hasAccess={permissions.includes('weissbier:list:manager')}
                    exact
                    path="/:customerId/users"
                  >
                    <UserListPage />
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes(
                      'weissbier:create:managers_batch_update'
                    )}
                    exact
                    path="/:customerId/users/upload"
                  >
                    <UserImportPage />
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes('weissbier:create:user')}
                    exact
                    path="/:customerId/users/add-user"
                  >
                    {customer && (
                      <CreateUserPage
                        type="ohsOfficer"
                        customerId={customer.id}
                      />
                    )}
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes('weissbier:read:manager')}
                    exact
                    path="/:customerId/users/:userId"
                  >
                    <UserProfilePage />
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes(
                      'weissbier:list:unaudited_location'
                    )}
                    exact
                    path="/:customerId/audits"
                  >
                    <LocationAudit />
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes('weissbier:list:admin')}
                    exact
                    path="/admins"
                  >
                    <AdminListPage />
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes('weissbier:create:admin')}
                    exact
                    path="/admins/add-admin"
                  >
                    <CreateUserPage type="admin" />
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes('weissbier:read:admin')}
                    exact
                    path="/admins/:userId"
                  >
                    <AdminProfilePage />
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes(
                      'weissbier:list:safety_expert'
                    )}
                    exact
                    path="/safety-experts"
                  >
                    <SafetyExpertListPage />
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes(
                      'weissbier:create:safety_expert'
                    )}
                    exact
                    path="/safety-experts/add-user"
                  >
                    <CreateUserPage type="safetyExpert" />
                  </ProtectedRoute>
                  <ProtectedRoute
                    hasAccess={permissions.includes(
                      'weissbier:read:safety_expert'
                    )}
                    exact
                    path="/safety-experts/:userId"
                  >
                    <SafetyExpertProfilePage />
                  </ProtectedRoute>
                  <Route path="*">
                    <ErrorPage />
                  </Route>
                </Switch>
              </Sidebar>
            </RoleGuard>
          </VerifiedEmailGuard>
        </AuthLock>
      </Switch>
    </Router>
  )
}

export const initialLdUser = {
  key: 'logged_out_user',
  anonymous: true,
}

export default withLDProvider({
  clientSideID: config.LD_CLIENT_SIDE_ID,
  user: initialLdUser,
})(withLDConsumer()(RouterWithRoutes))
