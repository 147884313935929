import { useCallback, useEffect, useState } from 'react'

import { Breadcrumbs, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import routes, { DivisionRouteParams } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import { useMutation } from 'redux-query-react'
import {
  getEmployeeInstructionsResultsDetails,
  getFacility,
  sendCustomerInstructionReminderEmails,
} from 'services/queries-typed'
import { addUserlikeScript } from 'services/userlike'
import { ConfirmationModal } from 'components/ConfirmationModal'
import { useSnackbar } from 'notistack'
import { Features } from 'services/model'

const useStyles = makeStyles()(() => ({
  root: {
    padding: '2rem',
  },
}))

interface Props {
  children?: React.ReactNode
}

export const OnlineInstructionsScreen: React.FC<Props> = ({ children }) => {
  const { classes } = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { customerId, divisionId } = useParams<DivisionRouteParams>()
  const division = useSelector((state) => state.entities.division)
  const language = useSelector((state) => state.settings.language)
  const hasEmailsDisabled = useSelector((state) =>
    state.entities.customer?.features?.includes(Features.NO_EMAIL_INSTRUCTIONS)
  )
  const [, getFacilityRequest] = useMutation(getFacility)
  const [, sendReminders] = useMutation(sendCustomerInstructionReminderEmails)

  const [exportRunning, setExportRunning] = useState(false)
  const [hasSentReminder, setHasSentReminder] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (divisionId) {
      getFacilityRequest(customerId, parseInt(divisionId, 10))
    }
  }, [customerId, divisionId, getFacilityRequest])

  const isDivisionsOverview = useRouteMatch(
    routes.instructions(customerId)
  )?.isExact

  const handleGoToCSVUpload = () => {
    history.push(routes.instructionsEmployeeUpload(customerId))
  }
  const handleGoToManageInstructions = () => {
    history.push(routes.instructionsManage(customerId))
  }

  const handleExport = useCallback(() => {
    setExportRunning(true)
    getEmployeeInstructionsResultsDetails(
      customerId,
      language,
      t('instructionScreen.exportFileName')
    ).finally(() => setExportRunning(false))
  }, [customerId, language, getEmployeeInstructionsResultsDetails])

  useEffect(() => {
    addUserlikeScript()
  }, [])

  const handleReminder = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const onSendReminders = () => {
    setIsModalOpen(false)
    sendReminders(customerId)
      ?.then((response) => {
        if (response.status < 400) {
          setHasSentReminder(true)
          enqueueSnackbar(t('instructionScreen.reminder.success'), {
            variant: 'success',
          })
        } else {
          enqueueSnackbar(t('instructionScreen.reminder.error'), {
            variant: 'error',
          })
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' })
      })
  }

  const permissions = useSelector((state) => state.profile.permissions)
  const canUploadCSV = permissions.includes(
    'weissbier:create:employees_batch_update'
  )
  const canManageInstructions = permissions.includes(
    'weissbier:update:facility_instruction'
  )

  const canSendReminders = permissions.includes('weissbier:send:reminder')

  const canExportCSV = permissions.includes(
    'weissbier:read:instructions_results_details'
  )

  const showSendReminderButton =
    canSendReminders && isDivisionsOverview && !hasEmailsDisabled

  return (
    <Grid container direction="column" className={classes.root}>
      {divisionId && division && (
        <Grid container justifyContent="space-between">
          <Grid item>
            <Breadcrumbs>
              <Link
                to={routes.instructions(customerId)}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography variant="h6">
                  {t('instructionsScreen_name')}
                </Typography>
              </Link>
              <Typography
                variant="h4"
                data-testid="online-instructions-breadcrumbs"
              >{`${division.location}, ${division.operational_area}`}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item />
        </Grid>
      )}
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h1" data-testid="online-instructions-title">
            {t('instructionsScreen_name')}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            {canManageInstructions && (
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={handleGoToManageInstructions}
                  data-testid="online-instructions-navigation-button"
                >
                  {t('instructionsScreen_goTo_overview')}
                </Button>
              </Grid>
            )}
            {canUploadCSV && isDivisionsOverview && (
              <Grid item>
                <Button
                  sx={{ mb: 0 }}
                  onClick={handleGoToCSVUpload}
                  variant="contained"
                  color="secondary"
                  data-testid="online-instructions-import-csv-button"
                >
                  {t('instructionsScreen_upload_csv')}
                </Button>
              </Grid>
            )}
            {canExportCSV && isDivisionsOverview && (
              <Grid item>
                <Button
                  sx={showSendReminderButton ? { mb: 0 } : {}}
                  disabled={exportRunning}
                  onClick={handleExport}
                  variant="contained"
                  color="secondary"
                  data-testid="online-instructions-export-csv-button"
                >
                  {t('instructionScreen.export')}
                </Button>
              </Grid>
            )}
            {showSendReminderButton && (
              <Grid item>
                <Button
                  disabled={hasSentReminder}
                  onClick={handleReminder}
                  variant="contained"
                  color="secondary"
                  data-testid="online-instructions-remind-overdue-button"
                >
                  {hasSentReminder
                    ? t('instructionScreen.reminder_sent_btn')
                    : t('instructionScreen.remind_overdue_employees')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{children}</Grid>
      <ConfirmationModal
        bodyCopy={t('instructionScreen.reminder_modal_body')}
        confirmButtonCopy={t('global.buttons.send')}
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={onSendReminders}
      />
    </Grid>
  )
}

export default OnlineInstructionsScreen
