import config from './config'
import { options, formDataOptions, createFormData } from './utils'
export * from './queries-typed'

export const transforms = {
  measuresArray: (data) => ({
    measures: data.data,
  }),
  complianceDataArray: (data) => {
    return {
      compliance: data.data,
    }
  },
  riskGroups: (data) => {
    return {
      riskGroups: data.data,
    }
  },
  user: (data) => {
    return {
      user: data.data,
    }
  },
  documents: (data) => {
    return {
      documents: data.data,
    }
  },
}

export const getMeasuresForCustomer = (customerId, params, force = false) => {
  const url = `${config.OHS_BACKEND_URL}/customers/${customerId}/measures`
  return {
    url,
    options,
    force,
    transform: transforms.measuresArray,
    body: params,
    update: {
      measures: (prevMeasues, measures) => measures,
    },
  }
}

export const editCustomerMeasure = (customerId, measureId, measure) => ({
  queryKey: editCustomerMeasure.QUERY_KEY,
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures/${measureId}`,
  options: {
    ...options,
    method: 'PATCH',
  },
  body: {
    ...measure,
  },
  update: {},
})

export const createMeasure = (customerId, measure) => ({
  queryKey: createMeasure.QUERY_KEY,
  url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures`,
  options: {
    ...options,
    method: 'POST',
  },
  body: measure,
  update: {},
})

export const getComplianceDataForCustomer = (customerId) => {
  const url = `${config.OHS_BACKEND_URL}/customers/${customerId}/compliance`
  return {
    queryKey: getComplianceDataForCustomer.QUERY_KEY + customerId,
    url,
    options,
    transform: transforms.complianceDataArray,
    update: {
      compliance: (prevData, data) => data,
    },
  }
}

export const getRiskGroups = () => {
  const url = `${config.OHS_BACKEND_URL}/risk_groups`
  return {
    queryKey: getRiskGroups.QUERY_KEY,
    url,
    options: {
      ...options,
      method: 'GET',
    },
    transform: transforms.riskGroups,
    update: {
      riskGroups: (prevData, data) => data,
    },
  }
}

export const getUser = () => {
  const url = `${config.OHS_BACKEND_URL}/users/self`
  return {
    queryKey: getUser.QUERY_KEY,
    url,
    options: {
      ...options,
      method: 'GET',
    },
    transform: transforms.user,
    update: {
      user: (prevData, data) => data,
    },
  }
}

export const editUser = (user) => ({
  queryKey: editUser.QUERY_KEY,
  url: `${config.OHS_BACKEND_URL}/users/self`,
  options: {
    ...options,
    method: 'PUT',
  },
  body: user,
  update: {},
})

export const addMeasureDocument = (document, customerId, measureId) => {
  const formData = createFormData(document)
  return {
    url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures/${measureId}/documents`,
    queryKey: `create-measure-document-${document.path}`,
    options: {
      ...formDataOptions,
      method: 'POST',
    },
    body: formData,
    update: {},
  }
}

export const createImage = (image, customerId, measureId) => {
  const formData = createFormData(image)
  return {
    url: `${config.OHS_BACKEND_URL}/customers/${customerId}/measures/${measureId}/images`,
    queryKey: `create-measure-image-${image.url}`,
    options: {
      ...formDataOptions,
      method: 'POST',
    },
    body: formData,
    update: {},
  }
}

editCustomerMeasure.QUERY_KEY = 'edit-customer-measure'
getComplianceDataForCustomer.QUERY_KEY = 'get-compliance-data'
getRiskGroups.QUERY_KEY = 'get-risk-groups'
getUser.QUERY_KEY = 'get-user'
editUser.QUERY_KEY = 'edit-user'
createMeasure.QUERY_KEY = 'create-measure'
