import { useCallback, useState } from 'react'
import { useEffect } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { setTrackConsentGranted, setTrackConsentWithdrawn } from 'lib/helpers'

export interface Props {
  title: string
  body: string
  onAgree: () => void
  onDisagree: () => void
  isOpen: boolean
}

export const ConsentModal: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const userProfile = useSelector((state) => state.profile)

  useEffect(() => {
    const consentAnswer = localStorage.getItem('tracking_consent')
    if (!consentAnswer) {
      setOpen(true)
    }
  }, [setOpen])

  const handleAgree = useCallback(() => {
    setTrackConsentGranted()
    setOpen(false)
  }, [])

  const handleDisagree = useCallback(() => {
    setTrackConsentWithdrawn()
    setOpen(false)
  }, [])

  return (
    <Modal
      title={t('consent.title')}
      body={t('consent.body')}
      onAgree={handleAgree}
      onDisagree={handleDisagree}
      isOpen={open}
    />
  )
}

export const Modal: React.FC<Props> = ({
  title,
  body,
  onAgree,
  onDisagree,
  isOpen,
}) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDisagree} color="primary">
          {t('consent.refuse')}
        </Button>
        <Button onClick={onAgree} color="primary" autoFocus>
          {t('consent.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
