import { Box, Grid, Typography } from '@mui/material'
import { formatMeasureCommentDateTime } from 'lib/helpers'
import { useTranslation } from 'react-i18next'
import { Customer, Measure, MeasureComment } from 'services/model'
import { CommentForm } from './MeasureCommentForm'

export interface Props {
  comment: MeasureComment
}

export const Comment = ({ comment }: Props) => {
  const { t } = useTranslation()
  const { author, created_at, text } = comment

  const displayName = () => {
    if (author?.first_name && author.last_name) {
      return `${author.first_name} ${author.last_name}`
    } else if (author?.email) {
      return author.email
    } else if (author?.id) {
      return t('measure.comments.anonymous_user')
    } else {
      return t('measure.comments.deleted_user')
    }
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Grid container spacing={4}>
          <Grid item>
            <Typography
              variant="subtitle1"
              className="adminStyleGuide"
              data-testid="measure-comment-author"
            >
              {displayName()}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" data-testid="measure-comment-date">
              {formatMeasureCommentDateTime(created_at)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body2">{text}</Typography>
      </Grid>
    </Grid>
  )
}

export interface CommentsProps {
  comments: MeasureComment[]
  measureId: Measure['id']
  customerId: Customer['id']
  canComment: boolean
  onComment: (text: Partial<MeasureComment>) => void
}

export const Comments = ({
  comments,
  onComment,
  measureId,
  customerId,
  canComment,
}: CommentsProps) => {
  return (
    <>
      <CommentForm
        onCreate={onComment}
        measureId={measureId}
        customerId={customerId}
        canComment={canComment}
      />
      {comments.map((comment: MeasureComment) => (
        <Box mt={4}>
          <Comment comment={comment} />
        </Box>
      ))}
    </>
  )
}
