import { Grid, Paper } from '@mui/material'
import { Typography } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { fonts } from 'themes'

const Container = styled(Paper).attrs({ elevation: 0 })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${(props) => props.theme.palette.background.banner};
  height: 12rem;
`
const Text = styled(Typography).attrs({ align: 'left' })`
  color: ${(props) => props.theme.palette.text.primary};
  line-height: 2rem;
`

const WelcomeText = styled(Text)`
  font-family: ${fonts.base};
  font-size: 1.7rem;
  font-weight: 200;
`
interface Props {
  children: React.ReactNode
}

export const SummaryHeader: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation()
  const { firstName } = useSelector((state) => state.profile)
  const { lastName } = useSelector((state) => state.profile)

  return (
    <Container data-testid="summary-header-banner">
      <Grid
        container
        alignItems="center"
        justifyContent="space-evenly"
        spacing={3}
      >
        <Grid item xs={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <WelcomeText>
                {t('summary_header_welcome')} {firstName} {lastName}
              </WelcomeText>
            </Grid>
            <Grid item>
              <Text>
                {t('summary_header_description')}{' '}
                <ArrowRightAltIcon
                  style={{
                    position: 'relative',
                    top: '0.5rem',
                  }}
                />
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
