import { Grid, Typography, Avatar } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { fonts, theme } from 'themes'
import { placeholderExpert } from 'themes/images'

const useStyles = makeStyles()((theme) => ({
  root: {
    textAlign: 'center',
    backgroundColor: theme.palette.background.banner,
    minHeight: '9rem',
  },
  h1: {
    fontFamily: fonts.base,
    color: theme.palette.text.primary,
    margin: '1rem 0 1.5rem',
  },
  h3: {
    fontFamily: fonts.base,
    color: theme.palette.text.primary,
    margin: '0.5rem 0',
  },
  text: {
    color: theme.palette.text.primary,
    margin: '0 0 2rem',
  },
  image: {
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  imageContainer: {
    paddingLeft: '3rem',
  },
}))

export const OnboardingBanner = ({ safetyExpert }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      data-testid="onboarding-banner"
    >
      <Grid item xs={2}></Grid>
      <Grid item xs={6}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h1" className={classes.h1} align="left">
              {t('onboarding_banner_welcome')} Argumed Kundenportal
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" className={classes.h3} align="left">
              {t('onboarding_banner_safety_expert', {
                firstName: safetyExpert?.first_name,
                lastName: safetyExpert?.last_name,
              })}
            </Typography>
            <Typography variant="body1" className={classes.text} align="left">
              {t('onboarding_banner_description')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <div className={classes.imageContainer}>
          <Avatar
            className={classes.image}
            alt={t('onboarding_banner_image_alt')}
            src={safetyExpert?.image || placeholderExpert}
          />
        </div>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  )
}
export default OnboardingBanner
