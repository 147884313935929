import { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import BotfrontChat from 'components/botfront-chat'
import { useSelector } from 'react-redux'
import { useMutation } from 'redux-query-react'
import { ProfileState } from 'redux/profile/types'
import { Language } from 'redux/settings/types'
import { getAllCustomers, getSelfCustomer } from 'services/queries-typed'

import Styles from './styles.js'

interface Props {
  user: ProfileState
}

export const AuditChatScreen: React.FC<Props> = ({ user }) => {
  const customer = useSelector((state) => state.entities.customer)
  const permissions = useSelector((state) => state.profile.permissions)
  const sendPollingRequest = customer?.status === 'created'
  const [sessionKey, setSessionKey] = useState(Date.now())

  const [{ isPending: isGettingSelectedCustomer }, getSelectedCustomer] =
    useMutation(getAllCustomers)
  const [{ isPending: isGettingCustomer }, getCustomer] =
    useMutation(getSelfCustomer)

  useEffect(() => {
    localStorage.removeItem('chat_session')
    setSessionKey(Date.now())
    return () => localStorage.removeItem('chat_session')
  }, [customer?.id])

  useEffect(() => {
    if (
      sendPollingRequest &&
      !isGettingSelectedCustomer &&
      !isGettingCustomer
    ) {
      const refreshTimeout = setTimeout(() => {
        if (permissions.includes('weissbier:list:customer') && customer) {
          getSelectedCustomer(customer.id)
        } else {
          getCustomer()
        }
      }, 1000)
      return () => {
        clearTimeout(refreshTimeout)
      }
    } else {
      return
    }
  }, [
    sendPollingRequest,
    isGettingSelectedCustomer,
    isGettingCustomer,
    getSelectedCustomer,
    getCustomer,
    customer,
    permissions,
  ])

  return (
    <Grid container style={Styles.header}>
      <Grid item xs={2} />
      <Grid item xs={8} style={Styles.message}>
        <BotfrontChat
          key={sessionKey}
          intent="start_main_form"
          payload={{ user_id: user.userId, customer_id: customer?.id }}
          customData={{ language: Language.DE }}
        />
      </Grid>
      <Grid item xs={2} />
    </Grid>
  )
}

export default AuditChatScreen
