import { useEffect } from 'react'

import { PageHeader } from 'containers/page-headers'
import routes, { CustomerRouteParams } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { addUserlikeScript } from 'services/userlike'

import Import from './import'
import List from './list'

export const FacilityListPage = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { customerId } = useParams<CustomerRouteParams>()
  const customer = useSelector((state) => state.entities.customer)
  const permissions = useSelector((state) => state.profile.permissions)

  const handleGoToCSVUpload = () => {
    if (customer) history.push(routes.divisionsUpload(customerId))
  }
  useEffect(() => {
    addUserlikeScript()
  }, [])

  return (
    <PageHeader
      title={t('facilities.title')}
      buttonTitle={t('facilities.add')}
      onButtonClick={handleGoToCSVUpload}
      displayButton={permissions.includes(
        'weissbier:create:facilities_batch_update'
      )}
    >
      {customer && <List customer={customer} />}
    </PageHeader>
  )
}

export const FacilityImportPage = () => {
  const { t } = useTranslation()
  const { customerId } = useParams<CustomerRouteParams>()

  useEffect(() => {
    addUserlikeScript()
  }, [])

  return (
    <PageHeader
      title={t('facilities.add')}
      breadcrumbs={[
        { name: t('facilities.title'), path: routes.divisions(customerId) },
      ]}
    >
      <Import />
    </PageHeader>
  )
}
