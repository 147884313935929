import { Box, Button, Grid } from '@mui/material'
import { isNonEmpty } from 'lib'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Division, Measure, RiskGroup, RiskLevel } from 'services/model'
import theme from 'themes/theme'
import {
  FormDatePicker,
  FormSelectField,
  FormTextfield,
  SelectItemProps,
} from './FormFields'

export interface Props {
  measure?: Measure
  divisions: Division[]
  hazardGroups: RiskGroup[]
  editMode: boolean
  fullEditRights: boolean
  onSubmit: (attributes: Partial<MeasureInfoProps>) => void
  onClose: () => void
}

export interface MeasureInfoProps {
  name: string | undefined
  hazard: string | undefined
  hazard_description: string | undefined
  division: string | number
  risk_level: RiskLevel
  source: string | null
  due_date: string | Date | null
  hazard_group: string | undefined
}

export const MeasureInformationForm: React.FC<Props> = ({
  measure = {
    id: null,
    name: '',
    risk_level: RiskLevel.LOW,
    due_date: new Date(),
    facility: { id: '' },
    source: '',
    risk_group: { id: '', name: '' },
    hazard: '',
    hazard_description: '',
  },
  editMode,
  fullEditRights,
  divisions,
  hazardGroups,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation()
  const tf = (field: keyof MeasureInfoProps) => t(`measure.info.${field}.label`)
  const {
    name,
    hazard,
    hazard_description,
    risk_level,
    source,
    due_date,
    risk_group,
    facility,
  } = measure

  const defaultValues = {
    name,
    risk_level,
    due_date,
    division: facility.id,
    source,
    hazard_group: risk_group.id,
    hazard,
    hazard_description,
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<Partial<MeasureInfoProps>>({ defaultValues })

  useEffect(() => {
    if (measure.id) {
      reset(defaultValues)
    }
  }, [measure])

  const locations: SelectItemProps[] = divisions.map(
    ({ id, location, operational_area }: Division) => ({
      key: id,
      label: `${location} ${operational_area}`,
    })
  )

  const hazard_groups: SelectItemProps[] = hazardGroups.map(
    ({ id, name }: RiskGroup) => ({
      key: id,
      label: name,
    })
  )

  // If role does not have permission weissbier:list:risk_group, default to the single risk_group item returned by measure:
  const hazardGroupItems = hazard_groups.length
    ? hazard_groups
    : [{ key: risk_group.id, label: risk_group.name }]

  const risk_levels: SelectItemProps[] = ['1', '2', '3'].map((level) => ({
    key: level,
    label: t(`measure.info.risk_level.${level}`),
  }))

  const handleClose = () => {
    reset(defaultValues)
    onClose()
  }

  const onFormSubmit = (attributes: Partial<MeasureInfoProps>) => {
    onSubmit(attributes)
    reset()
  }

  return (
    <Box
      component="form"
      sx={{ backgroundColor: theme.palette.background.info }}
      padding={4}
      onSubmit={handleSubmit(onFormSubmit)}
      noValidate
      data-testid="measure-info-form"
    >
      <Grid container style={{ margin: '16px 0' }}>
        <Grid item xs={7} padding={1}>
          <FormTextfield
            name={'name'}
            control={control}
            editMode={editMode && fullEditRights}
            label={tf('name')}
            rules={{ validate: isNonEmpty(tf('name')) }}
          />
        </Grid>
        <Grid item xs={5} padding={1}>
          <FormSelectField
            name={'risk_level'}
            items={risk_levels}
            control={control}
            editMode={editMode && fullEditRights}
            label={tf('risk_level')}
          />
        </Grid>
      </Grid>
      <Grid container style={{ margin: '16px 0' }}>
        <Grid item xs={7} padding={1}>
          <FormSelectField
            name={'division'}
            items={locations}
            control={control}
            editMode={editMode && fullEditRights}
            label={tf('division')}
            rules={{ validate: isNonEmpty(tf('division')) }}
          />
        </Grid>
        <Grid item xs={5} padding={1}>
          <FormDatePicker
            name={'due_date'}
            control={control}
            editMode={editMode}
            label={tf('due_date')}
          />
        </Grid>
      </Grid>
      <Grid container style={{ margin: '16px 0' }}>
        <Grid item xs={7} padding={1}>
          <FormSelectField
            name={'hazard_group'}
            items={hazardGroupItems}
            control={control}
            editMode={editMode && fullEditRights}
            label={tf('hazard_group')}
            rules={{ validate: isNonEmpty(tf('hazard_group')) }}
          />
        </Grid>
        <Grid item xs={5} padding={1}>
          <FormTextfield
            name={'source'}
            control={control}
            editMode={editMode && fullEditRights}
            label={tf('source')}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ margin: '16px 0' }} padding={1}>
        <FormTextfield
          name={'hazard'}
          control={control}
          editMode={editMode && fullEditRights}
          label={tf('hazard')}
          rules={{ validate: isNonEmpty(tf('hazard')) }}
          multiline={true}
        />
      </Grid>
      <Grid container item xs={12} style={{ margin: '16px 0' }} padding={1}>
        <FormTextfield
          name={'hazard_description'}
          control={control}
          editMode={editMode && fullEditRights}
          label={tf('hazard_description')}
          rules={{ validate: isNonEmpty(tf('hazard_description')) }}
          multiline={true}
          rows={5}
        />
      </Grid>
      {editMode && (
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleClose}
              className="outlined-form-btn"
              data-testid="cancel-measure-info-edit-btn"
            >
              {t('global.buttons.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              key="preventDoubleSubmit"
              className="outlined-form-btn"
              disabled={!isDirty}
              data-testid="publish-measure-info-btn"
            >
              {t('measure.details.publish')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
