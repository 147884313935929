import { Card, CardContent, Grid, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { makeStyles } from 'tss-react/mui'
import { displayFormattedDateTime } from 'lib/helpers'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { CSVMeta, EmployeesCSVMeta } from 'services/model'
import { theme } from 'themes'

export interface Props {
  csvMeta: EmployeesCSVMeta
  file: File
  title: string
}

interface RowProps {
  title: string
  count?: number | string
}

const useStyles = makeStyles()(() => ({
  cardContent: {
    margin: '0.5rem 1rem',
  },
  success: {
    color: theme.palette.success.main,
  },
  width: {
    width: '12rem',
  },
  icon: {
    position: 'relative',
    top: '0.2rem',
    left: '1rem',
    color: theme.palette.success.main,
  },
}))

const UpdateRow: React.FC<RowProps> = ({ title, count }) => {
  const { classes } = useStyles()
  if (count === undefined) {
    return null
  }
  return (
    <Grid container justifyContent="flex-start">
      <Grid item className={classes.width}>
        <Typography variant="body2">{title}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          className={classes.success}
          data-testid="batch-import-success"
        >
          +{count}
        </Typography>
      </Grid>
    </Grid>
  )
}
export const SuccessMessage: React.FC<Props> = ({ title, csvMeta, file }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Typography variant="h2" data-testid="batch-import-success-title">
          {t('import.success.title')}
          <CheckCircleIcon fontSize="small" className={classes.icon} />
        </Typography>
        <Grid container justifyContent="flex-start">
          <Grid item className={classes.width}>
            <Typography variant="body2">{t('import.success.date')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {displayFormattedDateTime(moment().toDate().getTime())}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start">
          <Grid item className={classes.width}>
            <Typography variant="body2">{t('dropzone.file_name')}</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              data-testid="batch-import-success-file-name"
            >
              {file.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start">
          <Grid item className={classes.width}>
            <Typography variant="body2">{t('dropzone.file_size')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{file.size + ' Byte'}</Typography>
          </Grid>
        </Grid>
        <UpdateRow
          title={t('import.success.added', { title })}
          count={csvMeta.added}
        />
        <UpdateRow
          title={t('import.success.updated', { title })}
          count={csvMeta.updated}
        />
        <UpdateRow
          title={t('import.success.activated', { title })}
          count={csvMeta.activated}
        />
        <UpdateRow
          title={t('import.success.deactivated', { title })}
          count={csvMeta.deactivated}
        />
        <UpdateRow
          title={t('import.success.unchanged', { title })}
          count={csvMeta.unchanged}
        />
      </CardContent>
    </Card>
  )
}

export default SuccessMessage
