import { createReducer } from '@reduxjs/toolkit'
import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import { setLanguage } from './actions'
import { Language, SettingsState } from './types'

const INITIAL_STATE: SettingsState = {
  language: Language.DE,
}

export type ProfileActions = ActionType<typeof actions>

const settingsReducer = createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(setLanguage, (state, action) => {
    state.language = action.payload.language
  })
})

export default settingsReducer
