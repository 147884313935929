import { CircularProgress, Divider, Grid, Typography, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
// FIXME: replace with officially support react dropzone once out
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { CsvUploadDataType } from 'containers/csv-import'
import CsvFormatInfo from 'containers/csv-import/format-info'
import { theme } from 'themes'

type UploadType = 'image' | 'document' | 'csv' | 'file'
export interface ContentProps {
  uploadType: UploadType
  files: File[]
  hasFiles: boolean
}

const useContentStyles = makeStyles()(() => ({
  contentContainer: {
    width: '80%',
    margin: '2rem 2rem 0',
  },
  contentContainerCsv: {
    width: '50%',
    margin: '2rem 2rem 0',
  },
  detailsContainer: {
    margin: '2rem 0',
  },
  divider: {
    margin: '0.5rem 0',
  },
}))

const DropzoneContent: React.FC<ContentProps> = ({
  uploadType,
  files,
  hasFiles,
}) => {
  const { t } = useTranslation()
  const { classes: classNames } = useContentStyles()
  return (
    <Box
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ p: 4, border: `2px dashed rgba(0, 0, 0, 0.12)` }}
    >
      {!hasFiles && (
        <>
          <Typography variant="subtitle2">
            {t('dropzone.drag_and_drop')}
          </Typography>
          <Typography variant="subtitle2">{t('dropzone.or')}</Typography>
          <Button
            variant="contained"
            color="secondary"
            data-testid={`dropzone-select-${uploadType}-button`}
          >
            {t(`dropzone.select_${uploadType}`)}
          </Button>
        </>
      )}
      {hasFiles && (
        <>
          <Button
            variant="text"
            color="secondary"
            data-testid={`dropzone-select-${uploadType}-button`}
          >
            {t(`dropzone.select_${uploadType}`)}
          </Button>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            className={
              uploadType === 'csv'
                ? classNames.contentContainerCsv
                : classNames.contentContainer
            }
          >
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6">
                    {t('dropzone.confirm_upload')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classNames.detailsContainer}>
              {files.map((file, i) => (
                <Grid container direction="column" key={i}>
                  <Grid item>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        {files.length === 1
                          ? `${t('dropzone.file_name')}`
                          : `${t('dropzone.file')} #${i + 1}`}
                      </Grid>
                      <Grid item>{file.name}</Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {uploadType !== 'image' && (
                      <Grid container justifyContent="space-between">
                        <Grid item>{t('dropzone.file_size')}</Grid>
                        <Grid item>{file.size + ' Byte'}</Grid>
                      </Grid>
                    )}
                  </Grid>
                  {files.length > 1 && i !== files.length - 1 && (
                    <Grid item>
                      <Divider className={classNames.divider} />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

const getAcceptedFiles = (uploadType: UploadType) => {
  switch (uploadType) {
    case 'image':
      return ['image/jpeg', 'image/png', 'image/bmp']
    case 'document':
      return [
        'text/csv',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/zip',
      ]
    case 'csv':
      return [
        '.csv',
        'text/csv',
        'application/vnd.ms-excel',
        'application/csv',
        'text/x-csv',
        'application/x-csv',
        'text/comma-separated-values',
        'text/x-comma-separated-values',
      ]
    case 'file':
      return [
        'text/csv',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/zip',
      ]
    default:
      return []
  }
}

const useStyles = makeStyles()(() => ({
  actionButton: {
    margin: '1rem 0 1rem 4rem',
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export interface Props {
  onUploadChange: (files: any) => void
  filesLimit: number
  handleUpload: () => void
  cancelUpload: () => void
  uploadType: UploadType
  files: File[]
  isLoading?: boolean
  dataType?: CsvUploadDataType
}

export const Dropzone: React.FC<Props> = ({
  onUploadChange,
  handleUpload,
  filesLimit,
  cancelUpload,
  uploadType,
  files,
  isLoading = false,
  dataType,
  ...rest
}) => {
  const { t } = useTranslation()
  const { classes: classNames } = useStyles()
  const hasFiles = files.length > 0
  const displayCsvFormatInfo = uploadType == 'csv' && dataType && !hasFiles

  const renderDropzoneContent = () => (
    <DropzoneContent
      uploadType={uploadType}
      files={files}
      hasFiles={hasFiles}
    />
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onUploadChange,
    accept: getAcceptedFiles(uploadType),
    // Additional options like 'accept'
  })

  return (
    <>
      <section className="container">
        <div {...getRootProps()}>
          <input {...getInputProps()} data-testid="dropzone-input-area" />
          {renderDropzoneContent()}
          {displayCsvFormatInfo && <CsvFormatInfo dataType={dataType} />}
        </div>
      </section>
      <>
        {hasFiles && (
          <Grid container justifyContent="flex-end" alignItems="center">
            {isLoading ? (
              <Grid item className={`${classNames.actionButton}`}>
                <Button color="secondary" disabled>
                  {t(`dropzone.uploading_file`)}{' '}
                  <CircularProgress size="1.6rem" color="secondary" />
                </Button>
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  className={`${classNames.actionButton} ${classNames.hover}`}
                >
                  <Typography
                    variant="subtitle1"
                    onClick={cancelUpload}
                    data-testid="dropzone-cancel-upload-button"
                  >
                    {t('dropzone.cancel')}
                  </Typography>
                </Grid>
                <Grid item className={classNames.actionButton}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleUpload}
                    data-testid="dropzone-upload-button"
                  >
                    {t(`dropzone.upload_file`)}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </>
    </>
  )
}

export default Dropzone
