const colors = {
  charcoal: 'rgba(33, 32, 32,1)',
  linen: '#FFEFE2',

  silver: '#BCBCBC',
  dimGray: '#636362',
  smoke: '#EAEDEE',
  snow: '#FFFCFA',
  mist: '#FAFAFB',
  white: '#FFFFFF',

  eggplant: '#534E71',
  lightPurple: '#A19BD7',
  sky: '#C4E6F1',

  red: '#e95d5d',
  cherry: '#BA0C2F',
  peach: '#EC9876',
  yellow: '#FCF5A3',
  green: '#24C696',
}

export default colors
