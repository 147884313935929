import { useTheme } from '@mui/material'
import Widget from '@argumed/rasa-webchat'
import { Language } from 'redux/settings/types'
import config from 'services/config'

export interface Props {
  /**
   * Example: `get_started`
   */
  intent: string
  /**
   * Payload parameters passed along the intent
   */
  payload?: Record<string, any>
  socketUrl?: string
  language?: Language
  customData?: Record<string, any>
}
const removeMessageDelay = () => 0

export const BotfrontChat: React.FC<Props> = ({
  intent,
  payload = {},
  socketUrl = config.BOTFRONT_URL,
  language = Language.DE,
  customData,
}) => {
  const theme = useTheme()

  return (
    <Widget
      initPayload={`/${intent}` + JSON.stringify(payload)}
      customData={{ language, ...customData }}
      customMessageDelay={removeMessageDelay}
      socketUrl={socketUrl}
      embedded
      mainColor={theme.palette.primary.main}
      conversationBackgroundColor={theme.palette.background.paper}
      userTextColor={theme.palette.background.paper}
      userBackgroundColor={theme.palette.primary.main}
      assistTextColor={theme.palette.primary.main}
      assistBackgoundColor={theme.palette.background.default}
    />
  )
}

export default BotfrontChat
