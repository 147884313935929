import { useCallback } from 'react'

import { makeStyles } from 'tss-react/mui'
import Description from '@mui/icons-material/Description'
import FolderIcon from '@mui/icons-material/Folder'
import SheetIcon from '@mui/icons-material/GridOn'
import DefaultFile from '@mui/icons-material/InsertDriveFile'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Table } from 'components/Table'
// FIXME: we should not import rootFolderNames from a container
// as it breaks separation of concern. Let's use props instead.
import { rootFolderNames } from 'containers/document-library/utils'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { DocumentType, LibraryDocument, LibraryListItem } from 'services/model'
import { Button, Grid } from '@mui/material'
import { useSelector } from 'react-redux'

export interface Props {
  list: LibraryListItem[]
  loading?: boolean
  onRowClick?: (record: LibraryListItem) => void
  onDeleteDocument?: (record: LibraryDocument) => void
}

interface ObjectNameProps {
  icon: React.ReactNode
  name: string
}

const useStyles = makeStyles()(() => ({
  objectNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  name: { marginLeft: 8 },
}))

const ObjectName: React.FC<ObjectNameProps> = ({ icon, name }) => {
  const { classes: classNames } = useStyles()
  return (
    <div className={classNames.objectNameContainer}>
      {icon}
      <div className={classNames.name}>{name}</div>
    </div>
  )
}

const getFileIcon = (extension: string): JSX.Element => {
  switch (extension) {
    case 'docx':
    case 'doc':
    case 'odt':
    case 'txt':
      return <Description />
    case 'xls':
    case 'xlsx':
      return <SheetIcon />
    case 'pdf':
      return <PictureAsPdfIcon />
    default:
      return <DefaultFile />
  }
}

export const DocumentTable: React.FC<Props> = ({
  list = [],
  onRowClick = null,
  loading = false,
  onDeleteDocument,
}) => {
  const { t } = useTranslation()

  const canDelete = useSelector(
    (state) => state.entities.library?.permissions
  )?.includes('weissbier:delete:library_document')

  const handleRowClick = useCallback(
    (record: any) => {
      return onRowClick && onRowClick(record)
    },
    [onRowClick]
  )

  const handleOnDeleteClick =
    (item: LibraryDocument) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onDeleteDocument && onDeleteDocument(item)
    }

  const columns = [
    {
      label: t('referenceLibrary.name'),
      getter: (item: LibraryListItem) => {
        let icon = <DefaultFile />

        switch (item.type) {
          case DocumentType.FILE:
            const fileParts = item.name.split('.')
            const fileExtension = fileParts[fileParts.length - 1]
            icon = getFileIcon(fileExtension)
            break
          case DocumentType.FOLDER:
            icon = <FolderIcon />
            break
          default:
            break
        }

        let name = ''

        if (rootFolderNames.includes(item.name)) {
          name = t(`documents.folders.${item.name}`)
        } else {
          name = item.name
        }
        return <ObjectName icon={icon} name={name} />
      },
    },
    {
      label: t('referenceLibrary.dateModified'),
      getter: (item: LibraryListItem) =>
        item.type === DocumentType.FILE
          ? moment(item.lastModified).format('lll')
          : '-',
    },
    {
      label: t('referenceLibrary.type'),
      getter: (item: LibraryListItem) =>
        item.type === DocumentType.FILE && canDelete ? (
          <Grid container justifyContent="space-between">
            <Grid item>{item.type}</Grid>
            <Grid item>
              <Button
                variant="text"
                onClick={handleOnDeleteClick(item)}
                data-testid="document-delete-button"
              >
                {t('documents.delete.button')}
              </Button>
            </Grid>
          </Grid>
        ) : (
          item.type
        ),
    },
  ]

  return (
    <Table
      columns={columns}
      onRowClick={handleRowClick}
      list={list}
      count={list.length}
      emptyCopy={t('referenceLibrary.emptyFolder')}
      loading={loading}
    />
  )
}
