import { Grid } from '@mui/material'
import { Typography, Popover, Button } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Arrow from 'components/OnboardingPopoverArrow'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'redux-query-react'
import { goBack, goForward, close } from 'redux/onboarding/actions'
import { editUser } from 'services/queries'
import styled from 'styled-components'
import { theme } from 'themes'

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.warning.main};
  color: ${(props) => props.theme.palette.text.primary};
  padding: 2rem;
  border-radius: 5px;
`

const Text = styled(Typography)`
  padding: 1rem 0;
  width: 200px;
  font-weight: 700;
`

const BackButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.primary.light};
  border-radius: 20px;
  padding: 0.5rem 1rem;
  border: none;
  margin: 2rem 0.5rem 0 0;
  white-space: nowrap;
`

const NextButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.primary.light};
  border-radius: 20px;
  padding: 0.5rem 1rem;
  border: none;
  margin: 2rem 0 0 0.5rem;
  white-space: nowrap;
`

export const OnboardingPopover = ({
  anchorRef,
  title,
  description,
  position,
  lastItem = false,
  stepId,
}) => {
  const currentStep = useSelector((state) => state.onboarding?.currentStep)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [, updateUserStatus] = useMutation((user) => editUser(user))
  const userStatus = useSelector((state) => state.entities.user)
  const isOpen = stepId === currentStep
  const handleUpdateUserStatus = () => {
    let newUser = userStatus
    newUser.status = 'tutorial_completed'
    updateUserStatus(newUser)
  }

  const getAnchorOrigin = () => {
    switch (position) {
      case 'top':
        return {
          vertical: 'top',
          horizontal: 'left',
        }
      case 'bottom':
        return {
          vertical: 'bottom',
          horizontal: 'center',
        }
      case 'left':
        return {
          vertical: 'center',
          horizontal: 'left',
        }
      case 'right':
        return {
          vertical: 'center',
          horizontal: 'right',
        }
      default:
        return {
          vertical: null,
          horizontal: null,
        }
    }
  }

  const getTransformOrigin = () => {
    switch (position) {
      case 'top':
        return {
          vertical: 'bottom',
          horizontal: 'left',
        }
      case 'bottom':
        return {
          vertical: 'top',
          horizontal: 'center',
        }
      case 'left':
        return {
          vertical: 'center',
          horizontal: 'right',
        }
      case 'right':
        return {
          vertical: 'center',
          horizontal: 'left',
        }
      default:
        return {
          vertical: null,
          horizontal: null,
        }
    }
  }

  const handleNext = () => {
    if (lastItem) {
      handleUpdateUserStatus()
    }
    dispatch(goForward())
  }

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorRef?.current}
      onClose={() => dispatch(close())}
      anchorOrigin={getAnchorOrigin()}
      transformOrigin={getTransformOrigin()}
      PaperProps={{
        style: { backgroundColor: 'transparent' },
        elevation: 0,
      }}
      style={{ backgroundColor: 'rgba(33, 32, 32, 0.5', overflow: 'visible' }}
    >
      <Grid container data-testid="onboarding-popover-content">
        {position === 'right' && <Arrow direction="left" />}
        <Grid item>
          {position === 'bottom' && <Arrow direction="up" />}
          <Container>
            <Text variant="button" align="left">
              {t(title)}
            </Text>
            <Text variant="body1" align="left">
              {t(description)}
            </Text>
            <Grid container>
              <Grid item xs={6}>
                <BackButton align="center" onClick={() => dispatch(goBack())}>
                  <ArrowBackIosIcon fontSize={'small'} />
                  {t('onboarding_popover_btn_back')}
                </BackButton>
              </Grid>
              <Grid item xs={6} align="right">
                <NextButton align="center" onClick={handleNext}>
                  {lastItem
                    ? t('onboarding_popover_btn_finish')
                    : t('onboarding_popover_btn_next')}
                  {!lastItem && <ArrowForwardIosIcon fontSize={'small'} />}
                </NextButton>
              </Grid>
            </Grid>
          </Container>
          {position === 'top' && <Arrow direction="down" />}
        </Grid>
        {position === 'left' && <Arrow direction="right" />}
      </Grid>
    </Popover>
  )
}

export default OnboardingPopover
