import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { LDUser } from 'launchdarkly-js-client-sdk'
import withLDConsumer, {
  LDProps,
} from 'launchdarkly-react-client-sdk/lib/withLDConsumer'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'redux-query-react'
import {
  setPermissions,
  setProfile,
  setUserStatus,
} from 'redux/profile/actions'
import { getUser } from 'services/queries'

import LoadingScreen from '../loading'
import Footer from 'components/footer'

interface Props {
  flags: Record<string, any>
  children?: React.ReactNode
}

const AuthLock: React.FC<Props & LDProps> = ({ flags, ldClient, children }) => {
  const {
    isAuthenticated,
    user,
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0()

  const dispatch = useDispatch()

  const [hasToken, setHasToken] = useState(false)
  const userProfile = useSelector((state) => state.profile)
  const userStatus = useSelector(
    (state) => state.entities.user && state.entities.user.status
  )

  const [{ isPending: isFetchingUser }, fetchUser] = useMutation(getUser)

  useEffect(() => {
    if (userProfile?.email && userProfile.userId && ldClient) {
      let ldUser: LDUser = {
        key: userProfile.userId,
      }
      // @ts-ignore
      // Identify user for launchDarkly to enable user-based flag variations (cypress)
      if (window.Cypress) {
        ldUser = {
          key: userProfile.email,
          email: userProfile.email,
          name: userProfile?.name || '',
        }
      }
      ldClient.identify(ldUser)
    }
  }, [userProfile, ldClient])

  useEffect(() => {
    if (hasToken && (userProfile.emailVerified || !flags.emailVerification)) {
      fetchUser()
    }
  }, [hasToken, fetchUser, userProfile, flags])

  useEffect(() => {
    if (!isFetchingUser && userStatus) {
      dispatch(setUserStatus(userStatus))
    }
  }, [userStatus, isFetchingUser, dispatch])

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !user) {
      loginWithRedirect()
    }
  }, [isLoading, isAuthenticated, user, loginWithRedirect])

  useEffect(() => {
    if (isAuthenticated) {
      ;(async () => {
        try {
          const token = await getAccessTokenSilently({
            // @ts-ignore
            ignoreCache: window.Cypress ? false : true,
          })
          if (!!token) {
            const tokens = token.split('.')
            const payload = JSON.parse(atob(tokens[1]))
            dispatch(setPermissions(payload.permissions || []))
            localStorage.setItem('access_token', token)
            setHasToken(true)
          }
        } catch (e: any) {
          console.error(e)
          if (e.error === 'login_required' || e.error === 'consent_required') {
            loginWithRedirect()
          }
        }
      })()
    }
  }, [isAuthenticated, getAccessTokenSilently, dispatch, loginWithRedirect])

  useEffect(() => {
    if (user && typeof user === 'object' && user !== null) {
      dispatch(setProfile(user))
    }
  }, [user, dispatch])

  const showLoading = isLoading || user === undefined
  const showChildren = isAuthenticated && hasToken

  return (
    <>
      {showLoading && <LoadingScreen />}
      {showChildren && children}
      {showChildren && <Footer />}
    </>
  )
}

export default withLDConsumer()(AuthLock)
