import { useCallback, useEffect, useRef, useState } from 'react'

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import UpdateIcon from '@mui/icons-material/Update'
import FilterBar, { FilterParams } from 'components/FilterBar'
import OnboardingPopover from 'components/OnboardingPopover'
import RiskLevelChip from 'components/RiskLevelChip'
import { alphaSortCaseInsensitive } from 'lib'
import { displayFormattedDate } from 'lib/helpers'
import { Link, useHistory } from 'react-router-dom'
import routes, { DivisionRouteParams } from 'navigation/routes'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Entities, QueryConfig } from 'redux-query'
import { useRequests, useMutation } from 'redux-query-react'
import { skipTo } from 'redux/onboarding/actions'
import { Customer, Measure, Order, RiskLevel } from 'services/model'
import { getMeasuresForCustomer, getRiskGroups } from 'services/queries'
import { colors } from 'themes'
import {
  exportMeasures,
  sendCustomerInstructionReminderEmails,
} from 'services/queries-typed'
import { useSnackbar, SnackbarProvider } from 'notistack'

const useStyles = makeStyles()(() => ({
  container: {
    marginTop: '1rem',
    paddingTop: '1rem',
    whiteSpace: 'nowrap',
  },
  textContainer: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
  smallWidth: {
    width: '80px',
  },
  mediumWidth: {
    width: '140px',
  },
  largeWidth: {
    width: '500px',
  },
  maxWidth: {
    maxWidth: '400px',
  },
  statusMessage: {
    padding: '5rem',
    fontSize: '1.2rem',
  },
  overdue: {
    color: colors.red,
  },
  checked: {
    color: colors.green,
  },
  paddingLeft: {
    paddingLeft: '0.2rem',
  },
  icon: {
    position: 'relative',
    top: '0.4rem',
  },
}))

enum MeasureProperty {
  NAME = 'name',
  RISK_LEVEL = 'risk_level',
  DUE_DATE = 'due_date',
  OPERATIONAL_AREA = 'operational_area',
  LOCATION = 'location',
  RESPONSIBLE = 'responsible_name',
  DONE = 'done',
}

interface HeadCell {
  property: MeasureProperty
  comparator: (a: Measure, b: Measure) => number
}

interface Props {
  customerId: Customer['id']
}

export const MeasuresTable: React.FC<Props> = ({ customerId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const history = useHistory()
  const measures = useSelector((state) => state.entities.measures)
  const permissions = useSelector((state) => state.profile.permissions)
  const [, getExport] = useMutation(exportMeasures)
  const [filterParams, setFilterParams] = useState<FilterParams>({})
  const [callFailed, setCallFailed] = useState(false)
  const [measuresArray, setMeasuresArray] = useState<Measure[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const canListCustomers = !!(
    Array.isArray(permissions) &&
    permissions?.includes('weissbier:list:customer')
  )

  const canAddMeasure = !!(
    Array.isArray(permissions) &&
    permissions?.includes('weissbier:create:measure')
  )
  const canListRiskGroup = !!(
    Array.isArray(permissions) &&
    permissions?.includes('weissbier:list:risk_group')
  )

  const canExport = !!(
    Array.isArray(permissions) &&
    permissions?.includes('weissbier:create:export')
  )

  const handleExport = () => {
    getExport(customerId, [])
    enqueueSnackbar(t('measureExportSuccess'), { variant: 'success' })
  }

  const handleAddMeasure = () => {
    history.push(routes.addMeasure(customerId))
  }

  // TODO: check for customer on component render
  let requests: QueryConfig<Entities>[] = []
  if (customerId) {
    requests = canListCustomers
      ? [
          getMeasuresForCustomer(customerId, filterParams, true),
          getRiskGroups(),
        ]
      : [getMeasuresForCustomer(customerId, filterParams, true)]
  }
  const [{ isPending, status }, refresh] = useRequests(requests)

  useEffect(() => {
    if (typeof status === 'number' && (status >= 400 || status === 0)) {
      setCallFailed(true)
    }
  }, [status])

  const currentOnboardingStep = useSelector(
    (state) => state.onboarding?.currentStep
  )
  const onboardingDirection = useSelector(
    (state) => state.onboarding?.direction
  )

  useEffect(() => {
    switch (currentOnboardingStep) {
      case null:
        break
      case 'open-measure-modal':
        if (onboardingDirection === 'backwards') {
          dispatch(skipTo('safety-measure'))
        } else {
          dispatch(skipTo('reference-library'))
        }
        break
      default:
    }
  }, [currentOnboardingStep, dispatch, onboardingDirection, measuresArray])

  useEffect(() => {
    if (measures) {
      setMeasuresArray(measures)
    }
    setOrder(Order.ASCENDING)
    setOrderBy(MeasureProperty.DONE)
  }, [measures])

  const isOverdue = (measure: Measure) => {
    const date =
      measure && measure.due_date && moment(measure.due_date, 'YYYY-MM-DD')
    if (date && !date.isAfter(moment())) return true
    return false
  }

  const [order, setOrder] = useState<Order>(Order.ASCENDING)
  const [orderBy, setOrderBy] = useState<MeasureProperty>(MeasureProperty.DONE)

  const handleRequestSort = (
    property: MeasureProperty,
    comparator: (a: Measure, b: Measure) => number
  ) => {
    const isAsc = orderBy === property && order === Order.ASCENDING
    setOrder(isAsc ? Order.DESCENDING : Order.ASCENDING)
    setOrderBy(property)

    const sortedMeasuresArray = measuresArray.sort(comparator)

    if (isAsc) {
      sortedMeasuresArray.reverse()
    }

    setMeasuresArray(sortedMeasuresArray)
  }

  const handleSortColumn = (headCell: HeadCell) => () =>
    handleRequestSort(headCell.property, headCell.comparator)

  const headCells = [
    {
      property: MeasureProperty.NAME,
      comparator: (mA: Measure, mB: Measure) =>
        alphaSortCaseInsensitive(mA.name, mB.name),
    },
    {
      property: MeasureProperty.RISK_LEVEL,
      comparator: (mA: Measure, mB: Measure) => {
        const riskLevelMapping = {
          [RiskLevel.HIGH]: 3,
          [RiskLevel.MEDIUM]: 2,
          [RiskLevel.LOW]: 1,
        }

        return riskLevelMapping[mA.risk_level] - riskLevelMapping[mB.risk_level]
      },
    },
    {
      property: MeasureProperty.DUE_DATE,
      comparator: (mA: Measure, mB: Measure) =>
        alphaSortCaseInsensitive(mA.due_date, mB.due_date),
    },
    {
      property: MeasureProperty.LOCATION,
      comparator: (mA: Measure, mB: Measure) =>
        alphaSortCaseInsensitive(mA.facility.location, mB.facility.location),
    },
    {
      property: MeasureProperty.OPERATIONAL_AREA,
      comparator: (mA: Measure, mB: Measure) =>
        alphaSortCaseInsensitive(
          mA.facility.operational_area,
          mB.facility.operational_area
        ),
    },
    {
      property: MeasureProperty.RESPONSIBLE,
      comparator: (mA: Measure, mB: Measure) =>
        alphaSortCaseInsensitive(mA.responsible_name, mB.responsible_name),
    },
  ]

  const handleFilter = (params: FilterParams) => {
    setFilterParams(params)
    refresh()
  }

  const firstMeasureRef = useRef(null)

  return (
    <div className={classes.container}>
      {customerId && (
        <FilterBar
          key={customerId}
          onFilter={handleFilter}
          customerId={customerId}
        />
      )}
      <br />
      <SnackbarProvider>
        <TableContainer component={Paper} elevation={0}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.property}
                    sortDirection={
                      orderBy === headCell.property ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === headCell.property}
                      direction={
                        orderBy === headCell.property ? order : Order.ASCENDING
                      }
                      onClick={handleSortColumn(headCell)}
                    >
                      {t(`measuresScreen_${headCell.property}`)}
                    </TableSortLabel>
                    {headCell.property === 'name' &&
                      canAddMeasure &&
                      canListRiskGroup && (
                        <Button
                          onClick={handleAddMeasure}
                          variant="contained"
                          color="primary"
                          data-testid="measure-dashboard-add-measure-btn"
                        >
                          + {t('measuresScreen_add_measure')}
                        </Button>
                      )}
                    {headCell.property === 'name' && canExport && (
                      <Button
                        sx={{ ml: 0.5 }}
                        onClick={handleExport}
                        variant="contained"
                        color="primary"
                      >
                        {t('measuresExport')}
                      </Button>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(isPending || callFailed) && (
                <TableRow>
                  <TableCell align="center" colSpan={9}>
                    {isPending ? (
                      <>
                        <Typography
                          align="center"
                          className={classes.statusMessage}
                        >
                          {t('measuresScreen_loading')}
                        </Typography>
                        <CircularProgress />
                      </>
                    ) : (
                      <Typography
                        align="center"
                        className={classes.statusMessage}
                      >
                        {t('measuresScreen_callFailed')}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {!isPending && !callFailed && measuresArray.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={9}>
                    <Typography
                      align="center"
                      className={classes.statusMessage}
                    >
                      {t('measuresScreen_no_measures')}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {!callFailed &&
                measuresArray.map((m, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row" title={m.name}>
                      <Grid container spacing={3}>
                        <Grid
                          item
                          xs={12}
                          ref={i === 1 ? firstMeasureRef : null}
                        >
                          <div
                            className={`${classes.textContainer} ${classes.largeWidth}`}
                          >
                            {m.done ? (
                              <CheckCircleIcon
                                className={`${classes.icon} ${classes.checked}`}
                              />
                            ) : (
                              <RadioButtonUncheckedIcon
                                className={classes.icon}
                              />
                            )}
                            <Link
                              to={routes.measureDetails(customerId, m.id)}
                              style={{
                                textDecoration: 'none',
                              }}
                              component={Button}
                              data-testid={`go-to-measure-details-btn-${m.id}`}
                            >
                              <div
                                className={`${classes.textContainer} ${classes.maxWidth}`}
                              >
                                {m.name}
                              </div>{' '}
                              <ChevronRightIcon />
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell
                      title={
                        m.risk_level
                          ? t(`measuresScreen_risk_${m.risk_level}`)
                          : undefined
                      }
                      align="left"
                    >
                      <RiskLevelChip measure={m} isModalView={false} />
                    </TableCell>
                    <TableCell
                      title={m.due_date ? m.due_date : undefined}
                      align="left"
                    >
                      {m.due_date && (
                        <span
                          className={
                            isOverdue(m) && !m.done
                              ? classes.overdue
                              : undefined
                          }
                        >
                          {displayFormattedDate(m.due_date)}
                          {isOverdue(m) && !m.done && (
                            <UpdateIcon
                              className={`${classes.icon} ${classes.overdue} ${classes.paddingLeft}`}
                            />
                          )}
                        </span>
                      )}
                    </TableCell>
                    <TableCell title={m.facility.location} align="left">
                      <div
                        className={`${classes.textContainer} ${classes.smallWidth}`}
                      >
                        {m.facility.location}
                      </div>
                    </TableCell>
                    <TableCell title={m.facility.operational_area} align="left">
                      <div
                        className={`${classes.textContainer} ${classes.smallWidth}`}
                      >
                        {m.facility.operational_area}
                      </div>
                    </TableCell>
                    <TableCell
                      title={
                        m.responsible_name ? m.responsible_name : undefined
                      }
                      align="left"
                    >
                      <div
                        className={`${classes.textContainer} ${classes.mediumWidth}`}
                      >
                        {m.responsible_name}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SnackbarProvider>
      <OnboardingPopover
        stepId="safety-measure"
        anchorRef={firstMeasureRef}
        title="onboarding_popover_measure_row_title"
        description="onboarding_popover_measure_row_body"
        position="top"
      />
    </div>
  )
}
export default MeasuresTable
