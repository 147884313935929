import { Avatar, Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { fonts, theme } from 'themes'
import { argumedSealDE, argumedSealEN, sbk } from 'themes/images'

import { AuditTypes } from '.'
import { useSelector } from 'react-redux'
import { Language } from 'redux/settings/types'

const useStyles = makeStyles()(() => ({
  banner: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    minHeight: '12rem',
  },
  headline: {
    fontFamily: fonts.base,
    margin: '1rem 0',
  },
  text: {
    margin: '0 0 2rem',
  },
  imageAvatar: {
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  imageSBK: {
    width: theme.spacing(20),
  },
  imageContainer: {
    paddingLeft: '3rem',
  },
}))

export interface Props {
  type: AuditTypes
  title: string
  body: string
}

export const ChatbotBanner: React.FC<Props> = ({ type, title, body }) => {
  const { classes } = useStyles()
  const language = useSelector((state) => state.settings.language)

  return (
    <Grid container alignItems="center" className={classes.banner}>
      <Grid item xs={2} />
      <Grid item xs={6}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              variant="h1"
              color={theme.palette.text.contrast}
              className={classes.headline}
              align="left"
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              color={theme.palette.text.contrast}
              className={classes.text}
              align="left"
            >
              {body}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <div className={classes.imageContainer}>
          {type === AuditTypes.SBK ? (
            <img className={classes.imageSBK} alt={'SBK Logo'} src={sbk} />
          ) : (
            <Avatar
              className={classes.imageAvatar}
              alt={language == Language.DE ? 'Argumed Siegel' : 'Argumed Seal'}
              src={language == Language.DE ? argumedSealDE : argumedSealEN}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  )
}

export default ChatbotBanner
