import { useEffect } from 'react'

import { CircularProgress } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { addUserlikeScript } from 'services/userlike'
import styled from 'styled-components'

import AuditChatScreen from '../audit-chat-screen'

const StyledSpinner = styled(Typography).attrs({ variant: 'h5' })`
  text-align: center;
  margin: 7% auto;
`
const StyledTypography = styled(Typography).attrs({
  variant: 'h6',
  align: 'center',
})`
  width: 50%;
  margin: 0 auto;
`
export const OnboardingScreen = ({ user, auditCompleted }) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (!!auditCompleted) {
      addUserlikeScript()
    }
  }, [auditCompleted])

  return (
    <>
      {!auditCompleted && <AuditChatScreen user={user} />}
      {auditCompleted && (
        <>
          <StyledSpinner>
            <CircularProgress size="5rem" />
          </StyledSpinner>
          <StyledTypography>
            {t('onboarding_finished_audit_message')}
          </StyledTypography>
        </>
      )}
    </>
  )
}

export default OnboardingScreen
