import { AppBar, Grid, Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import LanguageSwitch from 'components/LanguageSwitch'
import OrgLogo from 'components/OrgLogo'
import { colors, theme } from 'themes'

const useStyles = makeStyles()(() => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 2rem',
    backgroundColor: theme.palette.primary.main,
    height: '4.5rem',
  },
}))

export const UnauthAppBar: React.FC = () => {
  const { classes } = useStyles()

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Paper
        elevation={0}
        className={classes.container}
        sx={{
          minWidth: { xs: 300 },
        }}
      >
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={2} md={1} lg={1} xl={1}>
            <OrgLogo color={colors.linen} />
          </Grid>
          <Grid item xs={10} md={11} lg={11} xl={11}>
            <LanguageSwitch data-testid="language-switch" />
          </Grid>
        </Grid>
      </Paper>
    </AppBar>
  )
}

export default UnauthAppBar
