import { useEffect, useState } from 'react'

import { ConfirmationModal } from 'components/ConfirmationModal'
import { PageHeader } from 'containers/page-headers'
import routes, { AdminRouteParams } from 'navigation/routes'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from 'redux-query-react'
import { deleteOhsAdmin, getOhsAdmin } from 'services/queries-typed'
import { addUserlikeScript } from 'services/userlike'

import List from './list'

export const AdminListPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const permissions = useSelector((state) => state.profile.permissions)
  const isAdmin = permissions.includes('weissbier:create:admin')

  const handleGoToCreateAdmin = () => {
    history.push(routes.addAdmin())
  }
  useEffect(() => {
    addUserlikeScript()
  }, [])

  return (
    <PageHeader
      title={t('admins.title')}
      buttonTitle={t('admins.add')}
      onButtonClick={handleGoToCreateAdmin}
      displayButton={isAdmin}
    >
      {isAdmin && <List />}
    </PageHeader>
  )
}

export const AdminProfilePage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { userId } = useParams<AdminRouteParams>()
  const admin = useSelector((state) => state.entities.admin)
  const canDeleteAdmin = useSelector((state) =>
    state.profile.permissions.includes('weissbier:delete:admin')
  )

  const [{ isPending }, fetchAdmin] = useMutation(getOhsAdmin)
  const [{ isPending: isDeletingAdmin }, removeAdmin] =
    useMutation(deleteOhsAdmin)
  const [userTitle, setUserTitle] = useState('')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (userId) {
      fetchAdmin(userId)
    }
  }, [userId, fetchAdmin])

  useEffect(() => {
    addUserlikeScript()
  }, [])

  useEffect(() => {
    if (admin) {
      if (admin.first_name) {
        setUserTitle(`${admin.first_name} ${admin.last_name}`)
      } else if (admin.email) {
        setUserTitle(admin.email)
      } else {
        setUserTitle(admin.id)
      }
    }
  }, [admin])

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleOnDelete = () => {
    setOpen(false)

    removeAdmin(userId)
      ?.then((response) => {
        if (response.status === 204) {
          history.replace(routes.admins())
          enqueueSnackbar(t('admins.delete_admin.success'), {
            variant: 'success',
          })
        } else {
          enqueueSnackbar(t('admins.delete_admin.error'), { variant: 'error' })
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' })
      })
  }

  return (
    <>
      {!isPending && !isDeletingAdmin && (
        <>
          <PageHeader
            title={userTitle}
            infoTitle={t(`admins.admin`)}
            secondaryTitle={admin?.email}
            breadcrumbs={[{ name: t('admins.title'), path: routes.admins() }]}
            buttonTitle={t('admins.delete')}
            onButtonClick={handleOpenModal}
            dangerButton={true}
            displayButton={canDeleteAdmin}
          >
            <ConfirmationModal
              bodyCopy={t('admins.delete_modal_body', { name: userTitle })}
              confirmButtonCopy={t('admins.delete_confirm')}
              open={open}
              onClose={handleCloseModal}
              onConfirm={handleOnDelete}
            />
          </PageHeader>
        </>
      )}
    </>
  )
}
