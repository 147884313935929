import { useCallback, useEffect, useState } from 'react'

import { ConfirmationModal } from 'components/ConfirmationModal'
import { PageHeader } from 'containers/page-headers'
import routes, { DivisionRouteParams } from 'navigation/routes'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from 'redux-query-react'
import {
  assignInstructionDivision,
  deleteFacility,
  getActiveDivisionInstructions,
  getExpiredDivisionInstructions,
  getFacility,
} from 'services/queries-typed'
import { addUserlikeScript } from 'services/userlike'
import { Column, Table } from 'components/Table'
import {
  AssignedDivisionInstruction,
  AssignedDivisionInstructions,
  DivisionInstruction,
  Order,
} from 'services/model'
import { Button, Divider, Grid, Typography } from '@mui/material'
import moment from 'moment'

enum InstructionStatus {
  ACTIVE = 'active',
  EXPIRED = 'expired',
}

interface TableProps {
  instructions: AssignedDivisionInstructions | undefined
  status: InstructionStatus
  onPageChange: (
    limit: number,
    offset: number,
    sortBy: string | undefined,
    order: Order | undefined
  ) => void
  onInstructionRenewal?: (
    id: AssignedDivisionInstruction['instruction_id']
  ) => void
  isLoading: boolean
  canRenewInstruction?: boolean
}

export const FacilityProfilePage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { customerId, divisionId } = useParams<DivisionRouteParams>()

  const [{ isPending }, fetchFacility] = useMutation(getFacility)
  const [{ isPending: isFetchingActive }, fetchActiveInstructions] =
    useMutation(getActiveDivisionInstructions)
  const [{ isPending: isFetchingExpired }, fetchExpiredInstructions] =
    useMutation(getExpiredDivisionInstructions)
  const [{ isPending: isDeleting }, removeFacility] =
    useMutation(deleteFacility)
  const [{ isPending: isRenewing }, assignInstruction] = useMutation(
    assignInstructionDivision
  )
  const [divisionTitle, setDivisionTitle] = useState<string | null>(null)
  const [open, setOpen] = useState(false)
  const [openRenewalModal, setOpenRenewalModal] = useState(false)
  const [disableDelete, setDisableDelete] = useState(false)
  const [disableRenewal, setDisableRenewal] = useState(false)
  const [instructionId, setInstructionId] = useState<
    DivisionInstruction['instruction_id'] | null
  >(null)
  const language = useSelector((state) => state.settings.language)
  const division = useSelector((state) => state.entities.division)
  const activeInstructions = useSelector(
    (state) => state.entities.activeDivisionInstructions
  )
  const expiredInstructions = useSelector(
    (state) => state.entities.expiredDivisionInstructions
  )
  const displayDelete = useSelector((state) =>
    state.profile.permissions.includes('weissbier:delete:facility')
  )
  const canRenewInstruction = useSelector((state) =>
    state.profile.permissions.includes(
      'weissbier:create:facility_instruction_renewal'
    )
  )

  useEffect(() => {
    if (customerId && divisionId) {
      fetchFacility(customerId, Number(divisionId))
    }
  }, [divisionId, customerId, fetchFacility])

  useEffect(() => {
    addUserlikeScript()
  }, [])

  useEffect(() => {
    if (division) {
      setDivisionTitle(`${t('facility')} #${division.id}`)
    }
  }, [division, t])

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setOpenRenewalModal(false)
    setDisableDelete(false)
    setDisableRenewal(false)
    setInstructionId(null)
  }

  const handleOnDelete = () => {
    removeFacility(customerId, Number(divisionId))
      ?.then((response) => {
        if (response.status === 204) {
          history.replace(routes.divisions(customerId))
        } else if (response.status === 409) {
          setDisableDelete(true)
        } else {
          enqueueSnackbar(t('facilities.delete_error_general'), {
            variant: 'error',
          })
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' })
      })
  }

  const handleGetActiveInstructions = useCallback(
    (
      limit: number,
      offset: number,
      sortBy: string | undefined,
      order: Order | undefined
    ) => {
      if (customerId && divisionId) {
        fetchActiveInstructions(
          customerId,
          Number(divisionId),
          limit,
          offset,
          sortBy,
          order,
          language
        )
      }
    },
    [fetchActiveInstructions, customerId, divisionId, language]
  )

  const handleGetExpiredInstructions = useCallback(
    (
      limit: number,
      offset: number,
      sortBy: string | undefined,
      order: Order | undefined
    ) => {
      if (customerId && divisionId) {
        fetchExpiredInstructions(
          customerId,
          Number(divisionId),
          limit,
          offset,
          sortBy,
          order,
          language
        )
      }
    },
    [fetchExpiredInstructions, customerId, divisionId, language]
  )

  const handleInstructionRenewal = (
    id: AssignedDivisionInstruction['instruction_id']
  ) => {
    setInstructionId(id)
    setOpenRenewalModal(true)
  }

  const renewInstruction = () => {
    if (customerId && divisionId && instructionId) {
      assignInstruction(customerId, instructionId, [Number(divisionId)])
        ?.then((response) => {
          if (response.status < 400) {
            handleCloseModal()
            enqueueSnackbar(t('facilities.instruction_renewal_success'), {
              variant: 'success',
            })
          } else if (response.status === 409) {
            setDisableRenewal(true)
          } else {
            handleCloseModal()
            enqueueSnackbar(t('facilities.delete_error_general'), {
              variant: 'error',
            })
          }
        })
        .catch((error) => {
          handleCloseModal()
          enqueueSnackbar(error, { variant: 'error' })
        })
    }
  }

  return (
    <>
      {!isPending && !isDeleting && (
        <>
          <PageHeader
            title={divisionTitle}
            secondaryTitle={`${division?.location} · ${division?.operational_area}`}
            breadcrumbs={[
              {
                name: t('facilities.title'),
                path: routes.divisions(customerId),
              },
            ]}
            buttonTitle={t('facilities.delete')}
            onButtonClick={handleOpenModal}
            dangerButton={true}
            displayButton={displayDelete}
          >
            <ConfirmationModal
              bodyCopy={t('facilities.delete_modal_body')}
              confirmButtonCopy={t('facilities.delete_confirm')}
              open={open}
              onClose={handleCloseModal}
              onConfirm={handleOnDelete}
              disabled={disableDelete}
              errorMessage={t('facilities.delete_error')}
            />
            <ConfirmationModal
              bodyCopy={t('facilities.renewal_modal_body')}
              confirmButtonCopy={t('facilities.instruction_renewal_confirm')}
              open={openRenewalModal}
              onClose={handleCloseModal}
              onConfirm={renewInstruction}
              disabled={disableRenewal}
              errorMessage={t('facilities.instruction_renewal_error')}
            />
            <Divider sx={{ mb: 4 }} />
            <InstructionsTable
              instructions={activeInstructions}
              status={InstructionStatus.ACTIVE}
              onPageChange={handleGetActiveInstructions}
              isLoading={isFetchingActive || isRenewing}
            />
            <Divider sx={{ mt: 4, mb: 4 }} />
            <InstructionsTable
              instructions={expiredInstructions}
              status={InstructionStatus.EXPIRED}
              onPageChange={handleGetExpiredInstructions}
              onInstructionRenewal={handleInstructionRenewal}
              isLoading={isFetchingExpired || isRenewing}
              canRenewInstruction={canRenewInstruction}
            />
          </PageHeader>
        </>
      )}
    </>
  )
}

export const InstructionsTable: React.FC<TableProps> = ({
  instructions,
  status,
  onPageChange,
  isLoading,
  onInstructionRenewal,
  canRenewInstruction,
}) => {
  const { t } = useTranslation()

  const handleOnInstructionRenewalClick =
    (id: AssignedDivisionInstruction['instruction_id']) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onInstructionRenewal && onInstructionRenewal(id)
    }

  const columns: Column[] = [
    {
      label: t('instruction'),
      getter: (instruction: AssignedDivisionInstruction) =>
        instruction.instruction_name,
      sortKey: 'instruction_name',
    },
    {
      label: t('expires_at'),
      getter: (instruction: AssignedDivisionInstruction) =>
        moment(instruction.expires_at).format('L'),
      sortKey: 'expires_at',
    },
  ]

  if (status == InstructionStatus.EXPIRED && canRenewInstruction) {
    columns.push({
      label: '',
      getter: (instruction: AssignedDivisionInstruction) => (
        <Button
          variant="text"
          color="secondary"
          onClick={handleOnInstructionRenewalClick(instruction.instruction_id)}
          data-testid="renew-instruction-button"
        >
          {t('facilities.renew_instruction')}
        </Button>
      ),
    })
  }

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography variant="h6">
          {t(`facilities.${status}_instructions`)}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Table
          key={`${status}-division-instructions`}
          list={instructions?.list || []}
          count={instructions?.meta.total_items || 0}
          columns={columns}
          emptyCopy={t(`facilities.no_${status}_instructions`)}
          onPageChange={onPageChange}
          loading={isLoading}
          tableLabel={`${status}-division-instructions`}
        />
      </Grid>
    </Grid>
  )
}
