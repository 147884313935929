import { GO_FORWARD, GO_BACK, SKIP_TO, CLOSE } from './actionTypes'

const steps = [
  'start',
  'compliance-status',
  'safety-measure',
  'open-measure-modal',
  'reference-library',
  'asa-meeting',
  'contact-expert',
  'end',
]

const initialState = {
  currentStep: steps[0],
  currentIndex: 0,
  direction: 'forwards',
}

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GO_FORWARD: {
      if (state.currentIndex >= steps.length - 1) return state
      return {
        ...state,
        currentIndex: state.currentIndex + 1,
        currentStep: steps[state.currentIndex + 1],
        direction: 'forwards',
      }
    }
    case GO_BACK: {
      if (state.currentIndex <= 0) return state
      return {
        ...state,
        currentIndex: state.currentIndex - 1,
        currentStep: steps[state.currentIndex - 1],
        direction: 'backwards',
      }
    }
    case SKIP_TO:
      if (!steps.includes(action.payload.step)) return state
      const stepIndex = steps.indexOf(action.payload.step)
      return {
        ...state,
        currentIndex: stepIndex,
        currentStep: action.payload.step,
        direction: stepIndex < state.currentIndex ? 'backwards' : 'forwards',
      }
    case CLOSE:
      return {
        ...state,
        currentIndex: -1,
        currentStep: null,
      }
    default:
      return state
  }
}

export default onboardingReducer
