import { useEffect, useState } from 'react'

import { Breadcrumb } from 'containers/page-headers'
import routes from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { Customer } from 'services/model'

/**
 * Hook that provides translated breadcrumbs for the document library
 * @param customerId
 * @param folderPath
 * @returns `breadcrumbs`
 */

export const useBreadcrumbs = (customerId: string, folderPath: string) => {
  const { t } = useTranslation()
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])

  useEffect(() => {
    const constructedBreadcrumbs = constructBreadcrumbs(
      customerId,
      folderPath
    ).map((crumb) => {
      if (crumb.name === 'reference_library') {
        crumb.name = t(`documents.reference_library`)
      } else if (rootFolderNames.includes(crumb.name)) {
        crumb.name = t(`documents.folders.${crumb.name}`)
      }
      return crumb
    })
    setBreadcrumbs(constructedBreadcrumbs)
  }, [customerId, folderPath, t])

  return breadcrumbs
}

export const getTopLevelPrefix = (customerId: string) =>
  `library/customers/${customerId}/`

export const rootFolderNames = [
  'Forms & Notices',
  'Instructions',
  'Legal Texts',
  'Occupational Medicine',
  'Operational Instructions',
  'Protocols',
  'Psychological Risk Assessment',
  'Q & A',
  'Risk Assessment & Checklists',
]

export const constructBreadcrumbs = (
  customerId: Customer['id'],
  folderPath: string
) => {
  if (!folderPath) return []
  let list = [
    {
      name: 'reference_library', // To be translated inside the component
      path: routes.library(customerId),
    },
  ]
  // ex: "library/customers/c4057a25-6fe6-4082-ab83-71560dd50ab1/Occupational Medicine/BEM Prozess komplette Unterlagen /"
  const decodedPath = decodeURIComponent(folderPath)

  // Remove the root path from decodedPath (since we don't want to see those breadcrumbs)
  // and transform into array of folders
  // remove the last
  // ex: ['Occupational Medicine', 'BEM Prozess komplette Unterlagen ', '']
  const pathArray = decodedPath
    .replace(getTopLevelPrefix(customerId), '')
    .split('/')

  // Remove last value of array since it is not a folder
  // ex: ['Occupational Medicine', 'BEM Prozess komplette Unterlagen ']
  pathArray.pop()

  pathArray.forEach((folderName, i, arr) => {
    if (i === arr.length - 1) {
      // ignore the last folder as it is the current directory
      return
    }
    const path = encodeURIComponent(
      // add back the root path
      // ex: "library/customers/c4057a25-6fe6-4082-ab83-71560dd50ab1/"
      getTopLevelPrefix(customerId) +
        // add all the previous directories
        // ex: 'Occupational Medicine/BEM Prozess komplette Unterlagen '
        arr.slice(0, i + 1).join('/') +
        // add trailing '/' to signify folder
        '/'
    )
    list.push({
      name: folderName,
      path: routes.libraryFolder(customerId, path),
    })
  })
  return list
}
