import { useCallback } from 'react'

import { Column, Table } from 'components/Table'
import { useSelector } from 'react-redux'
import { QueryConfigFactory, useMutation } from 'redux-query-react'
import { Customer } from 'services/model'

interface Props {
  customer?: Customer
  columns: Column[]
  query: QueryConfigFactory<any, any[]>
  record:
    | 'divisions'
    | 'managers'
    | 'customerUsers'
    | 'instructions'
    | 'admins'
    | 'safetyExperts'
  onRowClick?: (record: Record<'id', any>) => void
}

export const List: React.FC<Props> = ({
  customer,
  columns,
  query,
  record,
  onRowClick,
}) => {
  const list = useSelector((state) => state.entities[`${record}`]?.list || [])
  const count = useSelector(
    (state) => state.entities[`${record}`]?.meta.total_items || 0
  )
  const language = useSelector((state) => state.settings.language)

  const [{ isPending }, getRecords] = useMutation(query)

  const handlePageChange = useCallback(
    (
      limit: number,
      offset: number,
      sortBy: string | undefined,
      order: string | undefined
    ) => {
      if (customer) {
        getRecords(customer.id, limit, offset, sortBy, order, language)
      } else if (record === 'admins' || record === 'safetyExperts') {
        getRecords(limit, offset, sortBy, order)
      }
    },
    [customer, getRecords, language]
  )

  return (
    <Table
      key={customer?.id}
      onPageChange={handlePageChange}
      count={count}
      columns={columns}
      list={list}
      loading={isPending}
      onRowClick={onRowClick}
      tableLabel={`${record}-list`}
    />
  )
}

export default List
