import CsvImport, { CsvUploadDataType } from 'containers/csv-import'
import { useTranslation } from 'react-i18next'
import { importManagers } from 'services/queries-typed'

export const UsersImportScreen: React.FC = () => {
  const { t } = useTranslation()

  return (
    <CsvImport
      title={t('users.title')}
      query={importManagers}
      dataType={CsvUploadDataType.MANAGERS}
    />
  )
}

export default UsersImportScreen
