import { CircularProgress, Typography } from '@mui/material'
import OrgLogo from 'components/OrgLogo'
import { colors } from 'themes'

import Styles from './styles.js'

const LoadingScreen = () => {
  return (
    <>
      <div style={Styles.logo}>
        <OrgLogo color={colors.charcoal} />
      </div>
      <Typography variant="h5" align="center" style={Styles.spinner}>
        <CircularProgress />
      </Typography>
    </>
  )
}

export default LoadingScreen
