import { useCallback, useEffect } from 'react'

import InstructionsResultsTable from 'components/InstructionsResultsTable'
import routes, { CustomerRouteParams } from 'navigation/routes'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'redux-query-react'
import { DivisionOverview } from 'services/model'
import { getFacilityInstructionsOverview } from 'services/queries-typed'

export const InstructionsOverviewScreen: React.FC = () => {
  const divisionOverviewList = useSelector(
    (state) => state.entities.divisionOverviewList || []
  )
  const { customerId } = useParams<CustomerRouteParams>()
  const [{ isPending }, getOverview] = useMutation(
    getFacilityInstructionsOverview
  )
  const history = useHistory()

  const handleGoToFacility = useCallback(
    (division: DivisionOverview) =>
      history.push(
        routes.instructionsDivisionOverview(customerId, division.id)
      ),
    [customerId, history]
  )

  useEffect(() => {
    getOverview(customerId)
  }, [customerId, getOverview])

  return (
    <InstructionsResultsTable
      divisionOverviewList={divisionOverviewList}
      isLoading={isPending}
      onRowClick={handleGoToFacility}
      data-testid="instructions-overview-results-table"
    />
  )
}

export default InstructionsOverviewScreen
