import { useEffect, useReducer, useRef, useState } from 'react'

import { Grid } from '@mui/material'
import { CircularProgress, Typography } from '@mui/material'
import OnboardingPopover from 'components/OnboardingPopover'
import StatusLightIcon from 'components/StatusLightIcon'
import { SummaryHeader } from 'components/SummaryHeader'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useRequest } from 'redux-query-react'
import { getComplianceDataForCustomer } from 'services/queries'
import { theme } from 'themes'

interface ComplianceReducer {
  isCompliant: boolean
  isPartiallyCompliant: boolean
  isNonCompliant: boolean
}

function complianceReducer(
  _state: ComplianceReducer | null,
  status: string | undefined
) {
  switch (status) {
    case 'compliant':
      return {
        isCompliant: true,
        isPartiallyCompliant: false,
        isNonCompliant: false,
      }
    case 'non-compliant':
      return {
        isCompliant: false,
        isPartiallyCompliant: false,
        isNonCompliant: true,
      }
    case 'partially compliant':
      return {
        isCompliant: false,
        isPartiallyCompliant: true,
        isNonCompliant: false,
      }
    default:
      return {
        isCompliant: false,
        isPartiallyCompliant: false,
        isNonCompliant: false,
      }
  }
}

export const ComplianceHeader: React.FC = () => {
  const { t } = useTranslation()

  const compliance = useSelector((state) => state.entities.compliance)
  const customer = useSelector((state) => state.entities.customer)
  const editQuery = useSelector((state) => state.queries['edit-measure'])

  const [{ isPending, status }, refresh] = useRequest(
    getComplianceDataForCustomer(customer?.id)
  )
  const [callFailed, setCallFailed] = useState(false)

  const [state, dispatch] = useReducer(
    complianceReducer,
    compliance?.status,
    (status) => complianceReducer(null, status)
  )

  useEffect(() => {
    refresh()
  }, [editQuery, refresh])

  useEffect(() => {
    if (typeof status === 'number' && (status >= 400 || status === 0)) {
      setCallFailed(true)
    }
  }, [status])

  useEffect(() => {
    if (compliance) {
      dispatch(compliance.status)
    }
  }, [compliance])

  const getPercentage = (measuresCount: number) => {
    if (compliance) {
      return compliance?.measure_metrics?.total > 0
        ? ((100 * measuresCount) / compliance?.measure_metrics?.total).toFixed()
        : 0
    }
    return 0
  }

  const complianceStatus = useRef(null)
  const { isCompliant, isNonCompliant, isPartiallyCompliant } = state

  return (
    <SummaryHeader>
      <Grid item xs={4}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography data-testid="compliance_title_overdue">
              {t('compliance_header_overdue_measures')}:
            </Typography>
          </Grid>
          {isPending && (
            <CircularProgress style={{ color: theme.palette.text.primary }} />
          )}
          <Grid item>
            <Typography variant="h5" data-testid="compliance_data_overdue">
              {!isPending && callFailed && 'N/A'}
              {!isPending &&
                !callFailed &&
                compliance &&
                `${compliance.measure_metrics?.overdue} (${getPercentage(
                  compliance.measure_metrics?.overdue
                )}%)`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography data-testid="compliance_title_closed">
              {t('compliance_header_closed_measures')}:
            </Typography>
          </Grid>
          {isPending && (
            <CircularProgress style={{ color: theme.palette.text.primary }} />
          )}
          <Grid item>
            <Typography variant="h5" data-testid="compliance_data_closed">
              {!isPending && callFailed && 'N/A'}
              {!isPending &&
                !callFailed &&
                compliance &&
                `${compliance.measure_metrics?.done} (${getPercentage(
                  compliance.measure_metrics?.done
                )}%)`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography data-testid="compliance_title_status">
              {t('compliance_header_status')}:
            </Typography>
          </Grid>
          {isPending && (
            <CircularProgress style={{ color: theme.palette.text.primary }} />
          )}
          <Grid item ref={complianceStatus}>
            {!isPending && callFailed && 'N/A'}
            {!isPending && !callFailed && (
              <Grid container>
                <Grid item>
                  <StatusLightIcon
                    done={isCompliant}
                    overdue={isNonCompliant}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h5" data-testid="compliance_data_status">
                    {isCompliant && t('compliance_header_status_compliant')}
                    {isPartiallyCompliant &&
                      t('compliance_header_status_partially_compliant')}
                    {isNonCompliant &&
                      t('compliance_header_status_non_compliant')}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <OnboardingPopover
        stepId="compliance-status"
        anchorRef={complianceStatus}
        title="onboarding_popover_compliance_title"
        description="onboarding_popover_compliance_body"
        position="left"
      />
    </SummaryHeader>
  )
}
