import { useRef } from 'react'

import { Typography, Button, Avatar } from '@mui/material'
import OnboardingPopover from 'components/OnboardingPopover'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { fonts } from 'themes'
import { placeholderExpert } from 'themes/images'

const Text = styled(Typography)`
  padding: 0.2rem;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text.contrast};
`

const Header = styled(Text)`
  padding: 0.5rem 0;
  font-weight: 700;
  color: ${(props) => props.theme.palette.warning.main};
`

const Name = styled(Text)`
  font-family: ${fonts.base};
`
const Container = styled.div`
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;
`
const StyledAvatar = styled(Avatar)`
  margin: 1rem auto;
  width: 5rem;
  height: 5rem;
`
const EmailButton = styled(Button)`
  margin: 1rem auto;
  background-color: ${(props) => props.theme.palette.text.primary};
  color: ${(props) => props.theme.palette.text.contrast};
  border: 1px solid ${(props) => props.theme.palette.text.contrast};
  padding: 1rem 0.5rem;
  border-radius: 2rem;
  width: 100%;
  &:hover {
    background-color: ${(props) => props.theme.palette.text.contrast};
    color: ${(props) => props.theme.palette.text.primary};
  }
`
const Link = styled.a`
  text-decoration: none;
`

export const ContactExpertCard = () => {
  const { t } = useTranslation()
  const safetyExpert = useSelector(
    (state) => state.entities.customer?.safety_expert
  )
  const safetyExpertRef = useRef(null)

  return (
    <Container
      data-testid="safety-expert-popover-content"
      ref={safetyExpertRef}
    >
      <Header variant="body1" align="center">
        {t('safety_expert_btn_argumed_safety_expert')}:
      </Header>
      <StyledAvatar
        alt={t('onboarding_banner_image_alt')}
        src={safetyExpert?.image || placeholderExpert}
        data-testid="safety-expert-popover-avatar"
      />
      <Name variant="h3" align="center">
        {safetyExpert?.first_name} {safetyExpert?.last_name}
      </Name>
      <Link href={`mailto:${safetyExpert?.email}`} target={'_blank'}>
        <EmailButton>
          {t('safety_expert_btn_email', {
            name: safetyExpert?.first_name + ' ' + safetyExpert?.last_name,
          })}
        </EmailButton>
      </Link>
      <OnboardingPopover
        stepId="contact-expert"
        anchorRef={safetyExpertRef}
        title="onboarding_popover_contact_expert_title"
        description="onboarding_popover_contact_expert_body"
        position="right"
        lastItem
      />
    </Container>
  )
}

export default ContactExpertCard
