/**
 * Generic response body for a single item
 */
export interface ItemResponse<T> {
  data: T
}

/**
 * Generic response body for an item collection
 */
export interface CollectionResponse<T> {
  data: T[]
}

/**
 * Meta attribtue from response body
 */
export interface Meta<T> {
  meta: T
}

export type CollectionWithMetaResponse<C, M> = CollectionResponse<C> & Meta<M>

export interface Pagination {
  offset: number
  total_items: number
  total_pages: number
  page_number: number
}
export interface PaginatedCollectionResponse<T> extends CollectionResponse<T> {
  data: T[]
  meta: Pagination
}

export enum Order {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export interface Measure {
  id: string
  name: string
  hazard: string
  hazard_description: string
  facility: Division
  risk_level: RiskLevel
  done: boolean
  pdf_sent: boolean
  source: string | null
  due_date: string | null
  responsible_email: string | null
  responsible_name: string | null
  risk_group: RiskGroup
  images: MeasureImage[]
  documents: Document[]
  status: MeasureStatus
}

export enum MeasureStatus {
  DUE = 'due',
  DONE = 'done',
  OVERDUE = 'overdue',
}

export interface RiskGroup {
  id: string
  name: string
}

export interface Customer {
  id: string
  name: string
  status: CustomerStatus
  features: Features[] | null
  upcoming_ohs_meeting: string | null
  safety_expert: SafetyExpert | null
}

export interface SafetyExpert {
  id: string
  first_name: string
  last_name: string
  email: string
  image: string | null
}

export interface SafetyExperts {
  list: SafetyExpert[]
  meta: Pagination
}

export enum CustomerStatus {
  CREATED = 'created',
  ONBOARDED = 'onboarded',
}

export enum Features {
  OHS_MEETING = 'ohs_meeting',
  INSTRUCTIONS = 'instructions',
  NO_EMAIL_INSTRUCTIONS = 'no_email_instructions',
}

export interface OperationalArea {
  id: string
  name: string
}

export interface CustomerLocation {
  id: number
  name: string
  customer_id: string
  audited_at: string | null
}

export interface CustomerLocationMeta {
  total_divisions: number
}

export interface MeasureImage {
  id: string
  s3_key: string
}

export interface MeasureImageWithUrl extends MeasureImage {
  url: string
}

export interface Document {
  id: string
  name: string
  file: string
  file_type: FileType
  s3_key: string
}

export enum FileType {
  DOC = 'doc',
  IMG = 'img',
  PDF = 'pdf',
  XLS = 'xls',
  OTHER = 'other',
}

export interface Compliance {
  status: ComplianceStatus
  measure_metrics: MeasureMetrics
}

export interface MeasureMetrics {
  total: number
  done: number
  due: number
  overdue: number
}

export enum ComplianceStatus {
  COMPLIANT = 'compliant',
  PARTIALLY_COMPLIANT = 'partially compliant',
  NON_COMPLIANT = 'non-compliant',
}

export interface Employee {
  id: string
  status: EmployeeStatus
}

export enum EmployeeStatus {
  CREATED = 'created',
  TUTORIAL_COMPLETED = 'tutorial_completed',
}

export enum RiskLevel {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

export interface InstructionExaminee {
  id: string
  email: string
  passed_at?: string
}

export interface Filter {
  key: string
  value: string
}

export interface CSVMeta {
  added: number
}

export interface EmployeesCSVMeta extends CSVMeta {
  updated?: number
  unchanged?: number
  activated?: number
  deactivated?: number
}

export interface EditCustomerMeasureBody {
  due_date?: string
}

export interface EditCustomerMeasureExtendedBody
  extends EditCustomerMeasureBody {
  done?: boolean
  hazard?: string
  hazard_description?: string
  name?: string
  pdf_sent?: boolean
  responsible_email?: string
  responsible_name?: string
  risk_level?: RiskLevel
  source?: string
}

export interface Instruction {
  id: string
  name: string
  slides_url?: string | null
  quiz_intent?: string
}

export interface Instructions {
  list: Instruction[]
  meta: Pagination
}

export interface Division {
  id: number
  location: string
  operational_area: string
  managers_count: number
}

export interface Divisions {
  list: Division[]
  meta: Pagination
}

export interface InstructionDivisions {
  list: InstructionDivision[]
  meta: Pagination
}

export interface DivisionInstruction {
  facility_id: Division['id']
  instruction_id: Instruction['id']
  created_at: string
}

export interface InstructionDivision extends Division {
  instruction_expires_at: string
}

export interface CustomerDivisionInstruction extends DivisionInstruction {
  customer_id: Customer['id']
}

export interface DivisionOverview {
  id: Division['id']
  location: string
  operational_area: string
  employees_total: number
  employees_passed_all: number
  employees_overdue: number
  enabled_instructions_count: number
}

export interface DivisionEmployee {
  customer_id: string
  facility_id: number
  id: string
  first_name: string
  last_name: string
  email: string
}

export interface EmployeeInstruction {
  customer_id: string
  facility_id: number
  instruction_id: string
  passed_at: string
}

export interface EmployeeInstructionSummary {
  id: string
  employee_slides_url: string
  employee_instruction: EmployeeInstruction | null
  facility_instruction: DivisionInstruction
  instruction: Instruction
}

export interface DivisionEmployeeWithInstructions extends DivisionEmployee {
  passed_instructions: EmployeeInstruction[]
}

export type GetDivisionEmployeesInstructionsResponse =
  PaginatedCollectionResponse<DivisionEmployeeWithInstructions>

export interface MeasureFilters {
  locations: string[]
  operational_areas: string[]
  statuses: MeasureStatus[]
}

export type MeasureFiltersResponse = ItemResponse<MeasureFilters>

export interface Manager {
  id: string
  email: string
  first_name: string | null
  last_name: string | null
  facilities_count: number
}

export interface Managers {
  list: Manager[]
  meta: Pagination
}

export interface CustomerUserMeta {
  total_facilities: number
}

export interface CustomerUser {
  id: string
  role: CustomerUserRole
  email: string
  first_name: string | null
  last_name: string | null
}

export interface ExtendedCustomerUser extends CustomerUser {
  meta: CustomerUserMeta
}

export interface CustomerUsers {
  list: ExtendedCustomerUser[]
  meta: Pagination
}

export enum CustomerUserRole {
  OHS_OFFICER = 'ohs_officer',
  FACILITY_MANAGER = 'facility_manager',
  LOCATION_MANAGER = 'location_manager',
}

export interface ManagerLocation extends CustomerLocation {
  meta: CustomerLocationMeta
}

export interface ManagerLocations {
  list: ManagerLocation[]
  meta: Pagination
}

export interface CustomerLocation {
  customer_id: string
  id: number
  name: string
  audited_at: string | null
}

export interface S3Document {
  Key: string
  LastModified: string
  Size: number
}

export interface S3Folder {
  Prefix: string
}

/**
 * Only what is required is typed, consult boto3 documentation for all that's available.
 * - [Documentation](https://boto3.amazonaws.com/v1/documentation/api/latest/reference/services/s3.html#S3.Client.list_objects_v2)
 */
export interface S3Response {
  CommonPrefixes?: S3Folder[]
  Contents?: S3Document[]
  Prefix: string
}

export enum DocumentType {
  FOLDER = 'folder',
  FILE = 'file',
}

export interface LibraryFolder {
  id: string
  name: string
  type: DocumentType.FOLDER
}

export interface LibraryDocument {
  id: string
  name: string
  lastModified: string
  size: number
  type: DocumentType.FILE
}

export type LibraryListItem = LibraryFolder | LibraryDocument

export interface LibraryPermissions {
  permissions: string[]
}

export interface ExtendedS3Response {
  data: S3Response
  meta: LibraryPermissions
}

export interface OHSAdmin {
  id: string
  role: 'admin'
  email: string
  first_name: string | null
  last_name: string | null
}

export interface OHSAdmins {
  list: OHSAdmin[]
  meta: Pagination
}

export interface Author {
  customer_id: Customer['id'] | null
  id: string
  role: UserRole
  email: string | null
  first_name: string | null
  last_name: string | null
}

export interface MeasureComment {
  id: string
  created_at: Date
  text: string
  customer_id: Customer['id']
  measure_id: Measure['id']
  author: Author | null
}

export enum UserRole {
  OHS_OFFICER = 'ohs_officer',
  FACILITY_MANAGER = 'facility_manager',
  LOCATION_MANAGER = 'location_manager',
  ADMIN = 'admin',
  SAFETY_EXPERT = 'safety_expert',
}

export interface UserProps {
  first_name: string | undefined
  last_name: string | undefined
  email: string | undefined
}

export interface AssignedDivisionInstruction {
  id: string
  instruction_id: string
  instruction_name: string
  expires_at: string
}

export interface AssignedDivisionInstructions {
  list: AssignedDivisionInstruction[]
  meta: Pagination
}
