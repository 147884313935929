import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Box, Button, Grid } from '@mui/material'
import { isEmail, isNonEmpty } from 'lib'
import theme from 'themes/theme'
import { FormTextfield } from 'components/FormFields'
import { UserProps } from 'services/model'

interface Props {
  onSubmit: (attributes: Partial<UserProps>) => void
}

export const UserForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const tf = (field: keyof UserProps) => t(`admins.attributes.${field}.label`)

  const validEmailRule = {
    validate: isEmail,
    required: t('global.isRequired', '', { label: tf('email') }) as string,
  }

  const defaultValues = {
    first_name: '',
    last_name: '',
    email: '',
  }

  useEffect(() => {
    reset(defaultValues)
  }, [])

  // FIXME: currently control requires generic FieldValues as Type. Find a way to type with ResponsibleProps
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<FieldValues>({ defaultValues })

  const handleClose = () => {
    reset(defaultValues)
  }
  const onFormSubmit = (attributes: Partial<UserProps>) => {
    onSubmit(attributes)
    reset()
  }

  return (
    <Box
      component="form"
      sx={{ backgroundColor: theme.palette.background.info }}
      padding={4}
      onSubmit={handleSubmit(onFormSubmit)}
      noValidate
      data-testid="create-user-form"
    >
      <Grid container style={{ margin: '16px 0' }}>
        <Grid item xs={7} padding={1}>
          <FormTextfield
            name={'first_name'}
            control={control}
            editMode={true}
            label={tf('first_name')}
            rules={{ validate: isNonEmpty(tf('first_name')) }}
          />
        </Grid>
        <Grid item xs={5} padding={1}>
          <FormTextfield
            name={'last_name'}
            control={control}
            editMode={true}
            label={tf('last_name')}
            rules={{ validate: isNonEmpty(tf('last_name')) }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ margin: '16px 0' }} padding={1}>
        <FormTextfield
          name={'email'}
          control={control}
          editMode={true}
          label={tf('email')}
          rules={validEmailRule}
          multiline={true}
        />
      </Grid>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            variant="outlined"
            onClick={handleClose}
            className="outlined-form-btn"
            data-testid="cancel-create-user-btn"
          >
            {t('global.buttons.cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            key="preventDoubleSubmit"
            className="outlined-form-btn"
            disabled={!isDirty}
            data-testid="create-user-btn"
          >
            {t('global.buttons.send')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UserForm
