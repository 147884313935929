import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Grid, ListItem, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ContactExpertCard from 'components/ContactExpertCard'
import OnboardingPopover from 'components/OnboardingPopover'
import { displayFormattedDate } from 'lib/helpers'
import routes from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import { goBack, goForward } from 'redux/onboarding/actions'
import { fonts, theme } from 'themes'
import {
  DashboardIcon,
  InstructionsIcon,
  LibraryIcon,
  CalendarIcon,
  FacilitiesIcon,
  UserIcon,
} from 'themes/icons'

const useStyles = makeStyles()(() => ({
  selected: {
    color: theme.palette.primary.light,
  },
  unselected: {
    // FIXME: update theme to use MUI colors
    color: theme.palette.text.unselected,
  },
  link: {
    cursor: 'pointer',
    fontFamily: fonts.bold,
  },
  listItem: {
    backgroundColor: theme.palette.primary.main,
    paddingLeft: '0.5rem',
  },
  selectedBorder: {
    borderLeft: `3px solid ${theme.palette.primary.light}`,
  },
  transparentBorder: {
    borderLeft: `3px solid transparent`,
  },
  container: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    paddingTop: '2rem',
    paddingBottom: '4rem',
    position: 'fixed',
    width: 'inherit',
  },
  gridItem: {
    width: '100%',
  },
  menuIcon: {
    minWidth: '2rem',
  },
  title: {
    padding: '0.5rem 0.5rem 0 0.5rem',
    fontWeight: 700,
    color: theme.palette.warning.main,
  },
  a: {
    textDecoration: 'none',
  },
  list: {
    paddingTop: 0,
  },
}))

const getIcon = (link: string) => {
  switch (link) {
    case 'dashboard':
      return DashboardIcon
    case 'library':
      return LibraryIcon
    case 'instructions':
      return InstructionsIcon
    // TODO: alignement of icons is off
    case 'divisions':
      return FacilitiesIcon
    // TODO: alignement of icons is off
    case 'users':
      return UserIcon
    case 'admins':
      return UserIcon
    case 'safetyExperts':
      return UserIcon
    default:
      return DashboardIcon
  }
}

export const SidebarMenu: React.FC = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const { t } = useTranslation()
  const customerId = useSelector((state) => state.entities?.customer?.id)

  const safetyExpert = useSelector(
    (state) => state.entities.customer?.safety_expert
  )
  const onboardingStep = useSelector((state) => state.onboarding?.currentStep)
  const onboardingDirection = useSelector(
    (state) => state.onboarding?.direction
  )

  const displayUpcomingAsaDate = useSelector((state) =>
    state.entities.customer?.features?.some(
      (feature) => feature === 'ohs_meeting'
    )
  )

  const displayOnlineInstructions = useSelector(
    (state) =>
      state.entities.customer?.features?.some(
        (feature) => feature === 'instructions'
      ) && state.profile.permissions.includes('weissbier:list:instruction')
  )

  const displayFacilities = useSelector((state) =>
    state.profile.permissions.includes('weissbier:list:facility')
  )
  const displayUsers = useSelector((state) =>
    state.profile.permissions.includes('weissbier:list:manager')
  )

  const displayLibrary = useSelector((state) =>
    state.profile.permissions.includes('weissbier:list:library_document')
  )

  const displayAdmins = useSelector((state) =>
    state.profile.permissions.includes('weissbier:list:admin')
  )

  const displaySafetyExperts = useSelector((state) =>
    state.profile.permissions.includes('weissbier:list:safety_expert')
  )

  const upcomingAsaDate = useSelector(
    (state) => state.entities.customer?.upcoming_ohs_meeting
  )
  const libraryRef = useRef(null)
  const meetingRef = useRef(null)

  const [menuItems, setMenuItems] = useState(['dashboard'])

  useEffect(() => {
    const items = ['dashboard']

    if (displayLibrary) {
      items.push('library')
    }
    if (displayOnlineInstructions) {
      items.push('instructions')
    }
    if (displayFacilities) {
      items.push('divisions')
    }
    if (displayUsers) {
      items.push('users')
    }
    if (displayAdmins) {
      items.push('admins')
    }
    if (displaySafetyExperts) {
      items.push('safetyExperts')
    }
    setMenuItems(items)
  }, [
    displayOnlineInstructions,
    displayFacilities,
    displayUsers,
    displayLibrary,
    displayAdmins,
    displaySafetyExperts,
  ])

  const getPath = useCallback(
    (link: string) => {
      switch (link) {
        case 'dashboard':
          return routes.dashboard(customerId!)
        case 'library':
          return routes.library(customerId!)
        case 'instructions':
          return routes.instructions(customerId!)
        case 'divisions':
          return routes.divisions(customerId!)
        case 'users':
          return routes.users(customerId!)
        case 'admins':
          return routes.admins()
        case 'safetyExperts':
          return routes.safetyExperts()
        default:
          return routes.dashboard(customerId!)
      }
    },
    [customerId]
  )

  useEffect(() => {
    if (!displayUpcomingAsaDate && onboardingStep === 'asa-meeting') {
      if (onboardingDirection === 'backwards') {
        dispatch(goBack())
      } else {
        dispatch(goForward())
      }
    }
  }, [onboardingStep, displayUpcomingAsaDate, onboardingDirection, dispatch])

  const isSelected = useCallback(
    (link: string) => {
      switch (link) {
        case 'dashboard':
          return (
            customerId && location.pathname === routes.dashboard(customerId)
          )
        case 'library':
          return customerId && location.pathname === routes.library(customerId)
        case 'instructions':
          return (
            customerId && location.pathname === routes.instructions(customerId)
          )
        case 'divisions':
          return (
            customerId && location.pathname === routes.divisions(customerId)
          )
        case 'users':
          return customerId && location.pathname === routes.users(customerId)
        case 'admins':
          return location.pathname === routes.admins()
        case 'safetyExperts':
          return location.pathname === routes.safetyExperts()
        default:
          return false
      }
    },
    [customerId, location.pathname]
  )

  const renderItems = () => {
    return menuItems.map((link) => {
      const IconName = getIcon(link)
      return (
        <React.Fragment key={link}>
          <Link
            to={customerId ? getPath(link) : '/'}
            key={link}
            style={{ textDecoration: 'none' }}
            ref={link === 'library' ? libraryRef : null}
          >
            <ListItem
              className={`${
                isSelected(link)
                  ? classes.selectedBorder
                  : classes.transparentBorder
              } ${classes.listItem}`}
            >
              <ListItemIcon className={classes.menuIcon}>
                <IconName
                  fill={
                    isSelected(link)
                      ? theme.palette.warning.main
                      : // FIXME: update theme to use MUI colors
                        theme.palette.text.unselected
                  }
                  data-testid={`sidebar-menu-icon-${link}`}
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    className={`${
                      isSelected(link) ? classes.selected : classes.unselected
                    } ${classes.link}`}
                  >
                    {t(`sidebar_link_${link}`)}
                  </Typography>
                }
              />
            </ListItem>
          </Link>
          {link === 'library' && (
            <OnboardingPopover
              stepId="reference-library"
              anchorRef={libraryRef}
              title="onboarding_popover_library_title"
              description="onboarding_popover_library_body"
              position="right"
            />
          )}
        </React.Fragment>
      )
    })
  }

  const renderDates = () => {
    return (
      <>
        <ListItem
          className={`${classes.transparentBorder} ${classes.listItem}`}
        >
          <ListItemIcon className={classes.menuIcon}>
            <CalendarIcon
              fill={theme.palette.primary.light}
              data-testid={`sidebar-menu-icon-calendar`}
            />
          </ListItemIcon>
          <ListItemText
            ref={meetingRef}
            disableTypography
            primary={
              upcomingAsaDate ? (
                <Typography className={`${classes.selected} ${classes.link}`}>
                  {displayFormattedDate(upcomingAsaDate)}
                </Typography>
              ) : (
                <a
                  href="https://www.etermin.net/Argumed_Arbeitsmedizin"
                  target={'_blank'}
                  className={classes.a}
                  rel="noopener noreferrer"
                >
                  <Typography className={`${classes.selected} ${classes.link}`}>
                    {t(`sidebar_schedule_meeting`)}
                  </Typography>
                </a>
              )
            }
          />
        </ListItem>
        <OnboardingPopover
          stepId="asa-meeting"
          anchorRef={meetingRef}
          title="onboarding_popover_asa_meeting_title"
          description="onboarding_popover_asa_meeting_body"
          position="right"
        />
      </>
    )
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      className={classes.container}
      data-testid="sidebar-menu"
    >
      <Grid item className={classes.gridItem}>
        <List>{renderItems()}</List>
      </Grid>
      {displayUpcomingAsaDate && (
        <Grid item className={classes.gridItem}>
          <Typography variant="body1" align="left" className={classes.title}>
            {t(`sidebar_next_meeting`)}:
          </Typography>
          <List className={classes.list}>{renderDates()}</List>
        </Grid>
      )}
      <Grid item className={classes.gridItem}>
        <ContactExpertCard />
      </Grid>
    </Grid>
  )
}

export default SidebarMenu
