import { useEffect } from 'react'

import MeasuresTable from 'components/MeasuresTable'
import OnboardingModal from 'components/OnboardingModal'
import Callout from 'components/callout'
import { PageHeader } from 'containers/page-headers'
import routes, { CustomerRouteParams } from 'navigation/routes'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useHistory } from 'react-router'
import { useMutation } from 'redux-query-react'
import { getUnauditedLocations } from 'services/queries-typed'
import { addUserlikeScript } from 'services/userlike'

import TileHeaders from './tile-headers'

interface Props {
  showTutorial: boolean
}

export const DashboardScreen: React.FC<Props> = ({ showTutorial }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { customerId } = useParams<CustomerRouteParams>()

  const [, getLocations] = useMutation(getUnauditedLocations)

  useEffect(() => {
    getLocations(customerId)
  }, [customerId, getLocations])

  useEffect(() => {
    addUserlikeScript()
  }, [])

  const handleGoToAudit = () => {
    history.push(routes.audits(customerId))
  }

  return (
    <>
      <Callout />
      <PageHeader
        title={t('dashboard.title')}
        header={<TileHeaders onButtonClick={handleGoToAudit} />}
      >
        <MeasuresTable customerId={customerId} />
        <OnboardingModal showTutorial={showTutorial} />
      </PageHeader>
    </>
  )
}

export default DashboardScreen
