import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  style?: React.CSSProperties
}
const Footer: React.FC<Props> = ({ style }) => {
  const { t } = useTranslation()
  const goToImprint = () =>
    (window.location.href = 'https://www.argumed.eu/impressum/')

  return (
    <Box style={style}>
      <BottomNavigation showLabels sx={{ justifyContent: 'right' }}>
        <BottomNavigationAction
          label={t('footer.imprint')}
          onClick={() => goToImprint()}
        />
      </BottomNavigation>
    </Box>
  )
}

export default Footer
