import { useEffect, useState } from 'react'

import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  Button,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import LoadingBackdrop from 'components/LoadingBackdrop'
import { useTranslation } from 'react-i18next'
import {
  DivisionEmployeeWithInstructions,
  AssignedDivisionInstruction,
} from 'services/model'
import { sendEmployeeInstructionReminderEmails } from 'services/queries-typed'
import { useMutation } from 'redux-query-react'
import { useSnackbar } from 'notistack'
import { ConfirmationModal } from 'components/ConfirmationModal'
import { Features } from 'services/model'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { CustomerRouteParams } from 'navigation/routes'

export interface Props {
  list: DivisionEmployeeWithInstructions[]
  instructions: AssignedDivisionInstruction[]
  count: number
  onPageChange: (limit: number, page: number) => void
  loading?: boolean
}

const useStyles = makeStyles()((theme) => {
  return {
    avatar: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.dark,
    },
  }
})

const InstructionPassed: React.FC<{ passed: boolean }> = ({ passed }) => {
  const { t } = useTranslation()

  if (passed) {
    return (
      <Typography color="textSecondary">
        {t('employeeInstructionList_passed')}
      </Typography>
    )
  }

  return <Typography>{t('employeeInstructionList_notPassed')}</Typography>
}

export const EmployeeList: React.FC<Props> = ({
  list,
  instructions,
  count = 0,
  onPageChange,
  loading = false,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [, sendReminder] = useMutation(sendEmployeeInstructionReminderEmails)
  const { enqueueSnackbar } = useSnackbar()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [email, setEmail] = useState(String)
  const { customerId } = useParams<CustomerRouteParams>()

  const filtred_instruction = list.forEach((employee) => {
    if (employee.passed_instructions) {
      employee.passed_instructions.forEach((item) => {
        if (item.instruction_id in instructions) {
          return instructions
        }
      })
    }
  })
  useEffect(() => {
    onPageChange(rowsPerPage, page)
  }, [page, rowsPerPage, onPageChange])

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    pageNumber: number
  ) => {
    setPage(pageNumber)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleReminder = (email: string) => {
    setEmail(email)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const onSendReminders = () => {
    setIsModalOpen(false)
    sendReminder(customerId, email)
      ?.then((response) => {
        if (response.status < 400) {
          enqueueSnackbar(t('employeeScreen.reminder.success'), {
            variant: 'success',
          })
        } else {
          enqueueSnackbar(t('employeeScreen.reminder.error'), {
            variant: 'error',
          })
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' })
      })
  }

  const validatePassedInstructions = (
    instructions: any,
    passedInstructions: any
  ) => {
    console.log(instructions)
    console.log(passedInstructions)
    return true
  }
  const permissions = useSelector((state) => state.profile.permissions)

  const canManageInstructions = permissions.includes(
    'weissbier:update:facility_instruction'
  )

  const canSendReminders = permissions.includes('weissbier:send:reminder')

  const hasEmailsDisabled = useSelector((state) =>
    state.entities.customer?.features?.includes(Features.NO_EMAIL_INSTRUCTIONS)
  )
  return (
    <TableContainer component={Paper} elevation={0}>
      <LoadingBackdrop isLoading={loading} />
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t('employeeInstructionList_employeeHeader')}</TableCell>
            {instructions.map(({ instruction_id, instruction_name }) => (
              <TableCell key={instruction_id}>{instruction_name}</TableCell>
            ))}
            {!hasEmailsDisabled && canManageInstructions && (
              <TableCell></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((employee) => (
            <TableRow key={employee.id}>
              <TableCell>
                {
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        {employee.first_name.charAt(0) +
                          employee.last_name.charAt(0)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${employee.first_name} ${employee.last_name}`}
                      secondary={employee.email}
                    />
                  </ListItem>
                }
              </TableCell>
              {instructions.map(({ instruction_id }, idx) => (
                <TableCell key={idx}>
                  <InstructionPassed
                    passed={
                      (employee.passed_instructions.length > 0 &&
                        employee.passed_instructions.some(
                          (passedInstruction) =>
                            passedInstruction.instruction_id === instruction_id
                        )) ||
                      false
                    }
                  />
                </TableCell>
              ))}
              <TableCell align="center">
                {!hasEmailsDisabled &&
                  canSendReminders &&
                  employee.passed_instructions.length != instructions.length &&
                  validatePassedInstructions(
                    instructions,
                    employee.passed_instructions
                  ) && (
                    <Button
                      size="small"
                      onClick={() => handleReminder(employee.email)}
                      variant="contained"
                      color="secondary"
                      data-testid="online-instructions-remind-overdue-button"
                      sx={{ margin: 1 / 8, padding: 1.2 }}
                    >
                      {t('employeeScreen.remind_overdue_employees')}
                    </Button>
                  )}
              </TableCell>
            </TableRow>
          ))}
          <ConfirmationModal
            bodyCopy={t('employeeScreen.reminder_modal_body')}
            confirmButtonCopy={t('global.buttons.send')}
            open={isModalOpen}
            onClose={handleCloseModal}
            onConfirm={() => onSendReminders()}
          />
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
