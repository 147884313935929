import { Typography } from '@mui/material'
import { Column } from 'components/Table'
import { List } from 'containers/list'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Customer, ExtendedCustomerUser } from 'services/model'
import { getCustomerUsers } from 'services/queries-typed'

interface Props {
  customer: Customer
}

export const UsersList: React.FC<Props> = ({ customer }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { url } = useRouteMatch()
  const permissions = useSelector((state) => state.profile.permissions)

  const columns: Column[] = [
    {
      label: t('user'),
      getter: (user: ExtendedCustomerUser) => (
        <>
          {user.last_name && (
            <Typography variant="body1">{`${user.last_name}, ${user.first_name}`}</Typography>
          )}
          <Typography variant="body1" color="textSecondary">
            {user.email}
          </Typography>
          {!user.last_name && !user.email && (
            <Typography variant="body1">{`${user.id}`}</Typography>
          )}
        </>
      ),
    },
    {
      label: t('users.role'),
      getter: (user: ExtendedCustomerUser) =>
        t(`users.user_roles.${user.role}`),
    },
    {
      label: t('users.managing'),
      getter: (user: ExtendedCustomerUser) =>
        t('users.facility_count', { count: user.meta.total_facilities }),
    },
  ]

  const handleGoToUserProfile = (user: { id: string }) => {
    history.push(`${url}/${user.id}`)
  }

  return (
    <List
      key={customer?.id}
      customer={customer}
      columns={columns}
      query={getCustomerUsers}
      record="customerUsers"
      onRowClick={
        permissions.includes('weissbier:read:manager')
          ? handleGoToUserProfile
          : undefined
      }
    />
  )
}

export default UsersList
