import { Grid } from '@mui/material'
import SidebarMenu from 'components/SidebarMenu'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import styled from 'styled-components'

const SidebarContainer = styled(Grid)`
  width: 10%;
`
const BodyContainer = styled(Grid)`
  width: 90%;
`
interface Props {
  show: boolean
  children?: React.ReactNode
  flags: Record<string, any>
}

export const Sidebar: React.FC<Props> = ({ show, children, flags }) => {
  return (
    <>
      {flags.enableLocationBasedAudit || show ? (
        <Grid container>
          <SidebarContainer item>
            <SidebarMenu />
          </SidebarContainer>
          {/* TODO: apply responsivness here */}
          <BodyContainer item>{children}</BodyContainer>
        </Grid>
      ) : (
        children
      )}
    </>
  )
}

export default withLDConsumer()(Sidebar)
