import React, { useCallback, useEffect, useState } from 'react'

import { Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { makeStyles } from 'tss-react/mui'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { LDProps } from 'launchdarkly-react-client-sdk/lib/withLDConsumer'
import { isEqual } from 'lodash'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'react-redux'
import { theme } from 'themes'

export interface Props {
  title: string
  body: string
  onClose: () => void
  style: React.CSSProperties
}

const useStyles = makeStyles()(() => ({
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '90%',
  },
  icon: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}))

export const Callout: React.FC<Props> = ({ title, body, onClose, style }) => {
  const { classes } = useStyles()

  return (
    <Grid
      container
      justifyContent="space-between"
      data-testid="callout"
      style={style}
    >
      <Grid item className={classes.icon}>
        <InfoOutlined />
      </Grid>
      <Grid item className={classes.content}>
        <Typography variant="h2">{title}</Typography>
        <ReactMarkdown
          components={{
            a: ({ ...rest }) => <a target="_blank" {...rest} />,
          }}
        >
          {body}
        </ReactMarkdown>
      </Grid>
      <Grid item>
        <IconButton
          onClick={onClose}
          data-testid="close-callout-btn"
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

interface FlagProps extends LDProps {
  primaryOnly?: boolean
}

export const FeatureFlaggedCallout: React.FC<FlagProps> = ({
  flags = {},
  ldClient,
  primaryOnly = false,
}) => {
  const [calloutVisible, setCalloutVisible] = useState(false)
  const [primaryCalloutVisible, setPrimaryCalloutVisible] = useState(false)
  const language = useSelector((state) => state.settings.language)
  const CALLOUT_DISMISSED = 'calloutDismissed'
  const PRIMARY_CALLOUT_DISMISSED = 'primaryCalloutDismissed'
  const ENABLE_CALLOUT_FLAG = 'enable-call-out'
  const ENABLE_PRIMARY_CALLOUT_FLAG = 'enable-primary-callout'

  const resetCallouts = (resetAll: boolean = true) => {
    if (resetAll) {
      setPrimaryCalloutVisible(true)
      localStorage.removeItem(PRIMARY_CALLOUT_DISMISSED)
    }
    setCalloutVisible(true)
    localStorage.removeItem(CALLOUT_DISMISSED)
  }

  const handleClose = useCallback(() => {
    setCalloutVisible(false)
    const calloutContent = JSON.stringify(flags.enableCallOut)
    localStorage.setItem(CALLOUT_DISMISSED, calloutContent)
  }, [flags])

  const handleClosePrimaryCallout = useCallback(() => {
    setPrimaryCalloutVisible(false)
    const calloutContent = JSON.stringify(flags.enablePrimaryCallout)
    localStorage.setItem(PRIMARY_CALLOUT_DISMISSED, calloutContent)
  }, [flags])

  /**
   * Initialize
   */
  useEffect(() => {
    if (ldClient) {
      // Callout
      const currentValue = ldClient.variation(ENABLE_CALLOUT_FLAG)
      const dismissedValue = localStorage.getItem(CALLOUT_DISMISSED)
      let previousValue = null

      if (dismissedValue) {
        previousValue = JSON.parse(dismissedValue)
      }

      // Primary callout
      const currentPrimaryValue = ldClient.variation(
        ENABLE_PRIMARY_CALLOUT_FLAG
      )
      const dismissedPrimaryValue = localStorage.getItem(
        PRIMARY_CALLOUT_DISMISSED
      )
      let previousPrimaryValue = null

      if (dismissedPrimaryValue) {
        previousPrimaryValue = JSON.parse(dismissedPrimaryValue)
      }

      if (!isEqual(previousValue, currentValue)) {
        if (!isEqual(previousPrimaryValue, currentPrimaryValue)) {
          // None of the callouts have been dismissed - initialize both
          resetCallouts()
        } else {
          // Primary callout has been dismissed - only display second callout
          resetCallouts(false)
        }
      }
    }
  }, [ldClient])

  /**
   * Handle live flag changes
   */
  useEffect(() => {
    const eventKey = `change:${ENABLE_CALLOUT_FLAG}`
    ldClient?.on(eventKey, resetCallouts)
    return () => ldClient?.off(eventKey, resetCallouts)
  }, [ldClient])

  useEffect(() => {
    const eventKey = `change:${ENABLE_PRIMARY_CALLOUT_FLAG}`
    ldClient?.on(eventKey, resetCallouts)
    return () => ldClient?.off(eventKey, resetCallouts)
  }, [ldClient])

  const showPrimaryCallout =
    primaryCalloutVisible &&
    !!flags.enablePrimaryCallout?.title &&
    !!flags.enablePrimaryCallout?.body
  const showCallout =
    !primaryOnly &&
    calloutVisible &&
    !!flags.enableCallOut?.title &&
    !!flags.enableCallOut.body

  if (!showPrimaryCallout && !showCallout) {
    return null
  }

  if (showPrimaryCallout) {
    return (
      <Callout
        title={flags.enablePrimaryCallout.title[language]}
        body={flags.enablePrimaryCallout.body[language]}
        onClose={handleClosePrimaryCallout}
        style={{ backgroundColor: theme.palette.success.light }}
      />
    )
  }

  return (
    <Callout
      title={flags.enableCallOut.title[language]}
      body={flags.enableCallOut.body[language]}
      onClose={handleClose}
      style={{ backgroundColor: theme.palette.warning.main }}
    />
  )
}

export default withLDConsumer()(FeatureFlaggedCallout)
