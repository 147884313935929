import { useCallback, useEffect } from 'react'

import { EmployeeList } from 'containers/employee-list'
import { DivisionRouteParams } from 'navigation/routes'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMutation } from 'redux-query-react'
import { Order, AssignedDivisionInstruction } from 'services/model'
import {
  getDivisionEmployeesInstructions,
  getActiveDivisionInstructions,
  getDivisionsInstructions,
  getInstructions,
} from 'services/queries-typed'

export const OnlineInstructionsFacilityEmployees: React.FC = () => {
  const { divisionId, customerId } = useParams<DivisionRouteParams>()

  const language = useSelector((state) => state.settings.language)
  const instructions = useSelector(
    (state) => state.entities.instructions?.list || []
  )
  const activeInstructions = useSelector(
    (state) => state.entities.activeDivisionInstructions?.list || []
  )
  const divisionInstructionsMap = useSelector(
    (state) => state.entities.divisionInstructionsMap || {}
  )
  const facilityEmployeeInstructions = useSelector(
    (state) => state.entities.divisionEmployeesInstructions
  )

  const [{ isPending: isFetchingInstructions }, getCustomerInstructions] =
    useMutation(getDivisionsInstructions)
  const [{ isPending }, getFacilityEmployees] = useMutation(
    getDivisionEmployeesInstructions
  )
  const [{ isPending: loadingTemplates }, getTemplates] =
    useMutation(getInstructions)

  const [{ isPending: isFetchingActive }, fetchActiveInstructions] =
    useMutation(getActiveDivisionInstructions)

  useEffect(() => {
    getTemplates(customerId, 500, 0, 'instruction', Order.ASCENDING, language)
  }, [language, getTemplates, customerId])

  useEffect(() => {
    getCustomerInstructions(customerId)
  }, [getCustomerInstructions, customerId])

  useEffect(() => {
    fetchActiveInstructions(
      customerId,
      Number(divisionId),
      50,
      0,
      undefined,
      undefined,
      language
    )
  }, [fetchActiveInstructions, customerId, divisionId, language])

  const handleGetFacilityEmployees = useCallback(
    (limit: number, page: number) => {
      getFacilityEmployees(customerId, Number(divisionId), limit, page * limit)
    },
    [getFacilityEmployees, divisionId, customerId]
  )

  if (!customerId) {
    return null
  }

  const active_instructions_set = new Set<string>() // Create a Set to store unique instruction_ids

  const active_instructions =
    facilityEmployeeInstructions?.list.flatMap((employee) =>
      employee.passed_instructions.map((passed_instructions) => {
        const instruction = activeInstructions.find(
          (instruction) =>
            passed_instructions.instruction_id === instruction.instruction_id
        )

        if (
          instruction &&
          !active_instructions_set.has(instruction.instruction_id)
        ) {
          active_instructions_set.add(instruction.instruction_id) // Add instruction_id to the Set
          return instruction // Return the instruction
        }
      })
    ) || []

  const filtered_instructions: AssignedDivisionInstruction[] =
    active_instructions.filter(
      (instruction): instruction is AssignedDivisionInstruction =>
        instruction !== undefined
    )

  return (
    <EmployeeList
      onPageChange={handleGetFacilityEmployees}
      count={facilityEmployeeInstructions?.meta.total_items || 0}
      instructions={activeInstructions}
      list={facilityEmployeeInstructions?.list || []}
      loading={
        isPending ||
        loadingTemplates ||
        isFetchingInstructions ||
        isFetchingActive
      }
    />
  )
}

export default OnlineInstructionsFacilityEmployees
