import { Table, Column } from 'components/Table'
import { useTranslation } from 'react-i18next'
import { DivisionOverview } from 'services/model'
import { Features } from 'services/model'
import { useSelector } from 'react-redux'

export interface Props {
  divisionOverviewList: DivisionOverview[]
  isLoading: boolean
  onRowClick: (division: DivisionOverview) => void
}

export const InstructionsResultsTable: React.FC<Props> = ({
  divisionOverviewList,
  isLoading,
  onRowClick,
}) => {
  const { t } = useTranslation()

  const columns: Column[] = [
    {
      label: t(`instructionsScreen_location`),
      getter: (division: DivisionOverview) => division.location,
    },
    {
      label: t(`instructionsScreen_operational_area`),
      getter: (division: DivisionOverview) => division.operational_area,
    },
    {
      label: t(`instructionsScreen_passed`),
      getter: (division: DivisionOverview) =>
        `${division.employees_passed_all}/${division.employees_total}`,
    },
    {
      label: t(`instructionsScreen_overdue`),
      getter: (division: DivisionOverview) => division.employees_overdue,
    },
  ]

  const permissions = useSelector((state) => state.profile.permissions)

  const canManageInstructions = permissions.includes(
    'weissbier:update:facility_instruction'
  )

  const canSendReminders = permissions.includes('weissbier:send:reminder')
  const hasEmailsDisabled = useSelector((state) =>
    state.entities.customer?.features?.includes(Features.NO_EMAIL_INSTRUCTIONS)
  )

  return (
    <Table
      list={divisionOverviewList}
      emptyCopy={t('instructionsScreen_empty')}
      loading={isLoading}
      // FIXME: Find a type which requires `id` property and allows for whatever else
      // @ts-expect-error
      onRowClick={onRowClick}
      columns={columns}
      tableLabel="instruction-results"
      showButton={!hasEmailsDisabled && canSendReminders}
    />
  )
}

export default InstructionsResultsTable
