import { User } from '@auth0/auth0-react'
import { createAction } from '@reduxjs/toolkit'
import { EmployeeStatus } from 'services/model'

export const setProfile = createAction<User>('@@profile/SET_PROFILE')
export const setUserStatus = createAction<EmployeeStatus>(
  '@@profile/SET_USER_STATUS'
)

export const setPermissions = createAction<string[]>(
  '@@profile/SET_PERMISSIONS'
)
