import { ReduxState } from 'OHSWebApp'
import { routerReducer } from 'react-router-redux'
import { combineReducers } from 'redux'
import { entitiesReducer, queriesReducer } from 'redux-query'
import onboarding from 'redux/onboarding/reducer'
import profileReducer from 'redux/profile/reducer'
import settings from 'redux/settings/reducer'

import { OHSEntitiesState } from './entities'

export const reducersMap = {
  routing: routerReducer,
  queries: queriesReducer,
  entities: entitiesReducer,
  profile: profileReducer,
  onboarding,
  settings,
}

export default combineReducers(reducersMap)

// module augmentation so you don't need to specify state
// when using react-redux useSelector types
declare module 'react-redux' {
  export interface DefaultRootState extends ReduxState {}
}

declare module 'redux-query' {
  export interface Entities extends OHSEntitiesState {}
}
